import React, { useContext, useEffect, useState } from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { OrderContext } from '../../context/OrderContext/order.context';
import useDebounce from '../../hooks/useDebounce';
import { SearchBarProps } from './search-bar.interface';
import { T } from '@tolgee/react';

const SearchBar: React.FC<SearchBarProps> = ({
	placeholder,
	onChange,
	onClick,
	showButton = true,
	classNames,
}) => {
	const { setSearch } = useContext(OrderContext);
	const [search, setSearchLocal] = useState<string>('');
	const searchQuery = useDebounce(search, 500);

	useEffect(() => {
		onChange && onChange(searchQuery);
		setSearch(searchQuery);
	}, [onChange, searchQuery, setSearch]);

	return (
		<SypacInput
			className={`relative w-fit flex gap-2 items-center py-2 px-3 bg-white border border-solid border-gray-10 rounded-10 transition hover:border-gray-40 focus-within:border-cornflower-blue box-border ${classNames}`}
		>
			<input
				type="text"
				className="text-base placeholder:text-gray-40 p-0 outline-none border-0 bg-transparent flex-1"
				placeholder={placeholder}
				onChange={(event) => {
					setSearchLocal(event.target.value);
				}}
			/>
			{showButton ? (
				<SypacButton
					variant="primary"
					size="small"
					className="absolute top-1 right-1"
				>
					<button
						className="h-[32px] flex gap-2 items-center justify-center px-3 bg-primary-violet rounded-lg z-20"
						onClick={() => (onClick ? onClick(search) : '')}
					>
						<span className="flex">
							<SypacIcon
								icon-name="Magnifer"
								className="text-white"
								size="custom"
								width="22px"
								height="22px"
							/>
						</span>
						<SypacText variant="body-normal-small">
							<p className="text-sm text-white mt-[2px]">
								<T keyName="saechBar.search">Search</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
			) : null}
		</SypacInput>
	);
};

export default SearchBar;
