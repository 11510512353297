import React, { useState } from 'react';
import { SypacIcon, SypacText } from '@sypac/component-library-react';

import BoxComponent from '../../BoxComponent/BoxComponent';
import { UnloadingSuccessfulProps } from './UnloadingSuccessful.interface';
import { LineStroke } from '../../../assets/LineStroke';
import { T } from '@tolgee/react';
import ImageViewer from '../../ImageViewer/ImageViewer';
import classNames from 'classnames';

const UnloadingSuccessful = ({
	notification,
	title,
	driver,
	driverSigned,
	driverDocuments,
	client,
	clientSigned,
	clientDocuments,
}: UnloadingSuccessfulProps) => {
	const [openView, setShowView] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();

	const openImage = (item: { src: string; alt: string }) => {
		setUrl(item.src);
		setShowView(true);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	return (
		<>
			<BoxComponent notification={notification}>
				<div className="flex flex-col p-3 gap-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{title}</p>
					</SypacText>
					<div className="grid grid-cols-3 gap-2.5">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-40">
								<T keyName="unloadingSuccessful.client">Client:</T>
							</p>
						</SypacText>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{client}</p>
							</SypacText>
						</div>
						<SypacText variant="overline-regular-large">
							<p className="text-gray-40">Signed:</p>
						</SypacText>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{clientSigned}</p>
							</SypacText>
						</div>
					</div>
					<div className="grid grid-cols-3 gap-2.5 overflow-hidden">
						{clientDocuments?.map((item: any) => {
							return (
								<div
									key={item.src}
									className="relative"
									onClick={() => openImage(item)}
									onMouseEnter={() => handleBoxToggle(item.src)}
									onMouseLeave={() => handleBoxToggle(undefined)}
								>
									<div
										style={{
											backgroundImage: `url(${item.src})`,
										}}
										className="flex flex-none w-[100%] h-[70px] bg-no-repeat bg-cover rounded-10 cursor-pointer"
										role="img"
									/>
									{showBlur === item.src ? (
										<div
											className={classNames(
												'bg-black-50 absolute z-0 top-0 left-0 rounded-[12px] backdrop-blur-sm h-full w-full flex flex-row justify-center items-center border border-solid border-kimberly',
												{},
											)}
										>
											<SypacIcon
												size="custom"
												width="32px"
												height="32px"
												icon-name="Magnifer"
												className="text-white"
											/>
										</div>
									) : null}
								</div>
							);
						})}
					</div>
					<div className="flex -my-2">
						<LineStroke width={[330, 330, 350]} />
					</div>
					<div className="grid grid-cols-3 gap-2.5">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-40">Driver:</p>
						</SypacText>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{driver}</p>
							</SypacText>
						</div>
						<SypacText variant="overline-regular-large">
							<p className="text-gray-40">Signed:</p>
						</SypacText>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{driverSigned}</p>
							</SypacText>
						</div>
					</div>
					<div className="grid grid-cols-3 gap-2.5 overflow-hidden">
						{driverDocuments?.map((item: any) => {
							return (
								<div
									key={item.src}
									className="relative"
									onClick={() => openImage(item)}
									onMouseEnter={() => handleBoxToggle(item.src)}
									onMouseLeave={() => handleBoxToggle(undefined)}
								>
									<div
										style={{
											backgroundImage: `url(${item.src})`,
										}}
										className="flex flex-none w-[100%] h-[70px] bg-no-repeat bg-cover rounded-10 cursor-pointer relative"
										role="img"
									/>
									{showBlur === item.src ? (
										<div
											className={classNames(
												'bg-black-50 absolute z-0 top-0 left-0 rounded-[12px] backdrop-blur-sm h-full w-full flex flex-row justify-center items-center border border-solid border-kimberly',
												{},
											)}
										>
											<SypacIcon
												size="custom"
												width="32px"
												height="32px"
												icon-name="Magnifer"
												className="text-white"
											/>
										</div>
									) : null}
								</div>
							);
						})}
					</div>
				</div>
			</BoxComponent>
			{openView ? (
				<ImageViewer
					url={url!}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}
		</>
	);
};

export default UnloadingSuccessful;
