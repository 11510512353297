import { IconInterface } from '../interfaces/icon.interface';

const PaymentSuccess = (props: IconInterface) => {
	const { color = '#20AC93', width = 54, height = 60 } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox="0 0 54 60"
		>
			<path
				fill={color}
				fillOpacity="0.1"
				d="M0 25.25c0-9.593 0-14.39 1.133-16.003C2.265 7.634 6.775 6.09 15.795 3.002l1.718-.588C22.215.804 24.566 0 27 0s4.785.805 9.487 2.414l1.718.588c9.02 3.088 13.53 4.632 14.663 6.245C54 10.861 54 15.657 54 25.25v4.724c0 16.914-12.717 25.122-20.696 28.608C31.14 59.527 30.058 60 27 60c-3.058 0-4.14-.473-6.304-1.418C12.716 55.096 0 46.888 0 29.974V25.25z"
			></path>
			<path
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
				d="M19.5 31.2l4.286 4.8L34.5 24"
			></path>
		</svg>
	);
};

export default PaymentSuccess;
