import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { useNavigate } from 'react-router-dom';
import {
	OrderContext,
	OrderProps,
} from '../../../context/OrderContext/order.context';
import { QuantityPickerContext } from '../../../context/QuantityPickerContext/quantity-picker.context';
import { Geo } from '../../../interfaces/geo.interface';
import { MapMarker, PinVariant } from '../../../components/Map/Map.interface';
import { GeoService } from '../../../services/geo.services';
import { FileService } from '../../../services/file.service';
import { OrdersService } from '../../../services/orders.services';
import { T } from '@tolgee/react';
import useDebounce from '../../../hooks/useDebounce';
import SearchLocation from './components/SearchLocation';
import UploadLocationPhoto from './components/UploadLocationPhoto';
import Map from '../../../components/Map/Map';

const ClientSelectLocation: React.FC = () => {
	const navigate = useNavigate();
	const { order, setOrder } = useContext(OrderContext);
	const { setInputQuantity, setTotalQuantityCounter } = useContext(
		QuantityPickerContext,
	);
	const [search, setSearch] = useState<string>('');
	const [locations, setLocations] = useState<Geo[]>([]);
	const [localRecentOrders, setLocalRecentOrders] = useState<OrderProps[]>([]);
	const [showUpload, setUploadModal] = useState<boolean>(false);
	const [currentTab] = useState<string>('orders');
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	const [markerHovered, setMarkerHovered] = useState<string>();

	const searchQuery = useDebounce(search, 500);

	const getRecentOrders = async () => {
		try {
			const { data } = await OrdersService.getRecentOrdersByProducts({
				limit: 5,
				offset: 0,
			});
			if (!data.count) return;

			const newMarkers = await Promise.all(
				data.items.map(async (order) => {
					const { data: locationData } = await GeoService.getLocationDetails({
						locationid: order?.location?.locationId!,
					});
					return {
						latitude: locationData.Latitude,
						longitude: locationData.Longitude,
						variant: PinVariant.pickup,
						orderProduct: order.product,
					};
				}),
			);

			setLocalRecentOrders(data.items || []);
			setMarkers(newMarkers);
		} catch (e) {}
	};

	const handleLocationSearch = useCallback(async () => {
		if (!searchQuery.length) {
			setLocations([]);
			return;
		}

		try {
			const { data } = await GeoService.getLocations({
				phrase: searchQuery,
				maxResults: '10',
			});
			setLocations(data);
		} catch (e) {
			console.error('Error searching locations:', e);
		}
	}, [searchQuery]);

	const handleLocationSelect = (location: Geo) => {
		setOrder({ ...order, location });
		setUploadModal(true);
	};

	const selectRecentOrder = (order: OrderProps) => {
		setOrder({ ...order });
		navigate(
			`/store/groups/${order?.group?.id}/categories/${order?.category?.id}/products/${order?.product?.id}/quantity`,
		);
	};

	const handlePhotoUpload = async (file?: File) => {
		try {
			if (!file) {
				setOrder({ ...order, locationPhoto: undefined });
				setUploadModal(false);
				navigate('/store/groups');
				return;
			}

			const { data } = await FileService.uploadFiles([file]);
			setOrder({ ...order, locationPhoto: data.items?.[0]?.path });
			setUploadModal(false);
			navigate('/store/groups');
		} catch (e) {}
	};

	useEffect(() => {
		handleLocationSearch().then(() => {});
	}, [searchQuery, handleLocationSearch]);

	useEffect(() => {
		getRecentOrders().then(() => {});
		setOrder(undefined);
		setInputQuantity(0);
		setTotalQuantityCounter(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const RecentOrderCard = ({ order }: { order: OrderProps }) => (
		<button
			className="w-full h-[68px] flex items-center bg-transparent border border-solid border-gray-10 rounded-10 px-3 transition hover:border-primary-violet hover:shadow-dropdown cursor-pointer"
			onClick={() => selectRecentOrder(order)}
			onMouseEnter={() => setMarkerHovered(order?.product?.name)}
			onMouseLeave={() => setMarkerHovered(undefined)}
		>
			<div
				style={{ backgroundImage: `url(${order?.product?.photoUrl})` }}
				className="flex flex-none w-[44px] h-[44px] bg-no-repeat bg-cover rounded-[8px] border border-solid border-gray-10"
				role="img"
			/>
			<div className="flex flex-col ml-5 items-start">
				<SypacText variant="body-normal-medium">
					<p
						className={`text-base text-gray-80 ${
							!order?.product?.type && !order?.product?.size ? 'pb-[20px]' : ''
						}`}
					>
						{order?.product?.name}
					</p>
				</SypacText>
				{(order?.product?.type || order?.product?.size) && (
					<SypacText variant="body-normal-medium">
						<p className="text-sm text-gray-40">
							{order?.product?.type}{' '}
							{order?.product?.size && `(${order?.product?.size})`}
						</p>
					</SypacText>
				)}
			</div>
		</button>
	);

	const EmptyState = () => (
		<section className="h-full flex justify-center items-center">
			<div className="w-[339px] h-[150px] flex flex-col justify-between text-center">
				<div className="flex flex-col gap-4">
					<SypacText variant="body-regular-large">
						<p className="text-xl text-gray-80">
							<T keyName="clientSelectLocation.noRecentOrdersYet">
								No recent orders yet
							</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-large">
						<p className="text-base text-gray-40">
							<T keyName="selectLocation.youHaveNoRecentOrders">
								You have no recent orders. Your previous orders will show up
								here for convenient reordering.
							</T>
						</p>
					</SypacText>
				</div>
			</div>
		</section>
	);

	return (
		<main className="w-full flex gap-[20px] justify-center items-center">
			<section className="w-[556px] h-[738px] flex flex-col justify-between">
				<header>
					<SypacText variant="body-regular-large">
						<p className="text-[40px] leading-[42px] tracking-tighter text-gray-80">
							<T keyName="clientSelectLocation.welcomeToTheStore">
								Welcome to the Sypac store!
							</T>
						</p>
					</SypacText>
				</header>

				<div className="flex flex-col gap-10">
					<section className="flex flex-col gap-6">
						<SypacText variant="body-regular-large">
							<p className="text-xl text-gray-80">
								<T keyName="clientSelectLocation.whereToDeliverYourGoods">
									Where to deliver your goods?
								</T>
							</p>
						</SypacText>
						<SearchLocation
							inputName="search_location"
							locations={locations}
							onChange={setSearch}
							selectLocation={handleLocationSelect}
						/>
					</section>

					<section className="w-full flex flex-col h-[480px] border border-solid border-gray-10 rounded-10 bg-white overflow-hidden box-border">
						<nav className="w-[530px] fixed flex gap-[4px] p-3 rounded-t-[10px] border border-solid border-gray-10 border-t-0 border-l-0 border-r-0 bg-white z-30">
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									className={`w-full h-[32px] rounded-lg flex gap-2 items-center border-none transition group 
                    ${
											currentTab === 'orders'
												? 'bg-primary-violet text-white'
												: 'hover:bg-gray-10 text-gray-80'
										}`}
								>
									<SypacIcon
										iconName="Clock Circle"
										size="custom"
										width="24px"
										height="24px"
										className="mb-[2px]"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="clientSelectLocation.myRecentOrders">
												My recent orders
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</nav>

						{currentTab === 'orders' &&
							(localRecentOrders.length ? (
								<div className="w-full h-full flex flex-col gap-3 p-3 overflow-y-scroll scroll-smooth mt-[57px]">
									{localRecentOrders.map((order) => (
										<div className="mr-[11px]" key={order?.product?.id}>
											<RecentOrderCard order={order} />
										</div>
									))}
								</div>
							) : (
								<EmptyState />
							))}
					</section>
				</div>
			</section>

			<section className="w-[562px] h-[738px]">
				<Map
					polygons={[]}
					markers={markers}
					target="customer"
					markerHovered={markerHovered}
				/>
			</section>

			<UploadLocationPhoto
				isOpen={showUpload}
				onClose={() => setUploadModal(false)}
				onSave={handlePhotoUpload}
			/>
		</main>
	);
};

export default ClientSelectLocation;
