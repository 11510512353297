import React from 'react';
import { SypacText } from '@sypac/component-library-react';
import { RecentOrderItemProps } from '../clientSelectProduct.interface';

const RecentOrderItem: React.FC<RecentOrderItemProps> = ({
	order,
	onSelect,
}) => (
	<button
		type="button"
		className="flex items-center py-2.75 px-3 bg-transparent border-none rounded-lg cursor-pointer p-0 hover:bg-gray-10"
		onClick={() => onSelect(order)}
	>
		<div
			style={{ backgroundImage: `url(${order?.product?.photoUrl})` }}
			className="box-border flex flex-none w-[46px] h-[46px] bg-no-repeat bg-cover rounded-[8px] border border-solid border-gray-10"
			role="img"
			aria-label={order?.product?.name}
		/>
		<div className="flex flex-col ml-3 items-start">
			<SypacText variant="body-normal-medium">
				<p
					className={`w-[197px] text-start truncate text-base text-gray-80 ${
						!order?.product?.type && !order?.product?.size ? 'pb-[20px]' : ''
					}`}
				>
					{order?.product?.name}
				</p>
			</SypacText>
			{(order?.product?.type || order?.product?.size) && (
				<SypacText variant="body-normal-medium">
					<p className="text-sm text-gray-40">
						{order?.product?.type}{' '}
						{order?.product?.size && `(${order?.product?.size})`}
					</p>
				</SypacText>
			)}
		</div>
	</button>
);

export default RecentOrderItem;
