import React, { useContext, useEffect, useRef, useState } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { Document, Page } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import Close from '../../assets/Close';
import { OrderContext } from '../../context/OrderContext/order.context';
import { T } from '@tolgee/react';
import Flag from 'react-world-flags';
import BouncingLoader from '../BouncingLoader/BouncingLoader';
import { DocumentsViewerProps } from './DocumentsViewer.interface';
import { toastVariant } from '../CompaniesTable/toastVariant/toastVariant';
import Download from '../../assets/Download';
import Trash from '../../assets/Trash';

const DocumentsViewer: React.FC<DocumentsViewerProps> = ({
	isOpen,
	onClose,
	url,
	isPdf,
	additional,
	canDelete = false,
	handleDelete,
	canDownload = false,
}) => {
	const { setAdditional } = useContext(OrderContext);
	const [nrChanges, setNrChanges] = useState<boolean>(false);
	const [numPages, setNumPages] = useState<number>(1);
	const [docsUrl, setDocsUrl] = useState<string | undefined>(undefined);
	const [isVertical, setIsVertical] = useState<boolean>(false);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const modalRef = useRef<HTMLDivElement>(null);

	const handleLoadSuccess = (document: DocumentCallback) => {
		if (!nrChanges) {
			setNumPages(document?._pdfInfo?.numPages || 1);
			setNrChanges(true);
		}
	};

	const downloadDocument = async (fileUrl: string) => {
		if (!fileUrl) return;

		try {
			const response = await fetch(fileUrl);
			const blob = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(blob);
			const link = document.createElement('a');

			const fileName = new URL(fileUrl).pathname.split('/').pop();

			link.href = downloadUrl;
			link.download = fileName || 'document';
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	};

	useEffect(() => {
		setDocsUrl(url);
	}, [url]);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (docsUrl) {
			const img = new Image();
			img.src = docsUrl;
			img.onload = () => {
				setIsLoaded(true);
				setIsVertical(img.naturalHeight > img.naturalWidth);
			};
			img.onerror = () => {
				setIsLoaded(true);
			};
		}
	}, [docsUrl]);

	const renderAdditionalInfo = () => {
		if (!additional) return null;

		return (
			<footer className="box-border absolute w-[859px] bottom-0 flex flex-col gap-2.5 p-5 bg-[#fefefe] border border-solid border-gray-10 rounded-2xl z-50">
				{additional.isLocation ? (
					<>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								<T keyName="pickUpDeliveryDetails.deliveryLocation">
									Delivery location:
								</T>
							</p>
						</SypacText>
						<div className="flex items-center gap-2">
							<span className="-ml-[2px] mt-[2px]">
								<Flag
									className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
									code={additional.deliveryCode}
									width={24}
									height={16}
								/>
							</span>
							<SypacText variant="body-regular-medium">
								<p className="text-2xl text-gray-90">{additional.text}</p>
							</SypacText>
						</div>
					</>
				) : (
					<>
						<SypacText variant="body-regular-medium">
							<p className="text-2xl text-gray-80">{additional?.text}</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								{new Date(additional?.updatedAt!)
									.toLocaleDateString('en-GB', {
										day: '2-digit',
										month: 'short',
										year: 'numeric',
									})
									.replace(/ (\d{4})$/, ', $1')}
							</p>
						</SypacText>
					</>
				)}
			</footer>
		);
	};

	const pageProps = {
		renderTextLayer: false,
		renderAnnotationLayer: false,
		renderInteractiveForms: false,
		scale: 1.0,
	};

	return isOpen ? (
		<div className="modal-overlay" onClick={onClose}>
			<div
				className="absolute bg-transparent outline-none w-[924px] h-[860px]"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<div className="flex h-full gap-5 justify-center">
					<main
						className={`flex items-center justify-center box-border relative border border-solid border-gray-10 rounded-2xl ${
							!isPdf ? 'w-full h-full' : 'p-1'
						} ${isVertical ? 'bg-black' : 'bg-white'}`}
					>
						{isPdf ? (
							<Document
								file={docsUrl}
								renderMode="canvas"
								onLoadSuccess={handleLoadSuccess}
							>
								{Array.from({ length: numPages }, (_, i) => (
									<Page
										key={`page_${i + 1}`}
										pageNumber={i + 1}
										{...pageProps}
										children={null}
									/>
								))}
							</Document>
						) : (
							<div className="w-full h-full">
								{!isLoaded && (
									<div className="h-full flex items-center justify-center bg-white rounded-[15px]">
										<BouncingLoader />
									</div>
								)}
								<img
									src={docsUrl}
									alt="Company document"
									className={`w-full h-full rounded-2xl ${
										isVertical
											? 'max-w-full max-h-full object-contain'
											: 'object-cover'
									}`}
									style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
								/>
							</div>
						)}
						{renderAdditionalInfo()}
					</main>

					<aside className="flex flex-col gap-3">
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[44px] w-[44px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-80 hover:border-[2px] rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
						{canDelete && (
							<SypacButton variant="subTitle">
								<button
									type="button"
									className="flex justify-center items-center h-[44px] w-[44px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-80 hover:border-[2px] rounded-lg"
									onClick={handleDelete}
								>
									<Trash color="#171717" width={20} height={20} />
								</button>
							</SypacButton>
						)}
						{canDownload && (
							<SypacButton variant="subTitle">
								<button
									type="button"
									className="flex justify-center items-center h-[44px] w-[44px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-80 hover:border-[2px] rounded-lg"
									onClick={() => downloadDocument(url)}
								>
									<Download color="#171717" width={18} height={18} />
								</button>
							</SypacButton>
						)}
					</aside>
				</div>
			</div>
		</div>
	) : null;
};

export default DocumentsViewer;
