import { IconInterface } from '../interfaces/icon.interface';

const HashBox = (props: IconInterface) => {
	const { color = '#8B8B8B', width = 16, height = 16 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.20078 4L4.80078 12"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.2008 4L8.80078 12"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.8 6.3999H4"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.9992 9.6001H3.19922"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M0.5 8C0.5 6.10025 0.501062 4.72573 0.641988 3.67754C0.78098 2.64373 1.04772 2.00253 1.52513 1.52513C2.00253 1.04772 2.64373 0.78098 3.67754 0.641988C4.72573 0.501062 6.10025 0.5 8 0.5C9.89975 0.5 11.2743 0.501062 12.3225 0.641988C13.3563 0.78098 13.9975 1.04772 14.4749 1.52513C14.9523 2.00253 15.219 2.64373 15.358 3.67754C15.4989 4.72573 15.5 6.10025 15.5 8C15.5 9.89975 15.4989 11.2743 15.358 12.3225C15.219 13.3563 14.9523 13.9975 14.4749 14.4749C13.9975 14.9523 13.3563 15.219 12.3225 15.358C11.2743 15.4989 9.89975 15.5 8 15.5C6.10025 15.5 4.72573 15.4989 3.67754 15.358C2.64373 15.219 2.00253 14.9523 1.52513 14.4749C1.04772 13.9975 0.78098 13.3563 0.641988 12.3225C0.501062 11.2743 0.5 9.89975 0.5 8Z"
				stroke={color}
			/>
		</svg>
	);
};

export default HashBox;
