import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../../containers/NotFound';
import ClientSelectGroups from '../../containers/Client/ClientSelectGroups';
import ClientSelectCategory from '../../containers/Client/ClientSelectCategory';
import ClientSelectProduct from '../../containers/Client/ClientSelectProduct';
import BuyerGuardedRoute from '../guards/BuyerGuardedRoute';
import ClientSelectLocation from '../../containers/Client/ClientSelectLocation';
import ProductsPayment from '../../containers/ProductPayment/ProductsPayment';
import ClientThankYou from '../../containers/Client/ClientThankYou/ClientThankYou';
import ClientSelectProductDetails from '../../containers/Client/ClientSelectProductDetails';
import ClientSelectQuantity from '../../containers/Client/ClientSelectQuantity';
import ClientSelectDate from '../../containers/Client/ClientSelectDate';
import OrderConfirmation from '../../containers/Client/OrderConfirmation';
import Orders from '../../containers/Client/Orders';
import ClientBilling from '../../containers/Client/ClientBilling';
import { useTranslate } from '@tolgee/react';
import { SideMenu } from '../../components/SideBarMenu/SideMenu';
import UserSettings from '../../containers/UserSettings';

const CustomerRouter: React.FC = () => {
	const { t } = useTranslate();

	const CLIENT_MENU = [
		{
			logo: 'Cart Large 2',
			alt: t('customerRouter.store', 'Store'),
			url: '/store',
		},
		{
			logo: 'Document Add',
			alt: t('customerRouter.orders', 'Orders'),
			count: true,
			url: '/orders',
		},
		{
			logo: 'Money Bag',
			alt: t('customerRouter.billing', 'Billing'),
			url: '/billing',
		},
	];

	return (
		<Routes>
			<Route
				path="/store"
				element={
					<BuyerGuardedRoute>
						<SideMenu groups={CLIENT_MENU}>
							<ClientSelectLocation />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/orders"
				element={
					<BuyerGuardedRoute>
						<SideMenu groups={CLIENT_MENU}>
							<Orders />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/billing"
				element={
					<BuyerGuardedRoute>
						<SideMenu groups={CLIENT_MENU}>
							<ClientBilling />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/settings"
				element={
					<BuyerGuardedRoute>
						<SideMenu groups={CLIENT_MENU}>
							<UserSettings />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/groups"
				element={
					<BuyerGuardedRoute shouldHaveAddress={true}>
						<SideMenu groups={CLIENT_MENU}>
							<ClientSelectGroups />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/groups/:groupId/categories"
				element={
					<BuyerGuardedRoute shouldHaveAddress={true}>
						<SideMenu groups={CLIENT_MENU}>
							<ClientSelectCategory />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/groups/:groupId/categories/:categoryId/products"
				element={
					<BuyerGuardedRoute shouldHaveAddress={true}>
						<SideMenu groups={CLIENT_MENU}>
							<ClientSelectProduct />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/groups/:groupId/categories/:categoryId/products/:productId"
				element={
					<BuyerGuardedRoute shouldHaveAddress={true}>
						<SideMenu groups={CLIENT_MENU}>
							<ClientSelectProductDetails />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/:orderId/thank-you"
				element={
					<BuyerGuardedRoute>
						<SideMenu groups={CLIENT_MENU}>
							<ClientThankYou />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/groups/:groupId/categories/:categoryId/products/:productId/quantity"
				element={
					<BuyerGuardedRoute shouldHaveAddress={true}>
						<SideMenu groups={CLIENT_MENU}>
							<ClientSelectQuantity />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/order-confirmation"
				element={
					<BuyerGuardedRoute shouldHaveAddress={true}>
						<SideMenu groups={CLIENT_MENU}>
							<OrderConfirmation />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/payment"
				element={
					<BuyerGuardedRoute shouldHaveAddress={true}>
						<SideMenu groups={CLIENT_MENU}>
							<ProductsPayment />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route
				path="/store/groups/:groupId/categories/:categoryId/products/:productId/date"
				element={
					<BuyerGuardedRoute shouldHaveAddress={true}>
						<SideMenu groups={CLIENT_MENU}>
							<ClientSelectDate />
						</SideMenu>
					</BuyerGuardedRoute>
				}
			/>
			<Route path="/*" element={<NotFound />} />
		</Routes>
	);
};

export default CustomerRouter;
