import Api from '../axios/api';
import { Geo, Address } from '../interfaces/geo.interface';

export interface RequestLocations {
	phrase: string;
	maxResults: string;
}

export interface RequestLocationDetails {
	locationid: string;
}
export interface LocationDetails {
	Latitude: number;
	Longitude: number;
	Country: string;
	address?: Address;
}

export interface GetLocationsResponse {
	data: Geo[];
}
export interface GetLocationDetailsResponse {
	data: LocationDetails;
}
interface QueryParams {
	points: string[];
}

interface RouteResponse {
	shape: string;
	distance: number;
	trafficTime: number;
}

export interface ResponnseRouteResponse {
	data: RouteResponse[];
}

interface QueryParamsAddresses {
	search?: string;
	limit?: number;
	offset?: number;
}

export interface AddressesI {
	lat: number;
	long: number;
	country: string;
	address: string;
}

export interface GetAddressesResponse {
	data: { items: AddressesI[]; count: number };
}
export class GeoService {
	static getLocations(query: RequestLocations): Promise<GetLocationsResponse> {
		const params = new URLSearchParams({
			maxResults: query.maxResults,
			phrase: query.phrase,
		});
		return Api.get(`/v1/customer/geo/suggestions?${params}`);
	}

	static async getDataLocations(query: RequestLocations): Promise<Geo[]> {
		const params = new URLSearchParams({
			maxResults: query.maxResults,
			phrase: query.phrase,
		});
		const { data } = await Api.get(`/v1/customer/geo/suggestions?${params}`);
		return data;
	}

	static getLocationDetails(
		query: RequestLocationDetails,
	): Promise<GetLocationDetailsResponse> {
		const params = new URLSearchParams({
			locationid: query.locationid,
		});
		return Api.get(`/v1/customer/geo/location?${params}`);
	}

	static getRoute(query: QueryParams): Promise<ResponnseRouteResponse> {
		return Api.get('/v1/customer/geo/route', {
			params: query,
		});
	}

	static getAddresses(
		params: QueryParamsAddresses,
		target = 'producer',
	): Promise<GetAddressesResponse> {
		return Api.get(`/v1/${target}/addresses`, { params });
	}
}
