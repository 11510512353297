import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import {
	SypacButton,
	SypacDatepicker,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { ModalDownloadProps } from './ModalDownload.interface';
import './modal-download.css';

const ModalDownload = ({ isOpen, onClose, onSuccess }: ModalDownloadProps) => {
	const language = localStorage.getItem('lang') || 'en';
	const [minValue, setMinValue] = useState<string>();
	const [maxValue, setMaxValue] = useState<string>();
	const { t } = useTranslate();
	const formik = useFormik({
		initialValues: {
			dateFrom: '',
			dateTo: '',
		},
		validationSchema: Yup.object({
			dateFrom: Yup.string().required('Date from is required'),
			dateTo: Yup.string().required('Date to is required'),
		}),
		onSubmit: (values) => {
			onSuccess(values.dateFrom, values.dateTo);
		},
	});

	const callback = useCallback(
		(field: string, selectedDate: string) => {
			if (field === 'dateTo') {
				const minV = dayjs(selectedDate).subtract(60, 'd').toISOString();
				setMinValue(minV);
			} else {
				const maxV = dayjs(selectedDate).add(60, 'd').toISOString();
				if (maxV > dayjs().toISOString()) {
					setMaxValue(dayjs().toISOString());
				} else {
					setMaxValue(maxV);
				}
			}
			formik.setFieldValue(field, dayjs(selectedDate).toISOString());
		},
		[formik],
	);

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none min-w-[330px] max-w-[563px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">Select time period</p>
					</SypacText>
					<SypacIcon
						iconName="Close Square"
						className="text-gray-10 ml-6 cursor-pointer"
						size="custom"
						width="32px"
						height="32px"
						onClick={onClose}
					/>
				</div>
				<form onSubmit={formik.handleSubmit}>
					<div className="flex flex-col items-start justify-center modal-confirmation-code-border px-6 py-6 gap-4">
						<SypacInput
							error={!!(formik.touched.dateFrom && formik.errors.dateFrom)}
							className="w-full"
						>
							<label className="text-primary-dark-gray text-xs leading-4 -tracking-[0.01em]">
								Date from
								<span className="text-red">*</span>
							</label>
							<SypacDatepicker
								name="dateFrom"
								placeholder={t('modalFilterDate.enterDate', 'Enter date')}
								min={minValue || '1950-01-01'}
								max={dayjs().toString()}
								value={formik?.values.dateFrom ? formik?.values.dateFrom : ''}
								onCallback={({ detail }: any) => callback('dateFrom', detail)}
								inputReadOnly={true}
								locale={language}
							/>
							{formik.touched.dateFrom && formik.errors.dateFrom ? (
								<span className="bottom-helper">{formik.errors.dateFrom}</span>
							) : null}
						</SypacInput>
						<SypacInput
							error={!!(formik.touched.dateTo && formik.errors.dateTo)}
							className="w-full"
						>
							<label className="text-primary-dark-gray text-xs leading-4 -tracking-[0.01em]">
								Date to
								<span className="text-red">*</span>
							</label>
							<SypacDatepicker
								name="dateTo"
								placeholder={t('modalFilterDate.enterDate', 'Enter date')}
								min={formik?.values.dateFrom || '1950-01-01'}
								max={maxValue || dayjs().toString()}
								value={formik?.values.dateTo ? formik?.values.dateTo : ''}
								onCallback={({ detail }: any) => callback('dateTo', detail)}
								inputReadOnly={true}
								locale={language}
							/>
							{formik.touched.dateTo && formik.errors.dateTo ? (
								<span className="bottom-helper">{formik.errors.dateTo}</span>
							) : null}
						</SypacInput>
					</div>

					<div className="flex justify-center w-full gap-4 py-4">
						<SypacButton variant="secondary" size="small" onClick={onClose}>
							<button
								className="px-2 py-2.5 hover:border-gray-2 w-36"
								type="reset"
							>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="modalDownload.close">Close</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small">
							<button
								className="px-2 py-2.5 border border-solid border-mountain-meadow w-36"
								type="submit"
								disabled={!formik.isValid}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-mountain-meadow">
										<T keyName="modalDownload.close">Export document</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default ModalDownload;
