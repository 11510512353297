import React, { createContext, useState, useMemo } from 'react';
import { InvoiceInterface } from '../../components/PaymentsTable/interfaces/Payment.interface';
import { CompanyResponse } from '../../services/company.services';

interface OrderDetailsContextProps {
	customerInvoice: InvoiceInterface | undefined;
	setCustomerInvoice: (invoice: InvoiceInterface | undefined) => void;
	carrierInvoice: InvoiceInterface | undefined;
	setCarrierInvoice: (invoice: InvoiceInterface | undefined) => void;
	producerInvoice: InvoiceInterface | undefined;
	setProducerInvoice: (invoice: InvoiceInterface | undefined) => void;
	company: CompanyResponse | undefined;
	setCompany: (company: CompanyResponse | undefined) => void;
}

export const OrderDetailsContext = createContext<OrderDetailsContextProps>({
	customerInvoice: undefined,
	setCustomerInvoice: () => null,
	carrierInvoice: undefined,
	setCarrierInvoice: () => null,
	producerInvoice: undefined,
	setProducerInvoice: () => null,
	company: undefined,
	setCompany: () => null,
});

const OrderDetailsContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [customerInvoice, setCustomerInvoice] = useState<
		InvoiceInterface | undefined
	>();
	const [carrierInvoice, setCarrierInvoice] = useState<
		InvoiceInterface | undefined
	>();
	const [producerInvoice, setProducerInvoice] = useState<
		InvoiceInterface | undefined
	>();
	const [company, setCompany] = useState<CompanyResponse | undefined>();

	const value = useMemo<OrderDetailsContextProps>(
		() => ({
			customerInvoice,
			setCustomerInvoice,
			carrierInvoice,
			setCarrierInvoice,
			producerInvoice,
			setProducerInvoice,
			company,
			setCompany,
		}),
		[customerInvoice, carrierInvoice, producerInvoice, company],
	);

	return (
		<OrderDetailsContext.Provider value={value}>
			{children}
		</OrderDetailsContext.Provider>
	);
};

export default OrderDetailsContextProvider;
