import React, { useCallback, useEffect, useState } from 'react';
import BillingTabs from '../../../../components/BillingTabs/BillingTabs';
import AvatarDropDown from '../../../../components/AvatarDropDown/AvatarDropDown';
import { Sorting } from '../../../../components/Sorting/Sorting';
import { SortingItemInterface } from '../../../../components/Sorting/Sorting.interface';
import { BillingService } from '../../../../services/billing.service';
import { PaymentsTable } from '../../../../components/PaymentsTable/PaymentsTable';
import { InvoiceInterface } from '../../../../components/PaymentsTable/interfaces/Payment.interface';
import { MutatingDots } from 'react-loader-spinner';
import Pagination from '../../../../components/Pagination/Pagination';
import { LIMIT } from '../../../../constants';
import { AvatarItem } from '../../../../components/AvatarDropDown/AvatarDropDown.interface';
import { useTranslate } from '@tolgee/react';
import ConfirmPaymentForClient from '../../../../components/ConfirmPayemntForClient/ConfirmPaymentForClient';
import ModalDownload from '../../../../components/ModalDownload/ModalDownload';
import { toastVariant } from '../../../../components/CompaniesTable/toastVariant/toastVariant';
import { BillingForAdminProps, TabInterface } from '../adminBilling.interface';
import useDebounce from '../../../../hooks/useDebounce';

const ClientBillingForAdmin: React.FC<BillingForAdminProps> = ({
	countryQuery,
	openDownload,
	setOpenDownload,
}) => {
	const { t } = useTranslate();
	const sortOptions = [
		{
			title: 'Sort by date descending',
			value: 'invoiceDate DESC',
		},
		{
			title: 'Sort by date ascending',
			value: 'invoiceDate ASC',
		},
		{
			title: 'Sort by price ascending',
			value: 'totalAmount ASC',
		},
		{
			title: 'Sort by price descending',
			value: 'totalAmount DESC',
		},
	];
	const [searchLocal] = useState<string>('');
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [sorting, setSorting] = useState<SortingItemInterface>();
	const [invoices, setInvoices] = useState<InvoiceInterface[]>([]);
	const [count, setCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [avatars, setAvatars] = useState<AvatarItem[]>([]);
	const [assigneeId, setAssignee] = useState<string>();
	const [showConfirmPayment, setConfirmPayment] = useState<boolean>(false);
	const [selectedInvoice, setInvoice] = useState<InvoiceInterface>();
	const [tabs, setTabs] = useState<TabInterface[]>([
		{ label: 'All', color: 'white', count: '0', value: 'all' },
		{ label: 'Pending payment', color: 'yellow', count: '0', value: 'pending' },
		{ label: 'Payment overdue', color: 'red', count: '0', value: 'overdue' },
		{ label: 'Paid', color: 'green', count: '0', value: 'paid' },
	]);
	const [page, setPage] = useState<number>(0);
	const [downloadModal, setDownloadModal] = useState<boolean>(false);
	const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
	const searchQuery = useDebounce(searchLocal, 500);

	const selectTab = (tab: string) => {
		setCurrentTab(tab);
		setPage(0);
	};

	const getStatistics = useCallback(async () => {
		try {
			const { data } = await BillingService.getInvoiceStatsAdmin('customer');
			setTabs([
				{ label: 'All', color: 'white', count: data?.count?.all, value: 'all' },
				{
					label: 'Pending payment',
					color: 'yellow',
					count: data?.count?.pending,
					value: 'pending',
				},
				{
					label: 'Payment overdue',
					color: 'red',
					count: data?.count?.overdue,
					value: 'overdue',
				},
				{
					label: 'Paid',
					color: 'green',
					count: data?.count?.paid,
					value: 'paid',
				},
			]);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}, []);

	const getInvoiceList = useCallback(async () => {
		try {
			setLoading(true);
			const sort = sorting?.value?.split(' ');
			const sortDirection = sort?.at(1);
			const sortBy = sort?.at(0);
			const { data } = await BillingService.getInvoiceListAdmin('customer', {
				statuses: [currentTab],
				limit: LIMIT,
				offset: page * LIMIT,
				sortDirection,
				sortBy,
				assigneeId,
				search: searchQuery,
				countryCode: countryQuery,
			});
			if (!assigneeId) {
				const lines =
					data.items?.map((invoice) => invoice?.invoiceLines?.at(0)!) || [];

				const avatarsMap: Record<string, string> = {};
				lines?.forEach((line) => {
					avatarsMap[line?.assigneeUser?.uid] = line?.assigneeUser?.name;
				});
				const avatars: AvatarItem[] = Object.keys(avatarsMap)
					.map((key) => {
						return {
							id: key,
							fullName: avatarsMap[key],
						};
					})
					.filter((r) => r.fullName);
				setAvatars(avatars);
			}
			setInvoices(data.items);
			setCount(data.count);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}, [assigneeId, countryQuery, currentTab, page, searchQuery, sorting?.value]);

	const changeAssignee = (uid: string) => {
		const assignee = assigneeId === uid ? undefined : uid;
		setAssignee(assignee);
	};

	useEffect(() => {
		getStatistics().then(() => {});
		getInvoiceList().then(() => {});
	}, [getInvoiceList, getStatistics]);

	const openConfirmPayment = (invoice: InvoiceInterface | number) => {
		setInvoice(invoice as InvoiceInterface);
		setConfirmPayment(true);
	};

	const confirmPayment = () => {
		getStatistics().then(() => {});
		getInvoiceList().then(() => {});
		setConfirmPayment(false);
	};

	const downloadInvoices = async (fromDate: string, toDate: string) => {
		setDownloadLoading(true);
		try {
			const response = await BillingService.downloadInvoices({
				fromDate,
				toDate,
			});
			if (response.data.type === 'text/html') {
				toastVariant(
					t(
						'billing.noInvoicesAvailable',
						'No invoices available for download during this period.',
					),
					true,
				);
				setDownloadLoading(false);
				setDownloadModal(false);
				return;
			}
			const url = window.URL.createObjectURL(new Blob([response.data]));

			const a = document.createElement('a');
			a.href = url;
			a.download = 'invoices.zip';
			a.click();

			window.URL.revokeObjectURL(url);
		} finally {
			setDownloadLoading(false);
			setDownloadModal(false);
		}
	};

	useEffect(() => {
		setDownloadModal(openDownload!);
	}, [openDownload]);

	const handleCloseModal = () => {
		setDownloadModal(false);
		setOpenDownload && setOpenDownload(false);
	};

	return (
		<div className="w-[calc(100%-52px)] h-full flex flex-col gap-5 ml-5 mr-7.5">
			<div className="flex justify-between">
				<div className="flex items-center gap-[32px] md:flex-col xl:flex-row">
					<BillingTabs
						data={tabs}
						callback={selectTab}
						activeTab={currentTab}
					/>

					{avatars.length ? (
						<div className="flex w-fit h-[44px]">
							<AvatarDropDown items={avatars} onChange={changeAssignee} />
						</div>
					) : null}

					<Sorting options={sortOptions} action={(item) => setSorting(item)} />

					{/* <SearchBar
						placeholder={t('clientBilling.searchOrder', 'Search order')}
						onChange={setSearchLocal}
						showButton={false}
					/> */}
				</div>
			</div>

			<div className="h-full">
				{loading || downloadLoading ? (
					<div className="flex h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					<div className="h-full relative border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
						<div className="w-full h-[calc(100%-52px)] overflow-y-auto scroll-smooth pr-[5px]">
							<PaymentsTable
								rows={invoices}
								rowClick={() => {}}
								target={'admin_customer'}
								additionalClick={openConfirmPayment}
							/>
						</div>
						<div className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
							<div className="flex justify-between items-center h-[51px] px-3">
								<Pagination
									showText={true}
									count={count}
									page={page}
									onClick={(item) => setPage(item)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			{selectedInvoice && showConfirmPayment ? (
				<ConfirmPaymentForClient
					isOpen={showConfirmPayment}
					onClose={() => setConfirmPayment(false)}
					onSubmit={() => confirmPayment()}
					selectedInvoice={selectedInvoice!}
				/>
			) : null}
			<ModalDownload
				isOpen={downloadModal}
				onClose={handleCloseModal}
				onSuccess={downloadInvoices}
			/>
		</div>
	);
};

export default ClientBillingForAdmin;
