import { IconInterface } from '../interfaces/icon.interface';

export const Person = (props: IconInterface) => {
	const { color = '#8B8B8B', width = 14, height = 18 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="6.99883" cy="4.2" r="3.2" stroke={color} />
			<path
				d="M13.3996 13.3998C13.3996 15.388 13.3996 16.9998 6.99961 16.9998C0.599609 16.9998 0.599609 15.388 0.599609 13.3998C0.599609 11.4116 3.46499 9.7998 6.99961 9.7998C10.5342 9.7998 13.3996 11.4116 13.3996 13.3998Z"
				stroke={color}
			/>
		</svg>
	);
};
