import { IconInterface } from '../interfaces/icon.interface';

const ProducerCompanyIcon = (props: IconInterface) => {
	const { color = '#8B8B8B', width = 17, height = 18 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.77107 12.0736C5.86057 12.3348 6.14489 12.474 6.40613 12.3845C6.66737 12.295 6.80659 12.0107 6.7171 11.7495L5.77107 12.0736ZM3.91888 5.12402L4.39189 4.96198L3.91888 5.12402ZM2.64545 4.00088L2.5411 4.48987L2.5411 4.48987L2.64545 4.00088ZM1.24009 3.18972C0.970029 3.13209 0.704382 3.3043 0.646752 3.57436C0.589122 3.84442 0.761332 4.11007 1.03139 4.1677L1.24009 3.18972ZM16.6404 11.6565C16.9022 11.5685 17.043 11.285 16.955 11.0232C16.867 10.7615 16.5834 10.6207 16.3217 10.7087L16.6404 11.6565ZM8.24604 13.9551C8.6151 15.0324 8.03267 16.2102 6.93134 16.5806L7.2501 17.5284C8.86819 16.9842 9.74382 15.2416 9.19207 13.631L8.24604 13.9551ZM6.93134 16.5806C5.828 16.9516 4.63791 16.3664 4.26811 15.2869L3.32208 15.611C3.8731 17.2195 5.63401 18.0719 7.2501 17.5284L6.93134 16.5806ZM4.26811 15.2869C3.89905 14.2096 4.48148 13.0318 5.58281 12.6614L5.26405 11.7136C3.64595 12.2578 2.77033 14.0004 3.32208 15.611L4.26811 15.2869ZM5.58281 12.6614C6.68615 12.2904 7.87624 12.8756 8.24604 13.9551L9.19207 13.631C8.64105 12.0225 6.88014 11.1701 5.26405 11.7136L5.58281 12.6614ZM6.7171 11.7495L4.39189 4.96198L3.44587 5.28606L5.77107 12.0736L6.7171 11.7495ZM2.7498 3.51189L1.24009 3.18972L1.03139 4.1677L2.5411 4.48987L2.7498 3.51189ZM4.39189 4.96198C4.13907 4.22397 3.51435 3.67504 2.7498 3.51189L2.5411 4.48987C2.96618 4.58058 3.30825 4.88435 3.44587 5.28606L4.39189 4.96198ZM8.87843 14.2669L16.6404 11.6565L16.3217 10.7087L8.55967 13.3191L8.87843 14.2669Z"
				fill={color}
			/>
			<path
				d="M7.03715 6.21705C6.51317 4.68751 6.25118 3.92274 6.56913 3.2859C6.88708 2.64906 7.66081 2.38885 9.20826 1.86843L10.8496 1.31645C12.397 0.796031 13.1707 0.535823 13.8143 0.849319C14.4578 1.16281 14.7198 1.92759 15.2437 3.45713L15.7995 5.07946C16.3235 6.609 16.5855 7.37377 16.2675 8.01061C15.9496 8.64745 15.1758 8.90766 13.6284 9.42808L11.9871 9.98006C10.4396 10.5005 9.6659 10.7607 9.02239 10.4472C8.37888 10.1337 8.11689 9.36893 7.59291 7.83938L7.03715 6.21705Z"
				stroke={color}
			/>
		</svg>
	);
};

export default ProducerCompanyIcon;
