import { IconInterface } from '../interfaces/icon.interface';

const RouteIcon = (props: IconInterface) => {
	const { color = '#454545' } = props;

	return (
		<svg
			width="16"
			height="18"
			viewBox="0 0 16 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.2222 15.2223L15.5758 15.5758C15.771 15.3806 15.771 15.064 15.5758 14.8687L15.2222 15.2223ZM4.55556 2.27783C4.27941 2.27783 4.05556 2.50169 4.05556 2.77783C4.05556 3.05397 4.27941 3.27783 4.55556 3.27783V2.27783ZM13.798 13.0909C13.6027 12.8957 13.2862 12.8957 13.0909 13.0909C12.8956 13.2862 12.8956 13.6028 13.0909 13.7981L13.798 13.0909ZM13.0909 16.6465C12.8956 16.8418 12.8956 17.1583 13.0909 17.3536C13.2862 17.5489 13.6027 17.5489 13.798 17.3536L13.0909 16.6465ZM15.2222 14.7223H4.11111V15.7223H15.2222V14.7223ZM4.11111 9.50005H12.1111V8.50005H4.11111V9.50005ZM12.1111 2.27783H4.55556V3.27783H12.1111V2.27783ZM15.5758 14.8687L13.798 13.0909L13.0909 13.7981L14.8687 15.5758L15.5758 14.8687ZM14.8687 14.8687L13.0909 16.6465L13.798 17.3536L15.5758 15.5758L14.8687 14.8687ZM15.7222 5.88894C15.7222 3.89458 14.1055 2.27783 12.1111 2.27783V3.27783C13.5532 3.27783 14.7222 4.44687 14.7222 5.88894H15.7222ZM12.1111 9.50005C14.1055 9.50005 15.7222 7.88331 15.7222 5.88894H14.7222C14.7222 7.33102 13.5532 8.50005 12.1111 8.50005V9.50005ZM1.5 12.1112C1.5 10.6691 2.66903 9.50005 4.11111 9.50005V8.50005C2.11675 8.50005 0.5 10.1168 0.5 12.1112H1.5ZM4.11111 14.7223C2.66903 14.7223 1.5 13.5532 1.5 12.1112H0.5C0.5 14.1055 2.11675 15.7223 4.11111 15.7223V14.7223Z"
				fill={color}
			/>
			<circle cx="2.77778" cy="2.77778" r="1.77778" stroke={color} />
		</svg>
	);
};

export default RouteIcon;
