import { IconInterface } from '../interfaces/icon.interface';

const ClockSemiDotted = (props: IconInterface) => {
	const { color = '#A2A2A2', width = 42, height = 42 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 42 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 21C1 32.0457 9.9543 41 21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1"
				stroke={color}
				strokeLinecap="round"
			/>
			<path
				d="M21 15.001V23.001H29"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="21"
				cy="21"
				r="20"
				stroke={color}
				strokeLinecap="round"
				strokeDasharray="0.5 3.5"
			/>
		</svg>
	);
};

export default ClockSemiDotted;
