import { IconInterface } from '../interfaces/icon.interface';

const PriceTag = (props: IconInterface) => {
	const { color = '#8B8B8B', width = 18, height = 18 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.18279 12.3095C1.94636 11.0731 1.32815 10.4549 1.09811 9.65278C0.868076 8.85069 1.06467 7.9988 1.45785 6.295L1.68459 5.31246C2.01538 3.87904 2.18077 3.16234 2.67156 2.67156C3.16234 2.18077 3.87904 2.01538 5.31246 1.68459L6.295 1.45785C7.9988 1.06467 8.85069 0.868076 9.65278 1.09811C10.4549 1.32815 11.0731 1.94636 12.3095 3.18279L13.7732 4.6465C15.9244 6.79768 17 7.87328 17 9.20986C17 10.5464 15.9244 11.622 13.7732 13.7732C11.622 15.9244 10.5464 17 9.20986 17C7.87328 17 6.79768 15.9244 4.6465 13.7732L3.18279 12.3095Z"
				stroke={color}
			/>
			<circle
				cx="6.28618"
				cy="6.50391"
				r="1.6"
				transform="rotate(-45 6.28618 6.50391)"
				stroke={color}
			/>
			<path
				d="M8.27945 13.8457C8.08419 14.0409 8.0842 14.3575 8.27946 14.5528C8.47473 14.748 8.79131 14.748 8.98657 14.5528L8.27945 13.8457ZM14.5698 8.96937C14.765 8.7741 14.765 8.45752 14.5698 8.26226C14.3745 8.067 14.0579 8.06701 13.8627 8.26227L14.5698 8.96937ZM8.98657 14.5528L14.5698 8.96937L13.8627 8.26227L8.27945 13.8457L8.98657 14.5528Z"
				fill={color}
			/>
		</svg>
	);
};

export default PriceTag;
