import Api from '../axios/api';
import { InvoiceList } from '../interfaces/invoice.interface';
import { InvoiceInterface } from '../components/PaymentsTable/interfaces/Payment.interface';

interface CreateDepositResponse {
	data: any;
}

export interface CreateDeposit {
	paymentMethod: string;
	amount: number;
}

export interface ResponseInvoiceList {
	data: InvoiceList;
}

export interface RequestInvoicePdfListDto {
	companyType?: string;
	fromDate: string;
	toDate: string;
}

export interface RequestInvoiceAdminListDto {
	limit?: number;
	offset?: number;
	orderId?: number;
	assigneeId?: string;
	statuses?: string[];
	fromDate?: string;
	toDate?: string;
	sortDirection?: string;
	sortBy?: string;
	search?: string;
	countryCode?: string;
}

export interface AdminStats {
	count: {
		all: string;
		paid: string;
		overdue: string;
		pending: string;
	};
	amountTotal: {
		all: string;
		paid: string;
		overdue: string;
		pending: string;
	};
}

export interface ResponseAdminStats {
	data: AdminStats;
}

export interface AcceptPayment {
	paymentAmount: number;
	paymentDate: string;
}

export interface UploadInvoicesBody {
	patchInvoiceUrls: string[];
}

export interface ResponseInvoice {
	data: InvoiceInterface;
}

export enum PaymentNotesStatusesEnum {
	PENDING_INVOICE = 'pending_invoice',
	UPLOADED_INVOICE = 'uploaded_invoice',
	INVOICE_CONFIRMED = 'invoice_confirmed',
	PAID = 'paid',
}

export class BillingService {
	static createDeposit(body: CreateDeposit): Promise<CreateDepositResponse> {
		return Api.post(`/v1/customer/billings/deposit`, {
			...body,
		});
	}

	static getInvoiceListAdmin(
		target: string,
		params: RequestInvoiceAdminListDto,
	): Promise<ResponseInvoiceList> {
		return Api.get(`/v1/admin/invoices/${target}`, {
			params: {
				...params,
				statuses: params.statuses?.filter((r) => r !== 'all'),
			},
		});
	}

	static downloadInvoices(params: RequestInvoicePdfListDto): Promise<any> {
		return Api.get(`/v1/admin/invoices/pdf/download`, {
			params,
			responseType: 'blob',
		});
	}

	static getInvoiceStatsAdmin(target: string): Promise<ResponseAdminStats> {
		return Api.get(`/v1/admin/stats/${target}`);
	}

	static acceptInvoice(
		target: string,
		invoiceId: number,
		body: AcceptPayment,
	): Promise<void> {
		return Api.post(`/v1/admin/invoices/${target}/${invoiceId}`, {
			...body,
		});
	}

	static getInvoiceById(
		target: string,
		orderId: number,
	): Promise<ResponseInvoice> {
		return Api.get(`/v1/${target}/invoices/${orderId}`);
	}

	static getInvoiceForAdmin(
		target: string,
		orderId: number,
	): Promise<ResponseInvoice> {
		return Api.get(`/v1/admin/invoices/${target}/${orderId}`);
	}

	static uploadInvoicesProducer(
		invoiceId: number,
		body: UploadInvoicesBody,
	): Promise<ResponseInvoice> {
		return Api.patch(`/v1/producer/invoices/${invoiceId}`, {
			...body,
		});
	}

	static confirmInvoiceAdmin(target: string, invoiceId: number): Promise<void> {
		return Api.post(`/v1/admin/invoices/${target}/${invoiceId}/confirm`);
	}

	static rejectInvoiceAdmin(target: string, invoiceId: number): Promise<void> {
		return Api.post(`/v1/admin/invoices/${target}/${invoiceId}/reject`);
	}
}
