import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	SypacBreadcrumbs,
	SypacButton,
	SypacLink,
	SypacText,
} from '@sypac/component-library-react';
import Close from '../../../../../assets/Close';
import { Category } from '../../../../../interfaces/category.interface';
import { T } from '@tolgee/react';
import './product-group.css';
import Flag from 'react-world-flags';
import { COUNTRIES } from '../../../../../components/DropDownOption/countries.constant';

export interface ProductGroupProps {
	groups?: Category[];
	onSelect: (category: Category) => void;
	title: string;
	description?: string;
	breadcrumbs?: {
		name: string;
		onClick: () => void;
	}[];
	countryCode?: string;
	showClose?: boolean;
}

const ProductGroup: React.FC<ProductGroupProps> = ({
	groups,
	onSelect,
	title,
	breadcrumbs,
	description,
	showClose,
	countryCode,
}) => {
	const { storeId } = useParams();
	const navigate = useNavigate();
	const [showBlur, setShowBlur] = useState<string | undefined>(undefined);

	const handleImageBlur = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	const renderGroup = (item: Category) => {
		return (
			<div
				key={item.id}
				className="flex flex-col gap-3 items-center border border-solid border-gray-10 rounded-xl p-[10px] cursor-pointer transition hover:border-gray-30"
				onClick={() => onSelect(item)}
				onMouseEnter={() => handleImageBlur(item.id)}
				onMouseLeave={() => handleImageBlur(undefined)}
			>
				<div
					style={{ backgroundImage: `url(${item.imageUrl})` }}
					className="relative w-[248px] h-[174px] bg-no-repeat bg-cover bg-center rounded-10"
					role="img"
				>
					{showBlur === item.id ? (
						<div className="absolute w-full h-full bg-black-20 rounded-10" />
					) : null}
				</div>

				<div className="flex flex-col gap-2 self-start">
					<SypacText variant="body-normal-large">
						<p className="max-w-[203px] text-xl text-gray-80 truncate">
							{item.name}
						</p>
					</SypacText>
					<SypacText variant="body-normal-large">
						<p className="text-sm text-gray-40">
							{item.countSubcategories !== 0 ? (
								<>
									{item.countSubcategories}{' '}
									<T keyName="clientSelectGroups.categoriesInThisGroup">
										categories in this group
									</T>
								</>
							) : (
								<>
									{item.countPrododucts}{' '}
									<T keyName="clientSelectCategory.productsInThisCategory">
										products in this category
									</T>
								</>
							)}
						</p>
					</SypacText>
				</div>
			</div>
		);
	};

	return (
		<div className="w-full h-full flex items-center justify-center">
			<div className="max-w-[844px] w-full flex flex-col">
				{showClose && (
					<div className="flex justify-between items-center mb-6">
						<SypacText variant="body-normal-medium">
							<p className="text-gray-60">
								<T keyName="productGroup.addNewProduct">Add new product</T>
							</p>
						</SypacText>
						<SypacButton variant="secondary" size="medium">
							<button
								onClick={() => navigate(`/products/${storeId}`)}
								className="p-2 border border-solid border-gray-10 transition hover:border-primary-violet"
							>
								<Close />
							</button>
						</SypacButton>
					</div>
				)}

				<div className="flex flex-col gap-2 mb-[60px]">
					<SypacText variant="heading-5">
						<p className="text-xl text-gray-80">{title}</p>
					</SypacText>

					{!!description && (
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-60">{description}</p>
						</SypacText>
					)}

					{!!breadcrumbs?.length && (
						<SypacBreadcrumbs>
							{breadcrumbs.map((item, i) => (
								<SypacLink key={i} variant="breadcrumbs">
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<a
										className={`text-sm ${
											i === breadcrumbs.length - 1
												? 'text-cornflower-blue pointer-events-none'
												: 'text-gray-60 cursor-pointer'
										}`}
										onClick={(e) => {
											e.preventDefault();
											item.onClick?.();
										}}
									>
										{i === 0 ? (
											<div className="flex items-center gap-3">
												<Flag
													className="mb-[2px] object-cover border border-solid border-gray-10 rounded"
													code={countryCode}
													width={20}
													height={14}
												/>
												<SypacText variant="body-regular-medium">
													<p className="text-sm mt-[2.5px]">
														{
															COUNTRIES.find(
																(country) => country['alpha-3'] === countryCode,
															)?.label
														}
													</p>
												</SypacText>
											</div>
										) : (
											item.name
										)}
									</a>
								</SypacLink>
							))}
						</SypacBreadcrumbs>
					)}
				</div>

				<div className="max-h-[832px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 overflow-y-auto scrollbar-custom">
					{groups?.map((item) => renderGroup(item))}
				</div>
			</div>
		</div>
	);
};

export default ProductGroup;
