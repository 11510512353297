import React, {
	DragEvent,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import Close from '../../../../assets/Close';
import FolderIcon from '../../../../assets/FolderIcon';
import { FileInterface } from '../../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { toastVariant } from '../../../../components/CompaniesTable/toastVariant/toastVariant';
import { FileService } from '../../../../services/file.service';
import { MAX_FILE_SIZE_MB } from '../../../UserSettings/constants';
import { OrderContext } from '../../../../context/OrderContext/order.context';
import PdfIcon from '../../../../assets/PdfIcon';
import { formatDate } from '../../../../utils/time.util';
import Trash from '../../../../assets/Trash';
import Download from '../../../../assets/Download';
import { BillingService } from '../../../../services/billing.service';
import { OrderDetailsContext } from '../../../../context/OrderDetailsContext/order-details.context';
import { ModalUploadInvoiceProps } from '../producerBilling.interface';
import { FileType } from '../../../../constants';
import { getFileType } from '../../../../utils/files.util';
import Emitter, { EventType } from '../../../../services/events';
import BouncingLoader from '../../../../components/BouncingLoader/BouncingLoader';
import DocumentsViewer from '../../../../components/DocumentsViewer/DocumentsViewer';

const ModalUploadInvoice: React.FC<ModalUploadInvoiceProps> = ({
	isOpen,
	onClose,
}) => {
	const { producerInvoice } = useContext(OrderDetailsContext);
	const { setAdditional } = useContext(OrderContext);
	const [fileSizes, setFileSizes] = useState<Record<string, number>>({});
	const [localFiles, setLocalFiles] = useState<FileInterface[]>([]);
	const [filesLoading, setFilesLoading] = useState<boolean>(false);
	const [openDocView, setOpenDocView] = useState<boolean>(false);
	const [openDocUrl, setOpenDocUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();
	const modalRef = useRef<HTMLDivElement>(null);

	const validateFile = (file: File): boolean => {
		if (file.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
			toastVariant(
				`File ${file.name} exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
				true,
			);
			return false;
		}
		return true;
	};

	const getDataFiles = async (files: File[]) => {
		setFilesLoading(true);
		try {
			const validFiles = files.filter(validateFile);
			if (validFiles.length === 0) return;

			const { data } = await FileService.uploadFiles(validFiles);

			const originalFilesMap = new Map(
				validFiles.map((file) => [file.name, file]),
			);

			const newFiles = data.items.map((newFile: any) => {
				const [name, type] = newFile.originalName?.split('.') || [];
				const mimeType =
					newFile.type?.split('/').pop()?.toUpperCase() ||
					type?.toUpperCase() ||
					'';

				const originalFile = originalFilesMap.get(newFile.originalName);
				const size = originalFile
					? `${(originalFile.size / (1024 * 1024)).toFixed(1)} MB`
					: '0 MB';

				return { name, mimeType, size, file: newFile };
			});

			setLocalFiles((prevFiles) => [...prevFiles, ...newFiles]);
		} catch (e) {
			toastVariant('Failed to upload files.', true);
		} finally {
			setFilesLoading(false);
		}
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			const droppedFiles = Array.from(e.dataTransfer.files);
			getDataFiles(droppedFiles).then(() => {});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			const selectedFiles = Array.from(e.target.files);
			getDataFiles(selectedFiles).then(() => {});
		}
	};

	const handleRemoveFile = (fileUrl: string) => {
		setLocalFiles((prevFiles) =>
			prevFiles.filter((file) => file.file.url !== fileUrl),
		);
	};

	const downloadDocument = async (fileUrl: string) => {
		if (!fileUrl) return;

		try {
			const response = await fetch(fileUrl);
			const blob = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(blob);
			const link = document.createElement('a');

			const fileName = new URL(fileUrl).pathname.split('/').pop();

			link.href = downloadUrl;
			link.download = fileName || 'document';
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	};

	const handleClose = () => {
		setLocalFiles([]);
		onClose();
	};

	const handleSend = async () => {
		const patchInvoiceUrls = localFiles.map((file) => file.file.url);

		try {
			await BillingService.uploadInvoicesProducer(producerInvoice?.id!, {
				patchInvoiceUrls,
			});
			toastVariant('Invoices uploaded successfully.', false);
			Emitter.emit(EventType.PAYMENT_NOTE_REFRESH);
			onClose();
		} catch (error) {
			toastVariant('Failed to upload invoices.', true);
		} finally {
			handleClose();
		}
	};

	const openFile = (url: string) => {
		setOpenDocUrl(url);
		setOpenDocView(true);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	const handleRemoveFileModal = () => {
		handleRemoveFile(openDocUrl!);
		setOpenDocView(false);
	};

	useEffect(() => {
		const fetchFileSizes = async () => {
			if (!producerInvoice?.invoiceUrls?.length) return;

			const sizePromises = producerInvoice.invoiceUrls.map(async (url) => {
				try {
					const response = await fetch(url, { method: 'HEAD' });
					const contentLength = response.headers.get('Content-Length');
					return { url, size: contentLength ? parseInt(contentLength, 10) : 0 };
				} catch (error) {
					console.error('Error fetching file size:', error);
					return { url, size: 0 };
				}
			});

			const results = await Promise.all(sizePromises);
			const sizeMap = results.reduce((acc, { url, size }) => {
				acc[url] = size;
				return acc;
			}, {} as Record<string, number>);

			setFileSizes(sizeMap);
		};

		fetchFileSizes().then(() => {});
	}, [producerInvoice?.invoiceUrls]);

	useEffect(() => {
		const files = producerInvoice?.invoiceUrls?.length
			? producerInvoice.invoiceUrls.map((url, index) => ({
					file: {
						lastModified: formatDate(producerInvoice.updatedAt),
						name: `invoice_${index + 1}.pdf`,
						size: fileSizes[url] || 0,
						webkitRelativePath: '',
						type: `${getFileType(url)}`,
						url,
					},
					mimeType: `${getFileType(url)?.toUpperCase()}`,
					name: `Invoice ${index + 1}`,
					size: fileSizes[url]
						? `${(fileSizes[url] / (1024 * 1024)).toFixed(2)} MB`
						: 'Unknown',
			  }))
			: [];

		setLocalFiles(files);
	}, [
		producerInvoice?.invoiceUrls,
		producerInvoice?.updatedAt,
		fileSizes,
		isOpen,
	]);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<>
			<div className="modal-overlay" onClick={onClose}>
				<div
					className="w-[660px] bg-white rounded-xl"
					onClick={(e) => e.stopPropagation()}
					ref={modalRef}
				>
					<div className="flex flex-col">
						<div className="flex items-center justify-between p-3">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-90">
									<T keyName="modalUploadInvoice.uploadInvoice">
										Upload invoice
									</T>
								</p>
							</SypacText>
							<SypacButton variant="subTitle">
								<button
									type="button"
									className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
									onClick={handleClose}
								>
									<Close width="10" height="10" />
								</button>
							</SypacButton>
						</div>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />

						{filesLoading && localFiles.length === 0 ? (
							<div className="h-[60px] flex items-center justify-center bg-white rounded-[15px]">
								<BouncingLoader />
							</div>
						) : (
							<>
								<div className="w-full flex flex-col gap-[14px] p-3 box-border">
									{localFiles.map((file, index) => (
										<React.Fragment key={file?.name || Math.random() + index}>
											<div className="flex gap-[14px]">
												{file.file.type === FileType.JPG ? (
													<div
														style={{
															backgroundImage: `url(${file.file.url})`,
														}}
														className="relative w-[76px] h-[76px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
														onClick={() => openFile(file.file.url)}
														onMouseEnter={() => handleBoxToggle(file.file.url)}
														onMouseLeave={() => handleBoxToggle(undefined)}
														role="img"
													>
														{showBlur === file.file.url && (
															<div className="bg-black-50 absolute rounded-[11px] backdrop-blur-sm h-full w-full flex justify-center items-center">
																<SypacIcon
																	size="custom"
																	width="32px"
																	height="32px"
																	icon-name="Magnifer"
																	className="text-white"
																/>
															</div>
														)}
													</div>
												) : (
													<div className="w-[76px] h-[76px] flex justify-center items-center border border-solid border-gray-10 rounded-lg">
														<PdfIcon />
													</div>
												)}
												<div className="flex flex-col">
													<SypacText variant="body-regular-medium">
														<p className="text-base text-gray-80">
															{file.name}
														</p>
													</SypacText>
													<div className="flex justify-start items-center mt-1">
														<SypacText variant="overline-regular-large">
															<p className="text-[10px] text-gray-40">
																{file.mimeType}{' '}
															</p>
														</SypacText>
														<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
														<SypacText variant="overline-regular-large">
															<p className="text-[10px] text-gray-40">
																{file.size}{' '}
															</p>
														</SypacText>
													</div>
													<div className="flex gap-3 justify-start items-center mt-2">
														<SypacText variant="overline-regular-large">
															<p className="text-xs text-gray-40">
																<T keyName="modalUploadInvoice.uploadedBy">
																	Uploaded by:
																</T>
															</p>
														</SypacText>
														<SypacText variant="overline-regular-large">
															<p className="text-xs text-gray-80">
																Dean Russel
															</p>
														</SypacText>
														<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
														<SypacText variant="overline-regular-large">
															<p className="text-xs text-gray-80">
																{formatDate(
																	new Date()
																		.toUTCString()
																		.replace(
																			'GMT',
																			'GMT+0000 (Coordinated Universal Time)',
																		),
																)}
															</p>
														</SypacText>
													</div>
												</div>

												<div className="flex gap-3 ml-auto">
													<SypacButton variant="secondary" size="small">
														<button
															type="button"
															className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
															onClick={() => handleRemoveFile(file.file.url)}
														>
															<Trash />
														</button>
													</SypacButton>
													<SypacButton variant="secondary" size="small">
														<button
															type="button"
															className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
															onClick={() => downloadDocument(file.file.url)}
														>
															<Download />
														</button>
													</SypacButton>
												</div>
											</div>
											{index !== localFiles.length - 1 ? (
												<div className="border-0 border-solid border-t-[1px] border-gray-10" />
											) : null}
										</React.Fragment>
									))}
								</div>
								<div className="border-0 border-solid border-t-[1px] border-gray-10" />
							</>
						)}

						<div className="flex flex-col gap-3 p-3">
							<div className="flex flex-col">
								<SypacText className="mb-1" variant="overline-normal-large">
									<p>
										<T keyName="modalUploadInvoice.uploadFile">Upload files</T>
									</p>
								</SypacText>
								<div
									onDragEnter={handleDrag}
									onDragLeave={handleDrag}
									onDragOver={handleDrag}
									onDrop={handleDrop}
									className="flex px-5 py-[25px] bg-white border border-dashed border-gray-40 rounded-lg cursor-pointer transition hover:border-gray-50"
								>
									<input
										type="file"
										id="input-file-upload"
										className="hidden"
										multiple={true}
										accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
										onChange={handleChange}
									/>
									<label
										htmlFor="input-file-upload"
										className="w-full flex gap-6 items-center justify-center cursor-pointer"
									>
										<FolderIcon />
										<div className="flex flex-col gap-[10px]">
											<SypacText variant="body-regular-medium" className="flex">
												<p className="text-gray-80">
													<T keyName="userSettings.dragAndDrop">
														Drag and drop or
													</T>
												</p>
												&nbsp;
												<p className="text-cornflower-blue underline">
													<T keyName="userSettings.browseFile">Browse File</T>
												</p>
											</SypacText>
											<SypacText variant="body-regular-medium">
												<p className="text-xs text-nevada">
													<T keyName="modalAddProduct.weSupport">
														We support JPEG, or PNG, files. Max file size: 2 MB.
													</T>
												</p>
											</SypacText>
										</div>
									</label>
								</div>
							</div>
						</div>

						<div className="flex w-full py-2.5 xl-2xl:py-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl mt-auto">
							<SypacButton
								variant="secondary"
								size="small"
								className="w-full pl-2.5 xl-2xl:pl-3"
							>
								<button
									type="button"
									className="w-full py-2.5 rounded-lg"
									onClick={handleClose}
								>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="modalChangeRequest.cancel">Cancel</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton
								variant="primary"
								size="small"
								className="w-full pr-2.5 xl-2xl:pr-3"
							>
								<button
									type="button"
									disabled={localFiles.length === 0}
									className="w-full py-2.75 rounded-lg"
									onClick={handleSend}
								>
									<SypacText variant="body-regular-medium">
										<p className="text-white">
											<T keyName="modalUploadInvoice.sendToApproval">
												Send to approval
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>
				</div>
			</div>
			{openDocView ? (
				<DocumentsViewer
					url={openDocUrl!}
					isPdf={false}
					isOpen={openDocView}
					onClose={() => setOpenDocView(false)}
					canDelete={true}
					handleDelete={handleRemoveFileModal}
					canDownload={true}
				/>
			) : null}
		</>
	) : null;
};

export default ModalUploadInvoice;
