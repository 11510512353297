import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	NotificationInterface,
	OrderInterface,
} from '../../../components/OrdersTable/interfaces/Order.interface';
import {
	OrderStatesEnum,
	OrderStatusesEnum,
} from '../../../services/orders.services';
import Map from '../../../components/Map/Map';
import { GeoService } from '../../../services/geo.services';
import {
	MapMarker,
	MapPolyline,
	PinVariant,
} from '../../../components/Map/Map.interface';
import { NotificationsService } from '../../../services/notifications.services';
import Emitter, { EventType } from '../../../services/events';
import CreatedStep from './components/Steps/Created';
import PreparingStep from './components/Steps/Preparing';
import ToUnloadingStep from './components/Steps/ToUnloading';
import UnloadingStep from './components/Steps/Unloading';
import CompletedStep from './components/Steps/Completed';
import PendingPaymentStep from './components/Steps/PendingPayment';
import { OrderDetailsProps } from './clientOrder.interface';
import { OrderDetailsContext } from '../../../context/OrderDetailsContext/order-details.context';
import { BillingService } from '../../../services/billing.service';

const OrderDetails: React.FC<OrderDetailsProps> = ({
	isOpen = false,
	selectedOrder,
}) => {
	const { setCustomerInvoice } = useContext(OrderDetailsContext);
	const [currentTab] = useState<string>('overview');
	const [showMap, setShowMap] = useState<boolean>(false);
	const [poligon, setPoligon] = useState<MapPolyline>();
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	let updateTimeout: string | number | NodeJS.Timeout | undefined;

	const getRoute = useCallback(async () => {
		try {
			const latitude = (
				selectedOrder?.pickupLocation &&
				selectedOrder.pickupLocation?.coordinates?.at(1)
					? selectedOrder.pickupLocation?.coordinates?.at(1)
					: 47.0431905
			)!;
			const longitude = (
				selectedOrder?.pickupLocation &&
				selectedOrder.pickupLocation?.coordinates?.at(0)
					? selectedOrder.pickupLocation?.coordinates?.at(0)
					: 28.7929007
			)!;
			const deliveryLatitude =
				selectedOrder?.deliveryLocation &&
				selectedOrder.deliveryLocation?.coordinates?.at(1)
					? selectedOrder.deliveryLocation?.coordinates?.at(1)
					: 51.7738652;
			const deliveryLongitude =
				selectedOrder?.deliveryLocation &&
				selectedOrder.deliveryLocation?.coordinates?.at(0)
					? selectedOrder.deliveryLocation?.coordinates?.at(0)
					: 19.4116509;

			const markers: MapMarker[] = [
				{ latitude, longitude, variant: PinVariant.pickup },
			];
			if (latitude !== deliveryLatitude && longitude !== deliveryLongitude) {
				markers.push({
					latitude: deliveryLatitude!,
					longitude: deliveryLongitude!,
					variant: PinVariant.delivery,
				});
			}
			if (
				selectedOrder?.status &&
				![
					OrderStatusesEnum.DELIVERED.toString(),
					OrderStatusesEnum.COMPLETED.toString(),
				].includes(selectedOrder?.status) &&
				selectedOrder?.truck
			) {
				markers.push({
					latitude:
						selectedOrder?.truck.lastLocation?.coordinates[1] || 47.04014,
					longitude:
						selectedOrder?.truck.lastLocation?.coordinates[0] || 28.80058,
					variant: PinVariant.truck,
					truck: selectedOrder?.truck,
				});
			}
			setMarkers(markers);

			const dataRoutes = await GeoService.getRoute({
				points: [
					`${latitude},${longitude}`,
					`${deliveryLatitude},${deliveryLongitude}`,
				],
			});

			const routes = dataRoutes.data.map((r, index) => {
				const routePoints: number[][] = JSON.parse(r.shape);
				return {
					id: index,
					distance: r.distance || 0,
					trafficTime: r.trafficTime || 0,
					orderRoute: true,
					coordinates: routePoints.map((r: number[]) => ({
						lat: r[0],
						lng: r[1],
					})),
				};
			});

			setPoligon(routes[0]);
		} catch (e) {
			console.log(e);
		}
	}, [
		selectedOrder?.deliveryLocation,
		selectedOrder?.pickupLocation,
		selectedOrder?.status,
		selectedOrder?.truck,
	]);

	const markReadNotifications = useCallback(async () => {
		const notificationIds = selectedOrder?.notifications?.items
			?.filter((r) => !r.readAt)
			?.map((r) => r.id);
		try {
			if (notificationIds?.length) {
				await NotificationsService.readNotify(notificationIds);
				Emitter.emit(EventType.NOTIFICATION_COUNT_DECREASE, 1);
			}
			Emitter.emit(
				EventType.ORDER_NOTIFICATION_COUNT_DECREASE,
				selectedOrder?.id!,
			);
		} catch (e) {}
	}, [selectedOrder]);

	const getInvoice = useCallback(async () => {
		if (
			!selectedOrder?.id ||
			!selectedOrder?.target ||
			(selectedOrder.deliveryIncluded
				? selectedOrder.state !== OrderStatesEnum.COMPLETED
				: selectedOrder.status !== OrderStatusesEnum.PENDING_PAYMENT)
		) {
			return;
		}

		try {
			const { data } = await BillingService.getInvoiceById(
				selectedOrder.target,
				selectedOrder.id,
			);
			setCustomerInvoice(data);
		} catch (e) {}
	}, [selectedOrder, setCustomerInvoice]);

	const setUpdateLocation = useCallback(
		(notify: NotificationInterface) => {
			if (!markers.length) {
				return;
			}
			const lastMarkers = JSON.parse(JSON.stringify(markers)) as MapMarker[];
			const newMarkers = lastMarkers.map((r) => {
				if (r.variant === PinVariant.truck) {
					return {
						...r,
						latitude: notify.content?.lat
							? parseFloat(notify.content.lat)
							: r.latitude,
						longitude: notify.content?.long
							? parseFloat(notify.content.long)
							: r.longitude,
					};
				}
				return r;
			});
			setMarkers(newMarkers);
		},
		[markers],
	);

	const checkUpdateLocation = useCallback((notify: NotificationInterface) => {
		const orderId = parseInt(notify.groupId);
		if (orderId === selectedOrder?.id) {
			clearTimeout(updateTimeout);
			// eslint-disable-next-line react-hooks/exhaustive-deps
			updateTimeout = setTimeout(() => {
				setUpdateLocation(notify);
			}, 2000);
		}
		return () => {
			clearTimeout(updateTimeout);
		};
	}, []);

	useEffect(() => {
		getInvoice().then(() => {});
	}, [getInvoice]);

	useEffect(() => {
		Emitter.on(
			EventType.TRUCK_UPDATE_LOCATION,
			(notify: NotificationInterface) => {
				if (notify.groupId) {
					checkUpdateLocation(notify);
				}
			},
		);
		return () => {
			Emitter.off(EventType.TRUCK_UPDATE_LOCATION);
		};
	}, [checkUpdateLocation]);

	useEffect(() => {
		if (selectedOrder?.id) {
			markReadNotifications().then(() => {});
		}
	}, [markReadNotifications, selectedOrder?.id]);

	useEffect(() => {
		getRoute().then(() => {});

		return () => {
			setShowMap(false);
			setPoligon(undefined);
			setMarkers([]);
		};
	}, [getRoute, selectedOrder?.id]);

	const renderSimpleSteps = (order: OrderInterface) => {
		const { state, status } = order;

		if (status === OrderStatusesEnum.PAID) {
			return <CompletedStep selectedOrder={order} />;
		}

		switch (state) {
			case OrderStatesEnum.TO_DO:
			case OrderStatesEnum.AWAITING_CONFIRMATION:
			case OrderStatesEnum.TRANSPORTER_CONFIRMED:
				return <CreatedStep selectedOrder={order} />;

			case OrderStatesEnum.TRUCK_CONFIRMED:
			case OrderStatesEnum.TO_LOADING:
			case OrderStatusesEnum.ARRIVED_TO_LOADING:
			case OrderStatusesEnum.LOADING:
				return <PreparingStep selectedOrder={order} />;

			case OrderStatusesEnum.LOADED:
			case OrderStatesEnum.TO_UNLOADING:
				return <ToUnloadingStep selectedOrder={order} />;

			case OrderStatusesEnum.ARRIVED_TO_UNLOADING:
			case OrderStatusesEnum.UNLOADING:
			case OrderStatusesEnum.UNLOADED:
				return <UnloadingStep selectedOrder={order} />;

			case OrderStatusesEnum.COMPLETED:
				return <PendingPaymentStep selectedOrder={order} />;

			default:
				return null;
		}
	};

	const renderMultipleSteps = (order: OrderInterface) => {
		const { status, state } = order;

		if (status === OrderStatusesEnum.PAID) {
			return <CompletedStep selectedOrder={order} />;
		} else if (order?.trucks?.every((obj) => obj.orderStatus === 'completed')) {
			return <UnloadingStep selectedOrder={order} />;
		} else if (
			status === OrderStatusesEnum.PREPARING ||
			status === OrderStatusesEnum.TO_UNLOADING ||
			status === OrderStatusesEnum.ARRIVED_TO_UNLOADING ||
			status === OrderStatusesEnum.DELIVERED
		) {
			return <ToUnloadingStep selectedOrder={order} />;
		}

		switch (state) {
			case OrderStatesEnum.TO_DO:
				return <CreatedStep selectedOrder={order} />;

			case OrderStatesEnum.AWAITING_CONFIRMATION:
			case OrderStatesEnum.TRANSPORTER_CONFIRMED:
			case OrderStatesEnum.TRUCK_CONFIRMED:
				return <PreparingStep selectedOrder={order} />;

			default:
				return null;
		}
	};

	const renderSimpleExcluded = (order: OrderInterface) => {
		const { status } = order;

		switch (status) {
			case OrderStatusesEnum.CREATED:
				return <CreatedStep selectedOrder={order} />;
			case OrderStatusesEnum.PREPARING:
				return <PreparingStep selectedOrder={order} />;
			case OrderStatusesEnum.IN_PROGRESS:
				return <ToUnloadingStep selectedOrder={order} />;
			case OrderStatusesEnum.DELIVERED:
				return <PendingPaymentStep selectedOrder={order} />;
			case OrderStatusesEnum.PAID:
				return <CompletedStep selectedOrder={order} />;
			default:
				return null;
		}
	};

	const renderMultipleExcluded = (order: OrderInterface) => {
		const { status } = order;

		if (
			(status === OrderStatusesEnum.IN_PROGRESS ||
				status === OrderStatusesEnum.PREPARING) &&
			order?.trucks?.some((obj) => 'producerLoadDetails' in obj)
		) {
			return <ToUnloadingStep selectedOrder={order} />;
		}

		if (
			status === OrderStatusesEnum.DELIVERED &&
			order?.trucks?.every((obj) => 'producerLoadDetails' in obj)
		) {
			return <UnloadingStep selectedOrder={order} />;
		}

		switch (status) {
			case OrderStatusesEnum.CREATED:
				return <CreatedStep selectedOrder={order} />;
			case OrderStatusesEnum.PREPARING:
			case OrderStatusesEnum.IN_PROGRESS:
				return <PreparingStep selectedOrder={order} />;
			case OrderStatusesEnum.DELIVERED:
				return <ToUnloadingStep selectedOrder={order} />;

			case OrderStatusesEnum.PAID:
				return <CompletedStep selectedOrder={order} />;

			default:
				return null;
		}
	};

	const renderOverviewTab = (order: OrderInterface | undefined) => {
		if (!order) return null;
		const { trucks, deliveryIncluded } = order;
		const simpleOrder = trucks?.length === 1;

		return simpleOrder
			? deliveryIncluded
				? renderSimpleSteps(order)
				: renderSimpleExcluded(order)
			: deliveryIncluded
			? renderMultipleSteps(order)
			: renderMultipleExcluded(order);
	};

	return (
		<>
			{isOpen ? (
				<div className="w-[716px] h-full flex flex-col absolute top-0 right-0 bg-alabaster shadow-order-details z-[100] overflow-y-auto scroll-smooth rounded-2xl sypac-scrollbar">
					<>
						{currentTab === 'overview' && renderOverviewTab(selectedOrder)}

						{currentTab === 'overview' ? (
							<>
								{showMap && markers.length && selectedOrder?.status ? (
									<div className="mt-14 h-96">
										<Map
											target="customer"
											polygons={poligon ? [poligon] : []}
											markers={markers}
											orderStatus={selectedOrder?.status}
										/>
									</div>
								) : null}

								{/* <div className="flex flex-row justify-center mt-20">
									<SypacButton variant="primary" size="large">
										<button>
											<T keyName="orderDetails.chatWithSypac">
												Chat with sypac
											</T>
										</button>
									</SypacButton>
								</div> */}
							</>
						) : null}
					</>
				</div>
			) : null}
		</>
	);
};

export default OrderDetails;
