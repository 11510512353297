import { IconInterface } from '../interfaces/icon.interface';

const ClockSmall = (props: IconInterface) => {
	const {
		color = '#454545',
		reverseColor,
		secondColor,
		width = 18,
		height = 18,
	} = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="9" cy="9" r="9" fill={reverseColor ? 'white' : color} />
			<path
				d="M9 5.39844V8.99844L11.25 11.2484"
				stroke={reverseColor ? color : secondColor || 'white'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ClockSmall;
