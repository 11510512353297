import React, { useState } from 'react';
import {
	SypacText,
	SypacBookTabs,
	SypacTabBook,
} from '@sypac/component-library-react';
import { useTranslate, T } from '@tolgee/react';
import ProducerAndCarrierBilling from './components/ProducerAndCarrierBilling';
import ClientBillingForAdmin from './components/ClientBillingForAdmin';
import { Sorting } from '../../../components/Sorting/Sorting';
import { SortingItemInterface } from '../../../components/Sorting/Sorting.interface';

const Billing: React.FC = () => {
	const { t } = useTranslate();
	const [tab, setTab] = useState<string>('client');
	const [sorting, setSorting] = useState<SortingItemInterface>();
	const [openDownload, setOpenDownload] = useState<boolean>(false);
	const sortOptions = [
		{
			title: t('adminProducts.poland', 'Poland'),
			value: 'PL',
		},
		{
			title: t('adminProducts.moldova', 'Moldova'),
			value: 'MD',
		},
	];

	return (
		<main className="relative flex flex-col w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
			<header className="flex gap-6 items-center ml-5 mb-5 mr-[30px]">
				<SypacText variant="heading-4">
					<p className="text-gray-80">
						<T keyName="adminBilling.payments">Payments</T>
					</p>
				</SypacText>

				<div className="ml-auto">
					<Sorting
						options={sortOptions}
						action={(item) => setSorting(item)}
						current={sortOptions[1]}
						label={{
							title: 'Store:',
							key: 'store',
						}}
						customDefault={{
							title: 'All',
							key: 'all',
						}}
						dropDownStyle="-left-[58px]"
					/>
				</div>
				{/* <SypacButton variant="secondary" size="small" className="ml-auto">
					<button
						type="button"
						className="h-[44px] flex items-center justify-center gap-3 border-solid border-gray-10 rounded-lg whitespace-nowrap transition hover:border-gray-40"
						onClick={handleDownloadInvoices}
					>
						<span className="scale-[1.1] mt-[2px]">
							<Download />
						</span>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								<T keyName="productDetails.rejectProduct">Download invoices</T>
							</p>
						</SypacText>
					</button>
				</SypacButton> */}
			</header>

			<nav className="flex justify-between border-0 border-solid border-b-[1px] border-gray-10 mb-[30px]">
				<section className="w-[616px] ml-5">
					<SypacBookTabs className="mt-3.5">
						<SypacTabBook
							label={t('billing.client', 'Buyer')}
							button-id="client"
							onClick={() => setTab('client')}
						/>
						<SypacTabBook
							label={t('billing.producer', 'Seller')}
							button-id="producer"
							onClick={() => setTab('producer')}
						/>
						<SypacTabBook
							label={t('billing.transporter', 'Carrier')}
							button-id="transporter"
							onClick={() => setTab('transporter')}
						/>
					</SypacBookTabs>
				</section>
			</nav>

			<section className="flex h-full">
				{tab === 'transporter' ? (
					<ProducerAndCarrierBilling
						target="transporter"
						countryQuery={sorting?.value}
					/>
				) : tab === 'producer' ? (
					<ProducerAndCarrierBilling countryQuery={sorting?.value} />
				) : (
					<ClientBillingForAdmin
						countryQuery={sorting?.value}
						openDownload={openDownload}
						setOpenDownload={setOpenDownload}
					/>
				)}
			</section>
		</main>
	);
};

export default Billing;
