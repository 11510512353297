import Api from '../axios/api';
import { Category } from '../interfaces/category.interface';

export interface CategoryBody {
	name: string;
	description?: string;
	parentId?: string;
	imageUrl?: string;
	translations?: {
		language: string;
		name: string;
		description?: string;
	}[];
}

export class CategoriesService {
	static createCategory(body: CategoryBody): Promise<Category> {
		return Api.post('/v1/admin/categories', body);
	}

	static updateCategory(
		categoryId: string,
		body: CategoryBody,
	): Promise<Category> {
		return Api.patch(`/v1/admin/companies/${categoryId}`, body);
	}

	static deleteCategory(categoryId: string): Promise<void> {
		return Api.delete(`/v1/transporter/fleets/${categoryId}`);
	}
}
