import { IconInterface } from '../interfaces/icon.interface';

const EyeIcon = (props: IconInterface) => {
	const { color = '#454545', width = 16, height = 14, hoverEffect } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={
				hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
			}
		>
			<path
				d="M10.5616 7.00247C10.5616 8.41892 9.41697 9.56353 8.00052 9.56353C6.58406 9.56353 5.43945 8.41892 5.43945 7.00247C5.43945 5.58602 6.58406 4.44141 8.00052 4.44141C9.41697 4.44141 10.5616 5.58602 10.5616 7.00247Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				className={
					hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
				}
			/>
			<path
				d="M8 12.9196C10.5253 12.9196 12.8789 11.4316 14.5171 8.85627C15.161 7.84758 15.161 6.15213 14.5171 5.14344C12.8789 2.56807 10.5253 1.08008 8 1.08008C5.47471 1.08008 3.1211 2.56807 1.48288 5.14344C0.839039 6.15213 0.839039 7.84758 1.48288 8.85627C3.1211 11.4316 5.47471 12.9196 8 12.9196Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				className={
					hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
				}
			/>
		</svg>
	);
};

export default EyeIcon;
