import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { DonutChartProps } from './ApexDonutChart.interface';

const ApexDonutChart: React.FC<DonutChartProps> = ({
	labels,
	series,
	total,
	colors = ['#48d4bb', '#ffb352', '#f44a77'],
	width = 200,
	thickness = 50,
}) => {
	const isEmptySeries = !series || series.length === 0;
	if (isEmptySeries) {
		series = [1];
		colors = ['#fafafa'];
		total = 0;
	}

	const computedTotal = total ?? series.reduce((sum, value) => sum + value, 0);

	const chartOptions: ApexOptions = {
		labels,
		colors,
		chart: {
			width,
		},
		plotOptions: {
			pie: {
				offsetY: 8,
				offsetX: -10,
				donut: {
					size: `${thickness}%`,
					labels: {
						show: true,
						total: {
							show: true,
							showAlways: true,
							label: '',
							formatter: () => computedTotal.toString(),
						},
					},
				},
				expandOnClick: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		legend: {
			show: false,
		},
		stroke: {
			width: isEmptySeries ? 2 : 0,
			colors: ['#e8e8e8'],
		},
		states: {
			active: {
				filter: {
					type: 'none',
				},
			},
			hover: {
				filter: {
					type: 'none',
				},
			},
		},
		tooltip: {
			enabled: !isEmptySeries,
			y: {
				formatter: (value) => value.toString(),
			},
		},
	};

	return (
		<div className="chart-container">
			<ReactApexChart
				options={chartOptions}
				series={series}
				type="donut"
				width={width}
			/>
		</div>
	);
};

export default ApexDonutChart;
