import { IconInterface } from '../interfaces/icon.interface';

export const CheckmarkCircle = (props: IconInterface) => {
	const { color = '#FFFFFF', width = 16, height = 16 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="8" cy="8" r="7" stroke={color} />
			<path
				d="M5.55078 8.35L6.95078 9.75L10.4508 6.25"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
