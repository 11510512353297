import { IconInterface } from '../interfaces/icon.interface';

const Trash = (props: IconInterface) => {
	const { color = '#454545', width = 18, height = 18 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M16.5565 3.66602H1.44531" stroke={color} strokeLinecap="round" />
			<path
				d="M15.0739 5.88867L14.6651 12.0212C14.5078 14.3811 14.4291 15.5611 13.6602 16.2804C12.8913 16.9998 11.7087 16.9998 9.34357 16.9998H8.65614C6.29098 16.9998 5.1084 16.9998 4.33951 16.2804C3.57061 15.5611 3.49195 14.3811 3.33462 12.0212L2.92578 5.88867"
				stroke={color}
				strokeLinecap="round"
			/>
			<path
				d="M4.11328 3.66667C4.16295 3.66667 4.18779 3.66667 4.2103 3.6661C4.94225 3.64755 5.58797 3.18214 5.83703 2.49362C5.84469 2.47244 5.85254 2.44888 5.86825 2.40176L5.95455 2.14286C6.02822 1.92185 6.06506 1.81134 6.11392 1.71751C6.30885 1.34318 6.6695 1.08323 7.08627 1.01668C7.19073 1 7.30721 1 7.54018 1H10.4642C10.6971 1 10.8136 1 10.9181 1.01668C11.3348 1.08323 11.6955 1.34318 11.8904 1.71751C11.9393 1.81134 11.9761 1.92184 12.0498 2.14286L12.1361 2.40176C12.1518 2.44881 12.1597 2.47245 12.1673 2.49362C12.4164 3.18214 13.0621 3.64755 13.794 3.6661C13.8166 3.66667 13.8414 3.66667 13.8911 3.66667"
				stroke={color}
			/>
		</svg>
	);
};

export default Trash;
