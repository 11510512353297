import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import { useTranslate } from '@tolgee/react';
import DriverMultipleNotification from '../../../../../../components/Notification/DriverMultipleNotification';
import dayjs from 'dayjs';

interface NotificationStartTripToLoadingProps {
	notification: boolean;
	date: string;
	eta: string;
	truckType: string;
	truckPlates: string;
	driverName: string;
	loadingPlace: string;
}

const NotificationStartTripToLoading = ({
	notification,
	date,
	eta,
	truckType,
	truckPlates,
	driverName,
	loadingPlace,
}: NotificationStartTripToLoadingProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={260}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<DriverMultipleNotification
					notification={notification}
					title={t(
						'timeline.pendingArrivingLoadingPlace',
						'Pending arriving at the loading place',
					)}
					description={t(
						'timeline.pendingHisArrivalLoadingGoods',
						'Pending his arrival and loading of goods.',
					)}
					eta={dayjs(eta).hour(16).minute(0).format('DD.MM.YYYY — HH:mm')}
					truckType={truckType}
					truckPlates={truckPlates}
					driverName={driverName}
					loadingPlace={loadingPlace}
				/>
			</div>
		</div>
	);
};

export default NotificationStartTripToLoading;
