import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import { Sorting } from '../../../components/Sorting/Sorting';
import SearchBar from '../../../components/SearchBar/SearchBar';
import useDebounce from '../../../hooks/useDebounce';
import { MutatingDots } from 'react-loader-spinner';
import { ProductsTable } from '../../../components/ProductsTable/ProductsTable';
import Pagination from '../../../components/Pagination/Pagination';
import { ProductService } from '../../../services/product.services';
import { ProductInterface } from '../../../interfaces/product.interface';
import { useNavigate } from 'react-router-dom';
import ProductDetailsDatabase from './components/ProductDetailsDatabase/ProductDetailsDatabase';
import CustomBookTabs from '../../../components/CustomBookTabs/CustomBookTabs';
import { useGetCategories } from '../../../hooks/use-get-categories';
import { ProductContext } from '../../../context/DatabaseProductContext/database-product.context';
import ManageDatabaseIcon from '../../../assets/ManageDatabaseIcon';
import Emitter, { EventType } from '../../../services/events';
import Close from '../../../assets/Close';
import { SortingItemInterface } from '../../../components/Sorting/Sorting.interface';

const ITEMS_PER_PAGE = 12;
const DEFAULT_GROUP_ID = '1fcbedea-6bdd-41f9-95ba-83c6c8b483e3';

const ProductDatabase: React.FC = () => {
	const { t } = useTranslate();
	const { additional } = useContext(ProductContext);
	const navigate = useNavigate();
	const refs = useRef<(HTMLElement | null)[]>([]);

	const [groups] = useGetCategories({}, 'admin');
	const [activeGroup, setActiveGroup] = useState<string>(DEFAULT_GROUP_ID);
	const [categories] = useGetCategories({ parentIds: [activeGroup] }, 'admin');
	const [activeCategory, setActiveCategory] = useState<string>('');
	const [searchQuery, setSearchQuery] = useState<string>();
	const [products, setProducts] = useState<ProductInterface[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [selectedProduct, setSelectedProduct] = useState<ProductInterface>();
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [countryTab, setCountryTab] = useState<string>('all');
	const [activeLanguage, setActiveLanguage] = useState<string>('');
	const [isSortingCleared, setIsSortingCleared] = useState<boolean>(false);
	const [resetSorting, setResetSorting] = useState<boolean>(false);
	const [resetGroupSorting, setResetGroupSorting] = useState<boolean>(false);

	const search = useDebounce(searchQuery, 500);

	const currentGroup = useMemo(() => {
		if (!groups?.items?.length) return undefined;
		const group = groups.items.find((group) => group.id === activeGroup);
		return group ? { title: group.name, value: group.id } : undefined;
	}, [activeGroup, groups?.items]);

	const sortingOptions = useMemo(
		() => ({
			groups:
				groups?.items?.map((group) => ({
					title: group.name,
					value: group.id,
				})) || [],
			categories:
				categories?.items?.map((category) => ({
					title: category.name,
					value: category.id,
				})) || [],
		}),
		[groups?.items, categories?.items],
	);

	const countryTabs = useMemo(
		() => [
			{ key: 'all', label: t('adminProducts.allCountries', 'All countries') },
			{ key: 'POL', label: t('adminProducts.poland', 'Poland'), flag: 'PL' },
			{ key: 'MDA', label: t('adminProducts.moldova', 'Moldova'), flag: 'MD' },
		],
		[t],
	);

	const getProductDatabase = useCallback(async () => {
		setLoading(true);
		try {
			const { data } = await ProductService.getProductDatabase({
				limit: ITEMS_PER_PAGE,
				offset: page * ITEMS_PER_PAGE,
				search,
				categoryId: activeCategory,
				countryCode: countryTab,
			});
			setProducts(data.items);
			setTotalCount(data.count);
		} catch (error) {
			console.error('Failed to fetch products:', error);
		} finally {
			setLoading(false);
		}
	}, [page, search, activeCategory, countryTab]);

	const handleProductClick = useCallback(
		(product: ProductInterface, locale?: string) => {
			setSelectedProduct(products.find((p) => p.id === product.id));
			setShowDetails(true);
			if (locale) setActiveLanguage(locale);
		},
		[products],
	);

	const handleDeleteProduct = async (id: number) => {
		try {
			await ProductService.deleteProductDatabase(id);
			setSelectedProduct(undefined);
			setShowDetails(false);
			await getProductDatabase();
		} catch (error) {
			console.error('Failed to delete product:', error);
		}
	};

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			const mountedRefs = refs.current.filter(Boolean);
			const mountedModalRefs = additional.modalRefs.filter(Boolean);

			const isOutsideRefs = mountedRefs.every(
				(ref) => !ref?.contains(event.target as Node),
			);
			const isOutsideModals = mountedModalRefs.every(
				(modalRef) => !modalRef.current?.contains(event.target as Node),
			);

			if (isOutsideRefs && isOutsideModals) {
				setSelectedProduct(undefined);
				setShowDetails(false);
			}
		},
		[additional.modalRefs],
	);

	const handleSortingReset = () => {
		setIsSortingCleared(true);
		setActiveGroup('');
		setActiveCategory('');
		setResetSorting(true);
		setTimeout(() => setResetSorting(false), 100);
	};

	const handleCategoryChange = useCallback(
		(item: SortingItemInterface) => {
			if (!activeGroup && groups?.items?.length) {
				setActiveGroup(groups.items[0].id);
			}
			setActiveCategory(item.value!);
			setResetGroupSorting(true);
			setIsSortingCleared(false);
			setTimeout(() => setResetGroupSorting(false), 100);
		},
		[activeGroup, groups?.items],
	);

	useEffect(() => {
		getProductDatabase().then(() => {});
	}, [getProductDatabase]);

	useEffect(() => {
		if (!activeGroup && !isSortingCleared && groups?.items?.length) {
			setActiveGroup(groups.items[0].id);
		}
	}, [groups?.items, activeGroup, isSortingCleared]);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [handleClickOutside]);

	useEffect(() => {
		const refreshHandler = () => getProductDatabase();
		Emitter.on(EventType.PRODUCT_DATABASE_REFRESH, refreshHandler);
		return () => {
			Emitter.off(EventType.PRODUCT_DATABASE_REFRESH, refreshHandler);
		};
	}, [getProductDatabase]);

	return (
		<main className="relative flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
			<header className="flex flex-col gap-4 mx-[30px]">
				<SypacText variant="heading-4">
					<p className="text-gray-80">
						<T keyName="productDatabase.productDatabase">Product database</T>
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">
						<T keyName="productDatabase.hereYou can manage all products in system.">
							Here you can manage all products in system.
						</T>
					</p>
				</SypacText>
			</header>

			<CustomBookTabs
				tabs={countryTabs}
				activeTab={countryTab}
				onTabChange={setCountryTab}
			/>

			<section className="flex items-center gap-[40px] md:flex-col lg:flex-row mx-[30px]">
				<SearchBar
					placeholder={t(
						'productDatabase.productDatabaseSearch',
						'Search product ID and product name',
					)}
					onChange={setSearchQuery}
					showButton={false}
					classNames="w-[318px]"
				/>

				<Sorting
					options={sortingOptions.groups}
					action={(item) => {
						setIsSortingCleared(false);
						setActiveGroup(item.value!);
					}}
					current={currentGroup}
					label={{ title: 'Showing:', key: 'showing' }}
					customDefault={{ title: 'All groups', key: 'allGroups' }}
					shouldReset={resetSorting || resetGroupSorting}
				/>

				<Sorting
					options={sortingOptions.categories}
					action={handleCategoryChange}
					label={{ title: 'Showing:', key: 'showing' }}
					customDefault={{ title: 'All categories', key: 'allCategories' }}
					shouldReset={resetSorting}
				/>

				{activeCategory && (
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="w-[16px] h-[16px] flex justify-center items-center rounded p-0 bg-gray-10 -ml-[30px] transition hover:bg-gray-10/60"
							onClick={handleSortingReset}
						>
							<span className="flex scale-[0.5]">
								<Close color="#A2A2A2" />
							</span>
						</button>
					</SypacButton>
				)}

				<SypacButton variant="subTitle" className="ml-auto">
					<button
						type="button"
						className="flex gap-3 items-center p-0 bg-transparent group"
						onClick={() => navigate('/product-database/new')}
					>
						<ManageDatabaseIcon hoverEffect={true} />
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 mt-[2px] transition group-hover:text-gray-80/60">
								<T keyName="adminProducts.manageGroupsCategories">
									Manage groups & categories
								</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
			</section>

			<section className="w-full h-full">
				{loading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							visible={true}
						/>
					</div>
				) : (
					<div className="h-full relative mx-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
						<div
							className="w-full h-[calc(100%-52px)] overflow-y-auto scroll-smooth pr-[5px]"
							ref={(el) => (refs.current[0] = el)}
						>
							<ProductsTable
								rows={products}
								rowClick={handleProductClick}
								search={searchQuery}
								clickedOutside={!showDetails}
								database={true}
							/>
						</div>
						<footer className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
							<div className="flex justify-between items-center h-[51px] px-3">
								<Pagination
									showText={true}
									count={totalCount}
									page={page}
									onClick={setPage}
									customLimit={ITEMS_PER_PAGE}
								/>
							</div>
						</footer>
					</div>
				)}
			</section>

			{selectedProduct && showDetails && (
				<aside ref={(el) => (refs.current[1] = el)}>
					<ProductDetailsDatabase
						product={selectedProduct}
						deleteProduct={handleDeleteProduct}
						activeLanguage={activeLanguage}
					/>
				</aside>
			)}
		</main>
	);
};

export default ProductDatabase;
