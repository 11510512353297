import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { SypacText } from '@sypac/component-library-react';
import { useTranslate } from '@tolgee/react';
import { OrderInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import {
	OrderStatesEnum,
	OrderStatusesEnum,
} from '../../../services/orders.services';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { NotificationsService } from '../../../services/notifications.services';
import { BillingService } from '../../../services/billing.service';
import { OrderDetailsContext } from '../../../context/OrderDetailsContext/order-details.context';
import Emitter, { EventType } from '../../../services/events';
import Paid from './components/Steps/Paid';
import Notifications from './components/Notifications';
import DocumentsTab from '../../../components/DocumentsTab/DocumentsTab';
import AssignOrder from './components/Steps/AssignOrder';
import InProgress from './components/Steps/InProgress';
import ConfirmPickUp from './components/Steps/ConfirmPickUp';
import PendingPayment from './components/Steps/PendingPayment';
import { OrderDetailsProps } from './producerOrder.interface';
import { CompanyService } from '../../../services/company.services';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import { TabConfig } from '../../../components/OrderDetailsTabButton/OrderDetailsTabButton.interface';
import TabButton from '../../../components/OrderDetailsTabButton/OrderDetailsTabButton';

const TAB_CONFIG: Record<string, TabConfig> = {
	overview: {
		icon: 'Document Text',
		label: 'tabButton.general',
		translatedLabel: 'General',
	},
	documents: {
		icon: 'Folder',
		label: 'tabButton.documents',
		translatedLabel: 'Documents',
	},
	events: {
		icon: 'Bell',
		label: 'tabButton.events',
		translatedLabel: 'Events',
	},
} as const;

const OrderDetails: React.FC<OrderDetailsProps> = ({
	isOpen = false,
	selectedOrder,
}) => {
	const { t } = useTranslate();
	const { setProducerInvoice, setCompany } = useContext(OrderDetailsContext);
	const [order, setOrder] = useState<OrderInterface | undefined>(selectedOrder);
	const [currentTab, setCurrentTab] =
		useState<keyof typeof TAB_CONFIG>('overview');

	const notifications = useMemo(
		() => selectedOrder?.notifications?.items ?? [],
		[selectedOrder?.notifications?.items],
	);

	const getInvoice = useCallback(async () => {
		if (
			!selectedOrder?.id ||
			!selectedOrder?.target ||
			(selectedOrder.deliveryIncluded
				? selectedOrder.state !== OrderStatesEnum.COMPLETED
				: selectedOrder.status !== OrderStatusesEnum.PENDING_PAYMENT)
		) {
			return;
		}

		try {
			const { data } = await BillingService.getInvoiceById(
				selectedOrder.target,
				selectedOrder.id,
			);
			setProducerInvoice(data);
		} catch (e) {}
	}, [selectedOrder, setProducerInvoice]);

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setCompany(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const markReadNotifications = useCallback(async () => {
		const notificationIds = order?.notifications?.items
			?.filter((r) => !r.readAt)
			?.map((r) => r.id);
		try {
			if (notificationIds?.length) {
				await NotificationsService.readNotify(notificationIds);
				Emitter.emit(EventType.NOTIFICATION_COUNT_DECREASE, 1);
			}
			Emitter.emit(EventType.ORDER_NOTIFICATION_COUNT_DECREASE, order?.id!);
		} catch (e) {}
	}, [order]);

	const selectOrder = (order: OrderInterface) => {
		setOrder(order);
	};

	useEffect(() => {
		getInvoice().then(() => {});
	}, [getInvoice]);

	useEffect(() => {
		const refreshHandler = () => getInvoice();
		Emitter.on(EventType.PAYMENT_NOTE_REFRESH, refreshHandler);
		return () => {
			Emitter.off(EventType.PAYMENT_NOTE_REFRESH, refreshHandler);
		};
	}, [getInvoice]);

	useEffect(() => {
		setOrder(selectedOrder);
		setCurrentTab('overview');
	}, [selectedOrder]);

	useEffect(() => {
		if (order?.id) {
			markReadNotifications().then(() => {});
		}
	}, [markReadNotifications, order?.id]);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const renderSimple = (order: OrderInterface) => {
		if (order.status === OrderStatusesEnum.PAID) {
			return <Paid selectedOrder={order} />;
		}

		switch (order.state) {
			case OrderStatesEnum.TRANSPORTER_CONFIRMED:
				return <AssignOrder selectedOrder={order} setOrder={selectOrder} />;
			case OrderStatesEnum.TRUCK_CONFIRMED:
				return <InProgress selectedOrder={order} setOrder={selectOrder} />;
			case OrderStatesEnum.ARRIVED_TO_LOADING:
			case OrderStatesEnum.LOADING:
				return <ConfirmPickUp selectedOrder={order} setOrder={selectOrder} />;
			case OrderStatesEnum.LOADED:
			case OrderStatesEnum.ARRIVED_TO_UNLOADING:
			case OrderStatesEnum.UNLOADING:
			case OrderStatesEnum.UNLOADED:
			case OrderStatesEnum.COMPLETED:
				return <PendingPayment selectedOrder={order} />;
			default:
				return null;
		}
	};

	const renderExcluded = (order: OrderInterface) => {
		const statusComponentMap: { [key: string]: JSX.Element } = {
			[OrderStatesEnum.TO_DO]: (
				<AssignOrder selectedOrder={order} setOrder={selectOrder} />
			),
			[OrderStatesEnum.IN_PROGRESS]: (
				<InProgress selectedOrder={order} setOrder={selectOrder} />
			),
			[OrderStatusesEnum.CONFRIM_PICKUP]: (
				<ConfirmPickUp selectedOrder={order} setOrder={selectOrder} />
			),
			[OrderStatusesEnum.PENDING_PAYMENT]: (
				<PendingPayment selectedOrder={order} />
			),
			[OrderStatusesEnum.PAID]: <Paid selectedOrder={order} />,
		};

		return statusComponentMap[order.status] || null;
	};

	const renderOverviewTab = (order: OrderInterface | undefined) => {
		if (!order) return null;
		const { deliveryIncluded } = order;

		return deliveryIncluded ? renderSimple(order) : renderExcluded(order);
	};

	return isOpen ? (
		<aside className="w-[716px] h-full flex flex-col absolute top-0 right-0 bg-alabaster shadow-order-details z-[100] overflow-y-auto scroll-smooth rounded-2xl">
			<nav className="px-10 py-7 flex gap-[3px]">
				{Object.keys(TAB_CONFIG).map((tab) => (
					<TabButton
						key={tab}
						tab={tab}
						isActive={currentTab === tab}
						config={TAB_CONFIG[tab]}
						notificationCount={
							tab === 'events' ? notifications.length : undefined
						}
						onClick={setCurrentTab}
					/>
				))}
			</nav>

			<main className="flex flex-col h-full overflow-x-hidden overflow-y-scroll sypac-scrollbar">
				{currentTab === 'overview' && order && renderOverviewTab(order)}

				{currentTab === 'events' && (
					<section className="flex flex-col gap-8 px-10 py-7 border-0 border-solid border-t-[1px] border-gray-10">
						<SypacText variant="body-regular-medium">
							<p className="text-base leading-6 tracking-tighter text-gray-40">
								{t('awaitingTransport.timeline', 'Timeline')}
							</p>
						</SypacText>
						<Notifications selectedOrder={order} />
					</section>
				)}

				{currentTab === 'documents' && (
					<section className="flex flex-col gap-8 pt-8 border-0 border-solid border-t-[1px] border-gray-10">
						<DocumentsTab selectedOrder={order} target={OrderTarget.producer} />
					</section>
				)}
			</main>
		</aside>
	) : null;
};

export default OrderDetails;
