import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
	AdminNotificationsEnums,
	CommonNotificationsEnums,
	NotificationCategory,
	NotificationInterface,
} from '../../../../../components/OrdersTable/interfaces/Order.interface';
import NotificationAssignedTo from '../../../Notification/NotificationAssignedTo';
import NotificationSent from '../../../Notification/NotificationSent';
import TransportSolution from '../TransportSolution';
import NotificationOrderConfirmed from '../../../Notification/NotificationOrderConfirmed';
import DriverMultipleNotification from '../../../../../components/Notification/DriverMultipleNotification';
import DriverConfirmed from '../../../../../components/Notification/DriverConfirmed';
import AssignedTo from '../../../../../components/Notification/AssignedTo';
import {
	SypacText,
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import NotificationDriverLoaded from '../../../../Transporter/OrderDetails/components/Timeline/Notification/NotificationDriverLoaded';
import { T, useTranslate } from '@tolgee/react';
import { OrderStatus } from '../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import ModalMap from '../../../../../components/ModalMap/ModalMap';
import Default from '../../../../../components/Notification/Default';
import NotificationDriverReportedIssue from '../../../../Transporter/OrderDetails/components/Timeline/Notification/NotificationDriverReportedIssue';
import NotificationIssueSolved, {
	ContentDto,
} from '../../../../Transporter/OrderDetails/components/Timeline/Notification/NotificationIssueSolved';
import { DriverIssuesEnum } from '../../../../../services/truck.services';
import { NotificationsProps } from '../../adminOrder.interface';

const Timeline: React.FC<NotificationsProps> = ({ selectedOrder }) => {
	const { t } = useTranslate();
	const [showModal, setShowModal] = useState(false);
	const [solutions, setSolutions] = useState([]);
	const [count, setCount] = useState(0);
	const [openedMap, setOpenMap] = useState(false);
	const [notifications, setNotifications] = useState<NotificationInterface[]>(
		[],
	);

	useEffect(() => {
		const notificationList = selectedOrder?.notifications?.items?.length
			? JSON.parse(JSON.stringify(selectedOrder?.notifications?.items))
			: [];

		const updatedNotificationList = notificationList.map(
			(notification: { category: string; content: { comment: string } }) => {
				if (
					notification.category ===
					AdminNotificationsEnums.TRANSPORTER_RESOLVE_ISSUE
				) {
					const matchingIssue = selectedOrder?.trucks?.[0]?.issues?.find(
						(issue) =>
							issue.status === DriverIssuesEnum.RESOLVE &&
							notification.content?.comment === issue.comment,
					);

					if (matchingIssue) {
						return {
							...notification,
							content: {
								...notification.content,
								driverData: matchingIssue.driverData,
								oldDriverData: matchingIssue.oldDriverData,
							},
						};
					}
				}

				return notification;
			},
		);

		setNotifications(updatedNotificationList);
	}, [selectedOrder]);

	const handleOpenModal = (content: string, length: number) => {
		setCount(length);
		setSolutions(content ? JSON.parse(content) : []);
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const openMap = () => {
		setOpenMap(true);
	};

	return (
		<>
			{notifications.length ? (
				<div className="flex flex-col gap-7 px-10 py-7 border-0 border-solid border-t-[1px] border-gray-10">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-60">
							<T keyName="awaitingTransport.timeline">Timeline</T>
						</p>
					</SypacText>

					{notifications.length > 1 ? (
						<div className="flex flex-col">
							{notifications.map((item, index) => {
								const date = dayjs(item.createdAt).format(
									'DD.MM.YYYY — HH:mm',
								);
								return (
									<div key={item.id}>
										{item.category ===
										CommonNotificationsEnums.ORDER_COMPLETE ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={140}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="h-fit w-full">
													<Default
														notification={index === 0}
														title="Order completed"
														description="The order has been paid."
													/>
												</div>
											</div>
										) : item.category ===
										  CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={360}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="h-fit w-full">
													<DriverMultipleNotification
														notification={index === 0}
														title={t(
															'notifications.driverHasBeenUnloaded',
															'The driver has been unloaded',
														)}
														truckType={selectedOrder?.truck?.type}
														truckPlates={selectedOrder?.truck?.licensePlates}
														driverName={selectedOrder?.truck?.driverFullName}
														unloadingPlace={selectedOrder?.deliveryAddress}
														onClick={
															selectedOrder?.status !== OrderStatus.delivered &&
															index === 0
																? () => openMap()
																: undefined
														}
														files={selectedOrder?.unloadDetails?.documents?.map(
															(src) => ({
																src,
															}),
														)}
													/>
												</div>
											</div>
										) : item.category ===
										  CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS ? (
											<>
												<div className="flex flex-row gap-[25px]">
													<SypacVerticalTimeline>
														<SypacVerticalTimelineStep
															isActive={index === 0}
															minWidth={230}
														>
															{date}
														</SypacVerticalTimelineStep>
													</SypacVerticalTimeline>
													<div className="h-fit w-full">
														<DriverMultipleNotification
															notification={index === 0}
															title={t(
																'notifications.pendingUnloadingOfGoods',
																'Pending unloading of goods',
															)}
															truckType={selectedOrder?.truck?.type}
															truckPlates={selectedOrder?.truck?.licensePlates}
															driverName={selectedOrder?.truck?.driverFullName}
															unloadingPlace={selectedOrder?.deliveryAddress}
															onClick={
																selectedOrder?.status !==
																	OrderStatus.delivered && index === 0
																	? () => openMap()
																	: undefined
															}
														/>
													</div>
												</div>
												<div className="flex flex-row gap-[25px]">
													<SypacVerticalTimeline>
														<SypacVerticalTimelineStep
															isActive={index === 0}
															minWidth={230}
														>
															{date}
														</SypacVerticalTimelineStep>
													</SypacVerticalTimeline>
													<div className="h-fit w-full">
														<DriverMultipleNotification
															notification={index === 0}
															title={t(
																'notifications.driverArrivedAtTheUnloadingPlace',
																'The driver arrived at the unloading place',
															)}
															truckType={selectedOrder?.truck?.type}
															truckPlates={selectedOrder?.truck?.licensePlates}
															driverName={selectedOrder?.truck?.driverFullName}
															unloadingPlace={selectedOrder?.deliveryAddress}
															onClick={
																selectedOrder?.status !==
																	OrderStatus.delivered && index === 0
																	? () => openMap()
																	: undefined
															}
														/>
													</div>
												</div>
											</>
										) : item.category ===
										  CommonNotificationsEnums.ORDER_START_TRIP_TO_UNLOADING ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={260}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="h-fit w-full">
													<DriverMultipleNotification
														notification={index === 0}
														title={t(
															'notifications.pendingArrivalUnloadingPlace',
															'Pending arrival at the unloading place',
														)}
														eta={dayjs(selectedOrder?.deliveryTo)
															.hour(16)
															.minute(0)
															.format('DD.MM.YYYY — HH:mm')}
														truckType={selectedOrder?.truck?.type}
														truckPlates={selectedOrder?.truck?.licensePlates}
														driverName={selectedOrder?.truck?.driverFullName}
														unloadingPlace={selectedOrder?.deliveryAddress}
														onClick={
															selectedOrder?.status !== OrderStatus.delivered &&
															index === 0
																? () => openMap()
																: undefined
														}
													/>
												</div>
											</div>
										) : item.category ===
										  AdminNotificationsEnums.ORDER_PRODUCER_LOADING_GOODS ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={430}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="h-fit w-full">
													<DriverMultipleNotification
														arrivalDate={
															selectedOrder?.notifications?.items.find(
																(notification) =>
																	notification.category ===
																	CommonNotificationsEnums.ORDER_CONFIRM_LOADING_GOODS,
															)?.createdAt
														}
														avatar={{
															name:
																selectedOrder?.producer?.company?.name || '',
															src: '',
														}}
														files={selectedOrder?.producerLoadDetails?.documents?.map(
															(src) => ({
																src,
															}),
														)}
														loadingPlace={selectedOrder?.pickupAddress}
														notification={index === 0}
														product={selectedOrder?.product}
														quantity={`${selectedOrder?.quantity}`}
														title={t(
															'notifications.producerConfirmedLoadingOfGoods',
															'Producer confirmed loading of goods',
														)}
													/>
												</div>
											</div>
										) : item.category ===
										  CommonNotificationsEnums.ORDER_CONFIRM_LOADING_GOODS ? (
											<NotificationDriverLoaded
												notification={index === 0}
												date={date}
												loadingPlace={selectedOrder?.pickupAddress}
												product={selectedOrder?.product}
												quantity={selectedOrder?.product.quantity}
												loadingDate={item.createdAt}
												files={selectedOrder?.loadDetails?.documents?.map(
													(src) => ({
														src,
													}),
												)}
											/>
										) : item.category ===
										  CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={260}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="h-fit w-full">
													<DriverMultipleNotification
														notification={index === 0}
														title={t(
															'notifications.driverArrivedAtLoadingPlace',
															'The driver arrived at the loading place',
														)}
														description={t(
															'notifications.confirmLoadingOfGoods',
															'Confirm loading of goods.',
														)}
														eta={date}
														truckType={selectedOrder?.truck?.type}
														truckPlates={selectedOrder?.truck?.licensePlates}
														driverName={selectedOrder?.truck?.driverFullName}
														loadingPlace={selectedOrder?.pickupAddress}
														onClick={
															selectedOrder?.status !== OrderStatus.delivered &&
															index === 0
																? () => openMap()
																: undefined
														}
													/>
												</div>
											</div>
										) : item.category ===
										  CommonNotificationsEnums.ORDER_START_TRIP_TO_LOADING ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={260}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="h-fit w-full">
													<DriverMultipleNotification
														notification={index === 0}
														title={t(
															'notifications.pendingArrivalAtTheLoadingPlace',
															'Pending arrival at the loading place',
														)}
														description={t(
															'notifications.confirmDriverArrival',
															'Confirm driver arrival.',
														)}
														eta={dayjs(selectedOrder?.deliveryFrom)
															.hour(16)
															.minute(0)
															.format('DD.MM.YYYY — HH:mm')}
														truckType={selectedOrder?.truck?.type}
														truckPlates={selectedOrder?.truck?.licensePlates}
														driverName={selectedOrder?.truck?.driverFullName}
														loadingPlace={selectedOrder?.pickupAddress}
														onClick={
															selectedOrder?.status !== OrderStatus.delivered &&
															index === 0
																? () => openMap()
																: undefined
														}
													/>
												</div>
											</div>
										) : item.category ===
										  AdminNotificationsEnums.ORDER_ACCEPTED_BY_PRODUCER ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={140}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="h-fit w-full">
													<AssignedTo
														avatar={
															selectedOrder?.assigneeUser?.avatarUrl || ''
														}
														notification={index === 0}
														title={t(
															'notifications.orderConfirmedByProducer',
															'Order confirmed by producer',
														)}
														username={selectedOrder?.producer?.company?.name!}
													/>
												</div>
											</div>
										) : item.category ===
										  AdminNotificationsEnums.ORDER_SENT_TO_PRODUCER ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={140}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="h-fit w-full">
													<AssignedTo
														avatar={
															selectedOrder?.assigneeUser?.avatarUrl || ''
														}
														notification={index === 0}
														title={t(
															'notifications.orderSentToProducer',
															'Order sent to producer',
														)}
														username={selectedOrder?.producer?.company?.name!}
													/>
												</div>
											</div>
										) : item.category ===
										  AdminNotificationsEnums.ORDER_ACCEPTED_BY_DRIVER ? (
											<div className="flex flex-row gap-[25px]">
												<SypacVerticalTimeline>
													<SypacVerticalTimelineStep
														isActive={index === 0}
														minWidth={590}
													>
														{date}
													</SypacVerticalTimelineStep>
												</SypacVerticalTimeline>
												<div className="w-full h-fit">
													<DriverConfirmed
														description={t(
															'notifications.pendingSentToSupplier',
															'Pending sent to supplier.',
														)}
														driver={selectedOrder?.truck?.driverFullName!}
														loadingPlace={selectedOrder?.pickupAddress!}
														notification={index === 0}
														scheduledLoading={dayjs(selectedOrder?.deliveryFrom)
															.hour(16)
															.minute(0)
															.format('DD.MM.YYYY — HH:mm')}
														scheduledUnloading={dayjs(selectedOrder?.deliveryTo)
															.hour(16)
															.minute(0)
															.format('DD.MM.YYYY — HH:mm')}
														title={t(
															'notifications.driverHasBeenConfirmed',
															'The driver has been confirmed',
														)}
														truckPlates={selectedOrder?.truck?.licensePlates!}
														truckType={`${selectedOrder?.truck?.type} (payload: ${selectedOrder?.quantity} tons)`}
														unloadingPlace={selectedOrder?.deliveryAddress!}
														onClick={
															selectedOrder?.status !== OrderStatus.delivered &&
															index === 0
																? () => openMap()
																: undefined
														}
													/>
												</div>
											</div>
										) : item.category ===
										  AdminNotificationsEnums.ORDER_SEND_TO_DRIVER ? (
											<NotificationOrderConfirmed
												notification={index === 0}
												date={date}
												button={{
													text: t(
														'notifications.viewAllSolution',
														'View all solution',
													),
												}}
											/>
										) : item.category ===
										  AdminNotificationsEnums.ORDER_ACCEPTED_BY_TRANSPORTER ? (
											<NotificationSent
												notification={index === 0}
												date={date}
												count={item?.content?.count}
												onClick={
													selectedOrder?.status !== OrderStatus.delivered &&
													index === 0
														? () =>
																handleOpenModal(
																	item?.content?.solutions,
																	item?.content?.count,
																)
														: undefined
												}
											/>
										) : item.category ===
										  AdminNotificationsEnums.ORDER_CONFIRMED ? (
											<NotificationAssignedTo
												notification={index === 0}
												date={date}
												avatar={selectedOrder?.assigneeUser?.avatarUrl || ''}
												username={
													(selectedOrder?.assigneeUser?.profile?.firstName
														? `${selectedOrder?.assigneeUser?.profile?.firstName} ${selectedOrder?.assigneeUser?.profile?.lastName}`
														: selectedOrder?.assigneeUser?.name)!
												}
											/>
										) : item.category === NotificationCategory.NEW_ISSUE ? (
											<NotificationDriverReportedIssue
												notification={index === 0}
												date={date}
												content={item.content as ContentDto}
											/>
										) : item.category ===
										  NotificationCategory.DRIVER_RESOLVE_ISSUE ? (
											<NotificationIssueSolved
												notification={index === 0}
												date={date}
												solvedByDriver={true}
												content={item.content as ContentDto}
											/>
										) : item.category ===
										  AdminNotificationsEnums.TRANSPORTER_IGNORE_ISSUE ? (
											<NotificationIssueSolved
												notification={index === 0}
												date={date}
												solvedByDriver={false}
												content={item.content as ContentDto}
											/>
										) : item.category ===
										  AdminNotificationsEnums.TRANSPORTER_RESOLVE_ISSUE ? (
											<NotificationIssueSolved
												notification={index === 0}
												date={date}
												solvedByDriver={false}
												changeTruck={true}
												content={item.content as ContentDto}
											/>
										) : null}
									</div>
								);
							})}
							{/* !!(showModal && solutions?.length) ? (
								<TransportSolution
									onClose={handleCloseModal}
									isOpen={!!(showModal && solutions?.length)}
									solutions={solutions}
									count={count}
								/>
							) : null */}

							{openedMap &&
							selectedOrder?.truck?.lastLocation?.coordinates?.at(1) ? (
								<ModalMap
									isOpen={openedMap}
									onClose={() => setOpenMap(false)}
									markers={[
										{
											latitude:
												selectedOrder?.truck?.lastLocation?.coordinates?.at(1)!,
											longitude:
												selectedOrder?.truck?.lastLocation?.coordinates?.at(0)!,
											variant: 'truck',
										},
									]}
								/>
							) : null}
						</div>
					) : (
						<div className="flex justify-center items-center">
							<SypacText variant="body-regular-medium">
								<p className="text-xl text-gray-80">No events at the moment.</p>
							</SypacText>
						</div>
					)}
				</div>
			) : null}
		</>
	);
};

export default Timeline;
