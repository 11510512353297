import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import {
	NotificationCategory,
	OrderInterface,
} from '../../../components/OrdersTable/interfaces/Order.interface';
import Requested from './components/Steps/Requested';
import { NotificationsService } from '../../../services/notifications.services';
import Emitter, { EventType } from '../../../services/events';
import Timeline from './components/Timeline';
import Accepted from './components/Steps/Accepted';
import DocumentsTab from '../../../components/DocumentsTab/DocumentsTab';
import {
	OrderStatesEnum,
	OrderStatusesEnum,
} from '../../../services/orders.services';
import CommonStep from './components/Steps/CommonStep';
import PendingAndPaid from './components/Steps/PendingAndPaid';
import { T } from '@tolgee/react';
import { AuthContext } from '../../../context/context';
import { OrderDetailsProps } from './transporterOrder.interface';
import { DriverIssuesEnum } from '../../../services/truck.services';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { OrderDetailsContext } from '../../../context/OrderDetailsContext/order-details.context';
import { BillingService } from '../../../services/billing.service';

const OrderDetails: React.FC<OrderDetailsProps> = ({
	isOpen = false,
	selectedOrder,
}) => {
	const { user } = useContext(AuthContext);
	const { setCarrierInvoice } = useContext(OrderDetailsContext);
	const [order, setOrder] = useState<OrderInterface | undefined>(selectedOrder);
	const [currentTab, setCurrentTab] = useState<string>('overview');

	const getInvoice = useCallback(async () => {
		if (
			!selectedOrder?.id ||
			!selectedOrder?.target ||
			(selectedOrder.deliveryIncluded
				? selectedOrder.state !== OrderStatesEnum.COMPLETED
				: selectedOrder.status !== OrderStatusesEnum.PENDING_PAYMENT)
		) {
			return;
		}

		try {
			const { data } = await BillingService.getInvoiceById(
				selectedOrder.target,
				selectedOrder.id,
			);
			setCarrierInvoice(data);
		} catch (e) {}
	}, [selectedOrder, setCarrierInvoice]);

	const notifications = useMemo(() => {
		const notificationList = selectedOrder?.notifications?.items?.length
			? JSON.parse(JSON.stringify(selectedOrder?.notifications?.items))
			: [];

		const relevantIssues = selectedOrder?.trucks?.[0]?.issues?.filter(
			(issue) =>
				issue.status === DriverIssuesEnum.IGNORE ||
				issue.status === DriverIssuesEnum.RESOLVE,
		);

		if (relevantIssues && relevantIssues.length > 0) {
			relevantIssues.forEach((issue) => {
				const matchingNotification = notificationList.find(
					(notification: { content: { comment: string }; category: string }) =>
						notification.content?.comment === issue.comment,
				);

				if (
					matchingNotification &&
					matchingNotification.category !==
						NotificationCategory.DRIVER_RESOLVE_ISSUE
				) {
					const formattedIssue = {
						content: issue,
						category:
							issue.status === DriverIssuesEnum.IGNORE
								? NotificationCategory.DISPATCHER_RESOLVE_ISSUE
								: NotificationCategory.DRIVER_CHANGE_TRUCK,
						createdAt: issue.updatedAt,
					};

					notificationList.unshift(formattedIssue);
				}
			});
		}

		return notificationList.sort(
			(a: { createdAt: Date }, b: { createdAt: Date }) =>
				new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
		);
	}, [selectedOrder]);

	const selectOrder = (order: OrderInterface) => {
		setOrder(order);
	};

	const handleClick = (tab: string) => {
		setCurrentTab(tab);
	};

	const markReadNotifications = useCallback(async () => {
		const notificationIds =
			selectedOrder?.notifications?.items
				?.filter((r) => !r.readAt)
				?.map((r) => r.id) || [];

		if (
			!selectedOrder?.notifications &&
			selectedOrder?.status === OrderStatusesEnum.REQUESTED
		) {
			try {
				const { data } = await NotificationsService.getCount({
					recipientId: user?.uid,
					limit: 10,
				});

				const relevantNotification = data.items.find(
					(item) => item.groupId === `${selectedOrder?.offerId}`,
				);

				if (relevantNotification) {
					notificationIds.push(relevantNotification.id);
				}
			} catch (e) {}
		}

		try {
			if (notificationIds.length) {
				await NotificationsService.readNotify(notificationIds);
				Emitter.emit(EventType.NOTIFICATION_COUNT_DECREASE, 1);
			}
			Emitter.emit(
				EventType.ORDER_NOTIFICATION_COUNT_DECREASE,
				selectedOrder?.id!,
			);
		} catch (e) {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOrder]);

	useEffect(() => {
		getInvoice().then(() => {});
	}, [getInvoice]);

	useEffect(() => {
		if (selectedOrder?.id) {
			markReadNotifications().then(() => {});
		}
	}, [markReadNotifications, selectedOrder?.id]);

	useEffect(() => {
		setOrder(selectedOrder);
		setCurrentTab('overview');
	}, [selectedOrder]);

	const renderSteps = (order: OrderInterface) => {
		const { state, status } = order;
		if (
			(state === OrderStatesEnum.COMPLETED ||
				state === OrderStatesEnum.UNLOADED) &&
			status === OrderStatusesEnum.DELIVERING
		) {
			return <CommonStep selectedOrder={order} currentStep={10} />;
		}

		const steps = {
			[OrderStatusesEnum.REQUESTED]: <Requested selectedOrder={order} />,
			[OrderStatusesEnum.ACCEPTED]: (
				<Accepted selectedOrder={order} setOrder={selectOrder} />
			),
			[OrderStatusesEnum.SENT_TO_DRIVER]: (
				<CommonStep selectedOrder={order} currentStep={4} />
			),
			[OrderStatesEnum.TRUCK_CONFIRMED]: (
				<CommonStep selectedOrder={order} currentStep={5} />
			),
			[OrderStatesEnum.TO_LOADING]: (
				<CommonStep selectedOrder={order} currentStep={6} />
			),
			[OrderStatesEnum.ARRIVED_TO_LOADING]: (
				<CommonStep selectedOrder={order} currentStep={7} />
			),
			[OrderStatesEnum.LOADING]: (
				<CommonStep selectedOrder={order} currentStep={7} />
			),
			[OrderStatesEnum.LOADED]: (
				<CommonStep selectedOrder={order} currentStep={8} />
			),
			[OrderStatesEnum.TO_UNLOADING]: (
				<CommonStep selectedOrder={order} currentStep={8} />
			),
			[OrderStatesEnum.ARRIVED_TO_UNLOADING]: (
				<CommonStep selectedOrder={order} currentStep={9} />
			),
			[OrderStatesEnum.UNLOADING]: (
				<CommonStep selectedOrder={order} currentStep={10} />
			),
			[OrderStatesEnum.UNLOADED]: (
				<PendingAndPaid
					selectedOrder={order}
					setOrder={selectOrder}
					currentStep={11}
				/>
			),
			[OrderStatesEnum.COMPLETED]: (
				<PendingAndPaid
					selectedOrder={order}
					setOrder={selectOrder}
					currentStep={11}
				/>
			),
			[OrderStatusesEnum.PAID]: (
				<PendingAndPaid
					selectedOrder={order}
					setOrder={selectOrder}
					currentStep={12}
				/>
			),
		};

		return steps[status] || steps[state] || null;
	};

	return (
		<>
			{isOpen ? (
				<div className="w-[716px] h-full flex flex-col absolute top-0 right-0 bg-alabaster shadow-order-details z-[100] overflow-y-auto scroll-smooth rounded-2xl">
					{![OrderStatusesEnum.ACCEPTED, OrderStatusesEnum.REQUESTED].includes(
						order?.status!,
					) ? (
						<div className="px-10 py-7 flex gap-[3px]">
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
										currentTab === 'overview'
											? 'bg-primary-violet text-white'
											: 'hover:bg-gray-10 bg-alabaster text-gray-80'
									}`}
									onClick={() => handleClick('overview')}
								>
									<SypacIcon
										iconName="Document Text"
										size="custom"
										width="24px"
										height="24px"
										className="mb-[2px]"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="tabButton.general">General</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
										currentTab === 'documents'
											? 'bg-primary-violet text-white'
											: 'hover:bg-gray-10 bg-alabaster text-gray-80'
									}`}
									onClick={() => setCurrentTab('documents')}
								>
									<SypacIcon
										iconName="Folder"
										size="custom"
										width="24px"
										height="24px"
										className="mb-[2px]"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="tabButton.documents">Documents</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
										currentTab === 'events'
											? 'bg-primary-violet text-white'
											: 'hover:bg-gray-10 bg-alabaster text-gray-80'
									}`}
									onClick={() => setCurrentTab('events')}
								>
									<SypacIcon
										iconName="Bell"
										size="custom"
										width="24px"
										height="24px"
										className="mb-[2px]"
									/>
									<SypacText
										variant="body-regular-medium"
										className="flex gap-4"
									>
										<p>
											<T keyName="tabButton.events">Events</T>
										</p>
										{notifications.length ? (
											<div
												className={`w-fit px-2 flex justify-center rounded-full text-gray-80 transition ${
													currentTab === 'events'
														? 'bg-white'
														: 'bg-gray-10 group-hover:bg-white'
												}`}
											>
												{notifications.length}
											</div>
										) : null}
									</SypacText>
								</button>
							</SypacButton>
						</div>
					) : null}

					<div className="flex flex-col h-full overflow-x-hidden overflow-y-scroll sypac-scrollbar">
						{currentTab === 'overview' &&
							order &&
							order.deliveryIncluded &&
							renderSteps(order)}

						{currentTab === 'events' ? (
							<Timeline
								selectedOrder={selectedOrder}
								notifications={notifications}
							/>
						) : null}

						{currentTab === 'documents' ? (
							<div className="flex flex-col gap-8 pt-8 border-0 border-solid border-t-[1px] border-gray-10">
								<DocumentsTab
									selectedOrder={selectedOrder}
									target={OrderTarget.transporter}
								/>
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</>
	);
};

export default OrderDetails;
