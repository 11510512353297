import React, { useMemo } from 'react';
import {
	SypacText,
	SypacBox,
	SypacAvatar,
} from '@sypac/component-library-react';
import {
	ClientsProductCardProps,
	PriceDisplayProps,
	ProductDetailsProps,
} from '../clientSelectProduct.interface';
import { NumericFormat } from 'react-number-format';
import { T } from '@tolgee/react';

const ClientsProductCard: React.FC<ClientsProductCardProps> = ({
	data: {
		name,
		availableQuantity = 0,
		type,
		pricePerUnitWithProcent = 0,
		pricePerUnitWithVat = 0,
		photoUrl,
		size,
		currency,
		company,
	},
	onClick,
}) => {
	const status = useMemo(() => {
		if (availableQuantity > 50) return 'plenty';
		if (availableQuantity > 10) return 'medium';
		if (availableQuantity > 0) return 'low';
		return 'none';
	}, [availableQuantity]);

	const PriceDisplay = ({
		label,
		value,
		currency,
		textSize = 'sm',
		textColor = 'text-gray-80',
	}: PriceDisplayProps) => (
		<div className={`flex gap-1 [&_p]:text-${textSize} ${textColor}`}>
			<SypacText variant="body-regular-medium">
				<p>{label}</p>
			</SypacText>
			<SypacText variant="body-regular-medium">
				<p>
					<NumericFormat
						type="text"
						displayType="text"
						thousandSeparator="."
						decimalSeparator=","
						value={parseFloat(value?.toFixed(2) || '0')}
						suffix={currency === 'lei' ? ' MDL' : ' PLN'}
					/>
				</p>
			</SypacText>
		</div>
	);

	const ProductDetails = ({ name, type, size }: ProductDetailsProps) => (
		<div
			className={`flex flex-col gap-[10px] px-2.5 ${
				!type && !size ? 'mb-5' : ''
			}`}
		>
			<SypacText variant="body-regular-medium">
				<p className="text-base leading-[22px] text-gray-80 truncate">{name}</p>
			</SypacText>
			{(type || size) && (
				<SypacText variant="body-regular-medium">
					<p className="text-sm text-gray-40">
						{`${type || ''}${size ? ` (${size})` : ''}`}
					</p>
				</SypacText>
			)}
		</div>
	);

	const CompanyInfo = ({ company }: { company?: { name: string } }) => {
		if (!company) return null;

		return (
			<div className="flex items-center gap-2 px-1 py-[3.5px] border-0 border-solid border-t-[1px] border-gray-10">
				<SypacAvatar
					className="scale-[0.6]"
					size="sm"
					initials={company.name}
				/>
				<SypacText variant="body-regular-medium">
					<p className="text-sm text-cornflower-blue mt-1">{company.name}</p>
				</SypacText>
			</div>
		);
	};

	return (
		<article className="relative">
			<SypacBox
				hover={true}
				className={`box-border w-[268px] h-fit flex gap-[10px] rounded-xl transition bg-white ${
					status === 'none' ? 'opacity-60' : ''
				}`}
				onClick={() => {
					onClick && onClick();
				}}
			>
				<div
					style={{ backgroundImage: `url(${photoUrl})` }}
					className="w-[calc(100%-18px)] h-[174px] m-[10px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 box-border"
					role="img"
					aria-label={name}
				/>

				<ProductDetails name={name!} type={type} size={size} />
				<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

				<section className="flex flex-col gap-[10px] px-2.5">
					<PriceDisplay
						label={
							<T keyName="clientsProductCard.pricePerTonExcl">
								Price per ton (excl. VAT):
							</T>
						}
						value={pricePerUnitWithProcent}
						currency={currency!}
					/>

					<PriceDisplay
						label={
							<T keyName="clientsProductCard.pricePerTonIncl">
								Price per ton (incl. VAT):
							</T>
						}
						value={pricePerUnitWithVat}
						currency={currency!}
						textSize="xs"
						textColor="text-gray-40"
					/>
				</section>

				<CompanyInfo company={company} />
			</SypacBox>
		</article>
	);
};

export default ClientsProductCard;
