import { IconInterface } from '../interfaces/icon.interface';

const EditPenIcon = (props: IconInterface) => {
	const {
		color = '#A2A2A2',
		width = 22,
		height = 22,
		hoverEffect = false,
	} = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={hoverEffect ? 'transition group-hover:stroke-gray-80' : ''}
		>
			<path
				d="M21 9.5V11C21 15.714 21 18.0711 19.5355 19.5355C18.0711 21 15.714 21 11 21C6.28595 21 3.92893 21 2.46447 19.5355C1 18.0711 1 15.714 1 11C1 6.28595 1 3.92893 2.46447 2.46447C3.92893 1 6.28595 1 11 1H12.5"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				className={hoverEffect ? 'transition group-hover:stroke-gray-80' : ''}
			/>
			<path
				d="M15.652 2.45506L16.3009 1.80624C17.3759 0.731252 19.1188 0.731252 20.1938 1.80624C21.2687 2.88124 21.2687 4.62415 20.1938 5.69914L19.5449 6.34795M15.652 2.45506C15.652 2.45506 15.7331 3.83379 16.9497 5.05032C18.1662 6.26685 19.5449 6.34795 19.5449 6.34795M15.652 2.45506L9.68718 8.41993C9.28316 8.82394 9.08116 9.02595 8.90743 9.24868C8.70249 9.51143 8.52679 9.79572 8.38344 10.0965C8.26191 10.3515 8.17157 10.6225 7.99089 11.1646L7.41242 12.9M19.5449 6.34795L13.5801 12.3128C13.1761 12.7168 12.9741 12.9188 12.7513 13.0926C12.4886 13.2975 12.2043 13.4732 11.9035 13.6166C11.6485 13.7381 11.3775 13.8284 10.8354 14.0091L9.1 14.5876M9.1 14.5876L7.97709 14.9619C7.71035 15.0508 7.41626 14.9814 7.21744 14.7826C7.01862 14.5837 6.9492 14.2897 7.03811 14.0229L7.41242 12.9M9.1 14.5876L7.41242 12.9"
				stroke={color}
				strokeWidth="1.5"
				className={hoverEffect ? 'transition group-hover:stroke-gray-80' : ''}
			/>
		</svg>
	);
};

export default EditPenIcon;
