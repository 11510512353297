import { IconInterface } from '../interfaces/icon.interface';

const ArrowToRight = (props: IconInterface) => {
	const { color = '#000000', width = 74, height = 8 } = props;

	const arrowHeadSize = 5;
	// @ts-ignore
	const lineWidth = width - arrowHeadSize;
	const lineEndpoint = lineWidth + 4.5;

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<line
				x1="0"
				y1="4"
				x2={lineEndpoint}
				y2="4"
				stroke={color}
				strokeWidth="1"
			/>

			<path
				d={`M${lineWidth} 0L${width} 4L${lineWidth} 8`}
				stroke={color}
				strokeWidth="1"
				fill="none"
			/>
		</svg>
	);
};

export default ArrowToRight;
