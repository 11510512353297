import React, { useEffect, useState } from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import {
	SypacBadge,
	SypacButtonIcon,
	SypacButtonOutside,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import classNames from 'classnames';
import './invoices_table.css';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import { Search } from '../../assets/Search';
import { InvoicesTableInterface } from './interfaces/InvoicesTable.interface';
import { InvoiceInterface } from '../PaymentsTable/interfaces/Payment.interface';
import { PaymentTypeAction } from '../PaymentsTable/interfaces/PaymentStatus.interface';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import { Expanded } from '../../assets/Expanded';
import { NotExpanded } from '../../assets/NotExpanded';
import { NumericFormat } from 'react-number-format';
import PaymentStatusPayment from '../TableStatus/PaymentStatusPayment';
import { TableStatusEnum } from '../TableStatus/TableStatus.enum';
import DocumentsViewer from '../DocumentsViewer/DocumentsViewer';
import { T, useTranslate } from '@tolgee/react';
import PaidInvoice from '../../assets/PaidInvoice';
import PendingInvoice from '../../assets/PendingInvoice';
import OverdueInvoice from '../../assets/OverdueInvoice';
import { PaymentStatusColor } from '../TableStatus/TableStatus.constants';
import { formatDate } from '../../utils/time.util';

export const InvoicesTable = ({
	rows,
	target = 'transporter',
}: InvoicesTableInterface) => {
	dayjs.locale(localStorage.getItem('lang') || 'en');
	const { t } = useTranslate();
	const [keyRows, setRows] = useState<InvoiceInterface[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const [openView, setShowView] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();

	useEffect(() => {
		const newRows = rows
			? rows?.map((row: InvoiceInterface) => ({
					...row,
					key: row?.id,
			  }))
			: [];
		setRows(newRows);
	}, [rows]);

	const downloadInvoice = async (invoice: InvoiceInterface) => {
		try {
			if (!invoice.invoiceUrl) {
				return;
			}
			const response = await fetch(invoice.invoiceUrl);
			const data = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.setAttribute(
				'download',
				`FV-${formatDate(invoice.createdAt)}.pdf`,
			);

			document.body.appendChild(link);

			link.click();

			link?.parentNode?.removeChild(link);
		} catch (e) {}
	};

	const viewInvoice = (url?: string) => {
		setUrl(url);
		setShowView(!!url);
	};

	const actionClick = (type: string, payment: InvoiceInterface | number) => {
		switch (type) {
			case PaymentTypeAction.download:
				return downloadInvoice(payment as InvoiceInterface);
			case PaymentTypeAction.view_invoice:
				return viewInvoice((payment as InvoiceInterface)?.invoiceUrl);
		}
	};

	const columnsProducer = (
		actionClick: (type: string, payment: InvoiceInterface) => void,
	) => [
		{
			title: t('invoicesTable.paymentDate', 'Payment date'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceInterface) {
				const date = formatDate(value.createdAt);

				return (
					<div className="flex items-center justify-start h-full w-fit px-6 py-5 box-border">
						{value.status === TableStatusEnum.PAID ? (
							<PaidInvoice />
						) : (
							<PendingInvoice />
						)}
						<SypacText variant="body-regular-medium" className="ml-6">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoicesTable.amount', 'Amount'),
			dataIndex: '',
			className: 'text-left',
			key: 'totalAmount',
			render(value: InvoiceInterface) {
				return (
					<div className="flex items-center h-full px-6">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.totalAmount?.toFixed(2) || '0')}
									suffix={value.countryCode === 'MD' ? ' MDL' : ' PLN'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoicesTable.orders', 'Orders'),
			dataIndex: '',
			className: 'text-left',
			key: 'description',
			render(value: InvoiceInterface) {
				const orcersIDs = value.invoiceLines
					.map((r) => r.orderId)
					.filter((r) => r);

				return (
					<div className="flex items-center h-full px-6">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">{orcersIDs.length}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			className: 'text-center',
			key: 'actions',
			render(value: InvoiceInterface) {
				return (
					<div className="flex flex-row items-center gap-x-10 px-6 w-fit">
						<SypacButtonOutside>
							<button
								onClick={() => actionClick(PaymentTypeAction.download, value)}
							>
								<SypacButtonIcon>
									<SypacIcon size="md" iconName="Download Minimalistic" />
								</SypacButtonIcon>
							</button>
						</SypacButtonOutside>

						<SypacButtonOutside>
							<button
								onClick={() =>
									actionClick(PaymentTypeAction.view_invoice, value)
								}
							>
								<SypacButtonIcon>
									<SypacIcon size="md" iconName="Eye" />
								</SypacButtonIcon>
							</button>
						</SypacButtonOutside>
					</div>
				);
			},
		},
	];

	const columnsClient = (
		actionClick: (type: string, payment: InvoiceInterface) => void,
	) => [
		{
			title: t('invoicesTable.status', 'Status'),
			dataIndex: '',
			className: 'text-right',
			key: 'status',
			render(value: InvoiceInterface) {
				const isOverdue =
					value?.status === TableStatusEnum.PENDING &&
					dayjs(value?.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value?.dueDate, 'day')
					: dayjs(value?.dueDate).diff(dayjs(), 'day');
				const status = isOverdue ? TableStatusEnum.OVERDUE : value?.status;

				return (
					<div className="flex items-center justify-start h-full w-fit px-6 py-5 box-border">
						{status === TableStatusEnum.PAID ? (
							<PaidInvoice />
						) : status === TableStatusEnum.OVERDUE ? (
							<OverdueInvoice />
						) : (
							<PendingInvoice />
						)}
						{status !== TableStatusEnum.PAID ? (
							<SypacBadge
								color={PaymentStatusColor[status]}
								size="small"
								className="ml-3"
							>
								<SypacText variant="overline-regular-large">
									<p className="text-gray-80">{days}d.</p>
								</SypacText>
							</SypacBadge>
						) : null}
					</div>
				);
			},
		},
		{
			title: t('invoicesTable.transaction', 'Transaction'),
			dataIndex: '',
			className: 'text-left',
			key: 'transaction',
			render(value: InvoiceInterface) {
				return (
					<div className="flex items-center h-full px-6">
						<SypacText variant="body-regular-small">
							<p className="text-gray-80">{value?.invoiceType}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoicesTable.amount', 'Amount'),
			dataIndex: '',
			className: 'text-left',
			key: 'totalAmount',
			render(value: InvoiceInterface) {
				return (
					<div className="flex items-center h-full px-6">
						<SypacText variant="body-regular-small">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.totalAmount?.toFixed(2) || '0')}
									suffix={value.countryCode === 'MD' ? ' MDL' : ' PLN'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoicesTable.createdDate', 'Created date'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceInterface) {
				const date = formatDate(value.createdAt);
				return (
					<div className="flex items-center h-full px-6">
						<SypacText variant="body-regular-small">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoicesTable.paymentMethod', 'Payment method'),
			dataIndex: '',
			className: 'text-left',
			key: 'paymentMethod',
			render(value: InvoiceInterface) {
				return (
					<div className="flex items-center h-full px-6">
						<SypacText variant="body-regular-small">
							<p className="text-gray-80">{value?.paymentMethod}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			className: 'text-center',
			key: 'actions',
			render(value: InvoiceInterface) {
				return (
					<div className="flex flex-row items-center gap-x-5 px-6 w-fit">
						<SypacButtonOutside>
							<button
								onClick={() => actionClick(PaymentTypeAction.download, value)}
							>
								<SypacButtonIcon>
									<SypacIcon size="md" iconName="Download Minimalistic" />
								</SypacButtonIcon>
							</button>
						</SypacButtonOutside>

						<SypacButtonOutside>
							<button
								onClick={() =>
									actionClick(PaymentTypeAction.view_invoice, value)
								}
							>
								<SypacButtonIcon>
									<SypacIcon size="md" iconName="Eye" />
								</SypacButtonIcon>
							</button>
						</SypacButtonOutside>
					</div>
				);
			},
		},
	];

	const getColumns = () => {
		switch (target) {
			case 'customer':
				return columnsClient(actionClick);
			default:
				return columnsProducer(actionClick);
		}
	};

	const onExpand = (expanded: boolean, record: InvoiceInterface) => {
		if (expanded) {
			setExpandedRowKeys([record.id]);
		} else {
			setExpandedRowKeys([]);
		}
	};

	const expandedRowRender = (record: InvoiceInterface) => {
		return (
			<div className="table-payment h-full">
				<table>
					<tr>
						<th>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 text-left px-2">
									<T keyName="invoicesTable.createdDate">Created date</T>
								</p>
							</SypacText>
						</th>
						<th>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 text-left px-2">
									<T keyName="invoicesTable.amountPaid">Amount paid</T>
								</p>
							</SypacText>
						</th>
						<th>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 text-left px-2">
									<T keyName="invoicesTable.amountOpen">Amount open</T>
								</p>
							</SypacText>
						</th>
						<th>
							<div className="grid grid-cols-4">
								<div className={`flex flex-row justify-start`}>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80 text-left px-2">
											<T keyName="invoicesTable.paymentStatus">
												Payment status
											</T>
										</p>
									</SypacText>
								</div>
							</div>
						</th>
					</tr>
					{record?.payments?.map((r, index) => {
						const isOverdue =
							dayjs(record.dueDate).toISOString() <
							dayjs(r.paymentDate).toISOString();

						const days = dayjs(record.dueDate).diff(r.paymentDate, 'day');
						const secondPartial =
							record.status === TableStatusEnum.PENDING ||
							record?.payments?.length > 1;
						let secondStatus = TableStatusEnum.PENDING;
						if (index === 0 && record.status === TableStatusEnum.PAID) {
							secondStatus = TableStatusEnum.PAID;
						} else {
							secondStatus = isOverdue
								? TableStatusEnum.OVERDUE
								: TableStatusEnum.PENDING;
						}

						const paymentAmount =
							secondStatus === TableStatusEnum.PAID &&
							r.paymentAmount !== record.totalAmount
								? record.totalAmount
								: r.paymentAmount;
						return (
							<tr>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80">{formatDate(r.createdAt)}</p>
									</SypacText>
								</td>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseFloat(paymentAmount?.toFixed(2) || '0')}
												suffix={record.countryCode === 'MD' ? ' MDL' : ' PLN'}
											/>
										</p>
									</SypacText>
								</td>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseFloat(paymentAmount?.toFixed(2) || '0')}
												suffix={record.countryCode === 'MD' ? ' MDL' : ' PLN'}
											/>
										</p>
									</SypacText>
								</td>
								<td>
									<div className="grid grid-cols-4 px-5 py-3">
										<div className={`flex flex-row justify-start`}>
											<PaymentStatusPayment
												days={days}
												state={secondStatus}
												isPartial={secondPartial}
											/>
										</div>
									</div>
								</td>
							</tr>
						);
					})}
				</table>
			</div>
		);
	};

	const expandIcon = (props: RenderExpandIconProps<InvoiceInterface>) => {
		return props.record.payments?.length ? (
			props.expanded ? (
				<button
					onClick={() => onExpand(!props.expanded, props.record)}
					className="bg-transparent border-0 outline-0 cursor-pointer"
				>
					<Expanded />
				</button>
			) : (
				<button
					onClick={() => onExpand(!props.expanded, props.record)}
					className="bg-transparent border-0 outline-0 cursor-pointer"
				>
					<NotExpanded />
				</button>
			)
		) : null;
	};

	const getTrProps = (
		record: InvoiceInterface,
		_index: number,
		_indent: number,
	): string => {
		const isOverdue =
			record.status === TableStatusEnum.PENDING &&
			dayjs(record.dueDate).toISOString() < dayjs().toISOString();
		const status = isOverdue ? TableStatusEnum.OVERDUE : record.status;
		const bgClass = ['customer'].includes(target)
			? `invoices-table-row-${status}`
			: '';

		return bgClass;
	};

	return (
		<>
			<Table
				className={classNames('w-full max-h-full invoices-table', {
					'h-full': !rows?.length,
				})}
				expandable={
					target !== 'customer'
						? {
								expandedRowKeys,
								expandIcon,
								expandedRowRender,
						  }
						: {}
				}
				columns={getColumns()}
				data={keyRows}
				rowClassName={getTrProps}
				emptyText={() => {
					return (
						<div className="flex flex-col justify-center items-center h-full gap-8">
							<Search />
							<SypacText variant="heading-3">
								<p className="text-gray-80">
									<T keyName="invoicesTable.noResultFound">No result found</T>
								</p>
							</SypacText>
							<SypacText variant="body-normal-small">
								<p className="text-gray-50">
									<T keyName="invoicesTable.noResultFoundDescription">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									</T>
								</p>
							</SypacText>
						</div>
					);
				}}
			/>
			{openView ? (
				<DocumentsViewer
					url={url!}
					isPdf={true}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}
		</>
	);
};
