import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { SypacText, SypacButton } from '@sypac/component-library-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	ProductService,
	ProductStoreInterface,
} from '../../../services/product.services';
import { T, useTranslate } from '@tolgee/react';
import { MutatingDots } from 'react-loader-spinner';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { PlusIcon } from '../../../assets/PlusIcon';
import Flag from 'react-world-flags';
import Back from '../../../assets/Back';
import SearchBar from '../../../components/SearchBar/SearchBar';
import useDebounce from '../../../hooks/useDebounce';
import { ProductsTableProducer } from '../../../components/ProductsTableProducer/ProductsTableProducer';
import Pagination from '../../../components/Pagination/Pagination';
import { ProductInterface } from '../../../interfaces/product.interface';
import { LIMIT } from '../../../constants';
import ProductTabs from '../../Admin/Products/components/ProductTabs/ProductTabs';
import ProductDetailsDatabase from '../../Admin/ProductDatabase/components/ProductDetailsDatabase/ProductDetailsDatabase';
import { ProductContext } from '../../../context/DatabaseProductContext/database-product.context';
import Emitter, { EventType } from '../../../services/events';

const initialTabs = [
	{ label: 'All', color: 'white', count: '0', value: 'all' },
	{ label: 'Approved', color: 'green', count: '0', value: 'approved' },
	{ label: 'Pending approve', color: 'yellow', count: '0', value: 'pending' },
	{ label: 'Rejected', color: 'gray', count: '0', value: 'rejected' },
];

const Products: React.FC = () => {
	const { t } = useTranslate();
	const { storeId } = useParams();
	const { additional } = useContext(ProductContext);
	const navigate = useNavigate();
	const refs = useRef<(HTMLDivElement | null)[]>([]);

	const [currentTab, setCurrentTab] = useState<string>('all');
	const [store, setStore] = useState<ProductStoreInterface | undefined>();
	const [products, setProducts] = useState<ProductInterface[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [searchLocal, setSearchLocal] = useState<string>('');
	const [page, setPage] = useState<number>(0);
	const [count, setCount] = useState<number>(0);
	const [showManageProduct, setManageProduct] = useState<boolean>(false);
	const [product, setProduct] = useState<Partial<ProductInterface>>();
	const [tabs, setTabs] = useState(initialTabs);
	const refreshProductId = useRef<number | null>(null);

	const searchQuery = useDebounce(searchLocal, 500);

	const editProduct = (data: ProductInterface) => {
		setProduct({ ...product, ...data });
		setManageProduct(true);
	};

	const closeEdit = () => {
		setProduct(undefined);
		setManageProduct(false);
	};

	const getProducts = useCallback(async () => {
		setIsLoading(true);
		try {
			const { data } = await ProductService.getList({
				status: currentTab,
				limit: LIMIT,
				offset: page * LIMIT,
				role: OrderTarget.producer,
				productBaseId: storeId ? parseFloat(storeId) : undefined,
				search: searchQuery,
			});
			setCount(data?.count);
			setProducts(data?.items || []);
		} catch (error) {
			console.error('Failed to fetch products:', error);
		} finally {
			setIsLoading(false);
		}
	}, [currentTab, page, searchQuery, storeId]);

	const getStatistics = useCallback(async () => {
		setIsLoading(true);
		try {
			const statuses = ['all', 'approved', 'pending', 'rejected'];
			const results = await Promise.all(
				statuses.map((status) =>
					ProductService.getList({
						status,
						limit: 0,
						role: OrderTarget.producer,
						productBaseId: storeId ? parseFloat(storeId) : undefined,
					}),
				),
			);

			const newTabs = initialTabs.map((tab, index) => ({
				...tab,
				count: results[index]?.data?.count?.toString() ?? '0',
			}));

			setTabs(newTabs);
		} finally {
			setIsLoading(false);
		}
	}, [storeId]);

	const getStoresCallback = useCallback(async () => {
		if (!storeId) return;
		try {
			const { data } = await ProductService.getProductStores({
				limit: 1,
				offset: 0,
				ids: [parseInt(storeId)],
			});
			setStore(data?.items?.at(0));
		} finally {
		}
	}, [storeId]);

	const selectStatusTab = (tab: string) => {
		setCurrentTab(tab);
		setPage(0);
	};

	const clickOutsideDetails = useCallback(
		(event: MouseEvent) => {
			const isOutsideRefs = refs.current.every(
				(ref) => !ref?.contains(event.target as Node),
			);
			const isOutsideModals = additional.modalRefs.every(
				(modalRef) => !modalRef.current?.contains(event.target as Node),
			);
			if (isOutsideRefs && isOutsideModals) {
				setProduct(undefined);
				setManageProduct(false);
			}
		},
		[additional.modalRefs],
	);

	useEffect(() => {
		Promise.all([getStatistics(), getStoresCallback(), getProducts()]).then(
			() => {},
		);
	}, [getStatistics, getStoresCallback, getProducts]);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => document.removeEventListener('mousedown', clickOutsideDetails);
	}, [clickOutsideDetails]);

	useEffect(() => {
		Emitter.on(EventType.PRODUCT_DATABASE_REFRESH, (product) => {
			getProducts().then(() => {});
			refreshProductId.current = product.id;
		});
		return () => {
			Emitter.off(EventType.PRODUCT_DATABASE_REFRESH);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (refreshProductId.current && products.length > 0) {
			const updatedProduct = products.find(
				(p) => p.id === refreshProductId.current,
			);
			if (updatedProduct) {
				setProduct(updatedProduct);
				setManageProduct(true);
			}
			refreshProductId.current = null;
		}
	}, [products]);

	return (
		<section className="flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
			<header className="sm:flex flex-col gap-y-2 lg:grid grid-cols-12 mx-[30px]">
				<div className="col-span-9">
					<div className="flex items-center">
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="w-[42px] h-[42px] flex justify-center items-center p-0 border border-solid border-gray-10 rounded-10 transition-all hover:border-gray-60 group"
								onClick={() => navigate('/stores')}
							>
								<span className="h-[14px] duration-300 ease-in-out group-hover:-translate-x-[2px]">
									<Back />
								</span>
							</button>
						</SypacButton>
						<SypacText variant="heading-4" className="ml-4">
							<p className="text-gray-80">{store?.name}</p>
						</SypacText>
					</div>
					<div className="flex flex-row items-center mt-3">
						<Flag
							className="w-[20px] h-[14px] mb-[2px] object-cover rounded z-10 border border-solid border-gray-10 group-hover:mb-[6px]"
							code={store?.countryCode}
							width={22}
							height={16}
						/>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 group-hover:mb-[4px] ml-2">
								{store?.address}
							</p>
						</SypacText>
					</div>
				</div>
				<div className="flex justify-end col-start-11 col-span-2">
					<SypacButton>
						<Link
							to={`/products/${storeId}/groups`}
							className="flex gap-3 bg-transparent border-none group"
						>
							<span className="flex justify-center items-center h-[32px] w-[32px] p-0 rounded-lg bg-mountain-meadow transition group-hover:bg-mountain-meadow/80">
								<PlusIcon color="white" hoverEffect={true} />
							</span>
							<SypacText variant="body-normal-medium">
								<p className="text-gray-80 transition group-hover:text-gray-80/60">
									<T keyName="productGroup.addNewProduct">Add product</T>
								</p>
							</SypacText>
						</Link>
					</SypacButton>
				</div>
			</header>
			<div className="border-0 border-solid border-t-[1px] border-gray-10" />

			<section className="flex justify-between mx-[30px]">
				<ProductTabs
					data={tabs}
					callback={selectStatusTab}
					activeTab={currentTab}
				/>
				<div className="flex items-center gap-[42px] md:flex-col lg:flex-row">
					<SearchBar
						placeholder={t(
							'adminOrders.searchProduct',
							'Search order by product ID and product name',
						)}
						onChange={setSearchLocal}
						showButton={false}
						classNames="w-[384px]"
					/>
				</div>
			</section>

			<section className="w-full h-full">
				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					<div className="h-full relative ml-7.5 mr-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
						<div
							className={`w-full overflow-y-auto scroll-smooth pr-[5px] ${
								products.length ? 'h-[calc(100%-56px)]' : 'h-[calc(100%+2px)]'
							}`}
							ref={(el) => (refs.current[0] = el)}
						>
							<ProductsTableProducer
								rows={products}
								rowClick={(product) => editProduct(product)}
								search={searchQuery}
								clickedOutside={!showManageProduct}
								currentTab={currentTab}
								storeId={storeId!}
							/>
						</div>
						{products.length && (
							<footer className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
								<div className="flex justify-between items-center h-[51px] px-3">
									<Pagination
										showText={true}
										count={count}
										page={page}
										onClick={(item) => setPage(item)}
									/>
								</div>
							</footer>
						)}
					</div>
				)}
			</section>
			{showManageProduct && (
				<div ref={(el) => (refs.current[1] = el)}>
					<ProductDetailsDatabase
						product={product!}
						deleteProduct={() => {}}
						target={OrderTarget.producer}
						onClose={closeEdit}
					/>
				</div>
			)}
		</section>
	);
};

export default Products;
