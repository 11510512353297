import { IconInterface } from '../interfaces/icon.interface';

const Tons = (props: IconInterface) => {
	const { color = '#8B8B8B', width = 16, height = 18 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.39306 15.8429C2.35276 16.9992 4.13892 16.9992 7.71125 16.9992H8.2882C11.8605 16.9992 13.6467 16.9992 14.6064 15.8429M1.39306 15.8429C0.43336 14.6865 0.762528 12.9309 1.42087 9.41977C1.88904 6.92283 2.12313 5.67436 3.01185 4.93679M1.39306 15.8429C1.39306 15.8429 1.39306 15.8429 1.39306 15.8429ZM14.6064 15.8429C15.5661 14.6865 15.2369 12.9309 14.5786 9.41977C14.1104 6.92283 13.8763 5.67436 12.9876 4.93679M14.6064 15.8429C14.6064 15.8429 14.6064 15.8429 14.6064 15.8429ZM12.9876 4.93679C12.0989 4.19922 10.8286 4.19922 8.28819 4.19922H7.71125C5.1708 4.19922 3.90057 4.19922 3.01185 4.93679M12.9876 4.93679C12.9876 4.93679 12.9876 4.93679 12.9876 4.93679ZM3.01185 4.93679C3.01185 4.93679 3.01185 4.93679 3.01185 4.93679Z"
				stroke={color}
			/>
			<path
				d="M5.59961 4.2V3.4C5.59961 2.07452 6.67413 1 7.99961 1C9.32509 1 10.3996 2.07452 10.3996 3.4V4.2"
				stroke={color}
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Tons;
