import { IconInterface } from '../interfaces/icon.interface';

const StatusPendingPayment = (props: IconInterface) => {
	const { color = '#494C83', width = 16, height = 14 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.5"
				d="M9.5996 13.0973H6.39973C3.38287 13.0973 1.87444 13.0973 0.937219 12.1601C0 11.2229 0 9.71445 0 6.69759C0 6.34406 0.00150751 5.41127 0.00301568 5.09766H15.9993C16.0008 5.41127 15.9993 6.34406 15.9993 6.69759C15.9993 7.22516 15.9993 7.70661 15.9943 8.14693L14.8722 7.02478C14.1693 6.32187 13.0296 6.32187 12.3267 7.02478L10.7268 8.62472C10.0238 9.32763 10.0238 10.4673 10.7268 11.1702C11.0273 11.4707 11.4076 11.6427 11.7995 11.6863V13.0767C11.1707 13.0973 10.4443 13.0973 9.5996 13.0973Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.1741 7.87299C13.4084 7.63869 13.7883 7.63869 14.0226 7.87299L15.6225 9.47293C15.8568 9.70723 15.8568 10.0871 15.6225 10.3214C15.3882 10.5557 15.0083 10.5557 14.774 10.3214L14.1983 9.74571V13.097C14.1983 13.4284 13.9297 13.697 13.5983 13.697C13.267 13.697 12.9984 13.4284 12.9984 13.097V9.74571L12.4227 10.3214C12.1884 10.5557 11.8085 10.5557 11.5742 10.3214C11.3399 10.0871 11.3399 9.70723 11.5742 9.47293L13.1741 7.87299Z"
				fill={color}
			/>
			<path
				d="M8.40132 9.29785C8.06996 9.29785 7.80134 9.56647 7.80134 9.89783C7.80134 10.2292 8.06996 10.4978 8.40132 10.4978H9.60127C9.93263 10.4978 10.2012 10.2292 10.2012 9.89783C10.2012 9.56647 9.93263 9.29785 9.60127 9.29785H8.40132Z"
				fill={color}
			/>
			<path
				d="M3.20154 9.29785C2.87018 9.29785 2.60156 9.56647 2.60156 9.89783C2.60156 10.2292 2.87018 10.4978 3.20154 10.4978H6.4014C6.73276 10.4978 7.00138 10.2292 7.00138 9.89783C7.00138 9.56647 6.73276 9.29785 6.4014 9.29785H3.20154Z"
				fill={color}
			/>
			<path
				d="M6.39561 0.297852H9.60372C12.6284 0.297852 14.1407 0.297852 15.0803 1.19044C15.7569 1.83311 15.9463 2.7578 15.9993 4.29768V5.09765H0V4.29768C0.0530384 2.7578 0.242466 1.83311 0.91901 1.19044C1.85865 0.297852 3.37097 0.297852 6.39561 0.297852Z"
				fill={color}
			/>
		</svg>
	);
};

export default StatusPendingPayment;
