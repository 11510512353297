import React, { useMemo, useState } from 'react';
import { AdminOrderProps } from '../../adminOrder.interface';
import OrderDetailsStepper from '../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import { OrderStatusesEnum } from '../../../../../services/orders.services';
import PaymentTerms from '../AdditionalDetails/PaymentTerms';

const PaymentsTab: React.FC<AdminOrderProps> = ({ selectedOrder }) => {
	const { deliveryIncluded } = selectedOrder!;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const simpleOrder = useMemo(() => {
		return selectedOrder?.trucks?.length === 1;
	}, [selectedOrder?.trucks]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={
						simpleOrder && deliveryIncluded
							? 12
							: simpleOrder && !deliveryIncluded
							? 6
							: 5
					}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
					isFinalStep={selectedOrder?.status === OrderStatusesEnum.PAID}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PaymentTerms selectedOrder={selectedOrder} forSeller={true} />
			</div>

			{deliveryIncluded && (
				<div className="px-10 py-3.5">
					<PaymentTerms selectedOrder={selectedOrder} forSeller={false} />
				</div>
			)}
		</>
	);
};

export default PaymentsTab;
