import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Flag from 'react-world-flags';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NumericFormat } from 'react-number-format';
import {
	SypacButton,
	SypacDatepicker,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useGetCompanyById } from '../../../../hooks/use-get-company-by-id';
import useDebounce from '../../../../hooks/useDebounce';
import { ConfirmPaymentProps } from '../../../../components/ModalConfirmPayment/ModalConfirmPayment.interface';
import Close from '../../../../assets/Close';
import COLUMNS from '../../../../components/ModalConfirmPayment/columns.constants';
import { T } from '@tolgee/react';
import { InvoiceInterface } from '../../../../components/PaymentsTable/interfaces/Payment.interface';
import { OrderContext } from '../../../../context/OrderContext/order.context';
import InvoiceTable from '../../../../components/InvoiceTable/InvoiceTable';
import PdfIcon from '../../../../assets/PdfIcon';
import { formatDate } from '../../../../utils/time.util';
import Trash from '../../../../assets/Trash';
import Upload from '../../../../assets/Upload';
import Download from '../../../../assets/Download';
import EyeIcon from '../../../../assets/EyeIcon';
import { BillingService } from '../../../../services/billing.service';
import { FileType } from '../../../../constants';
import { getFileType } from '../../../../utils/files.util';
import DocumentsViewer from '../../../../components/DocumentsViewer/DocumentsViewer';

interface ModalPendingInvoiceConfirmationProps {
	isOpen: boolean;
	onClose: () => void;
	invoice: InvoiceInterface;
	onSubmit: any;
}

const ModalPendingInvoiceConfirmation: React.FC<
	ModalPendingInvoiceConfirmationProps
> = ({ isOpen, onClose, invoice, onSubmit }) => {
	const { setAdditional } = useContext(OrderContext);
	const [displayAmount, setDisplayAmount] = useState<string>('');
	const [company] = useGetCompanyById({
		id: +invoice?.invoiceLines[0]?.assigneeUser?.company?.id!,
	});
	const [openDocView, setOpenDocView] = useState<boolean>(false);
	const [openDocUrl, setOpenDocUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();
	const modalRef = useRef<HTMLDivElement>(null);

	const localFiles = useMemo(
		() =>
			invoice?.invoiceUrls?.length
				? invoice.invoiceUrls.map((url, index) => ({
						file: {
							lastModified: Date.now(),
							name: `invoice_${index + 1}.pdf`,
							size: 0,
							type: 'application/pdf',
							webkitRelativePath: '',
						},
						mimeType: 'PDF',
						name: `Invoice ${index + 1}`,
						size: 'Unknown',
						type: 'Invoice',
						fileUrl: url,
				  }))
				: [],
		[invoice?.invoiceUrls],
	);

	const tableData = useMemo(() => {
		return (
			invoice?.invoiceLines?.map((line) => ({
				key: line.orderId,
				order: line.orderId,
				product: line.description,
				total: 'string',
				quantity: '10',
				price: line.lineTotal?.toString()!,
			})) || []
		);
	}, [invoice]);

	const formik = useFormik({
		initialValues: {
			date: '',
			amount: '',
			realAmount: invoice?.balanceDue.toString() || '',
		},
		initialTouched: {
			amount: true,
		},
		onSubmit: async (values) => {
			try {
				await BillingService.confirmInvoiceAdmin('producer', invoice.id);
			} catch (e) {
			} finally {
				handleSubmit(values);
			}
		},
		validationSchema: Yup.object({
			realAmount: Yup.string().required('RealAmount is required'),
			date: Yup.string().required('Please enter date.'),
			amount: invoice?.balanceDue
				? Yup.string()
						.oneOf(
							[Yup.ref('realAmount')],
							'Please enter an amount equal with the amount to pay.',
						)
						.required('Please enter a valid amount.')
				: Yup.string().required('Please enter a valid amount.'),
		}),
	});

	const errorAmount = useDebounce(
		formik.touched.amount && formik.errors.amount,
		500,
	);

	const closeModal = () => {
		formik.resetForm();
		onClose();
	};

	const handleSubmit = (values: ConfirmPaymentProps) => {
		formik.resetForm();
		onSubmit(values);
	};

	const setPrice = (value: string) => {
		setDisplayAmount(value);
		if (!invoice?.balanceDue) {
			formik.setFieldValue('realAmount', value.replace(/,/g, '.'), true);
		}
		formik.setFieldValue('amount', value.replace(/,/g, '.'), true);
	};

	const onCallback = (detail: string) => {
		formik.setFieldTouched('amount', false);
		formik.setFieldValue('date', dayjs(detail).toISOString());
	};

	const openFile = (url: string) => {
		setOpenDocUrl(url);
		setOpenDocView(true);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<>
			<div className="modal-overlay" onClick={closeModal}>
				<div
					className="w-[828px] bg-white rounded-xl"
					onClick={(e) => e.stopPropagation()}
					ref={modalRef}
				>
					<form onSubmit={formik.handleSubmit} className="h-full">
						<div className="flex flex-col h-full">
							<div className="flex items-center justify-between p-3">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-90">
										<T keyName="modalPendingInvoiceConfirmation.pendingInvoiceConfirmation">
											Pending invoice confirmation
										</T>
									</p>
								</SypacText>
								<SypacButton variant="subTitle">
									<button
										type="button"
										className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
										onClick={closeModal}
									>
										<Close width="10" height="10" />
									</button>
								</SypacButton>
							</div>

							<div className="border-0 border-t border-solid border-gray-10" />

							<div className="max-h-[calc(100%-100px)] h-full">
								<div className="flex flex-col justify-between h-full">
									<div className="flex flex-col items-center justify-center p-3 gap-6">
										<div className="w-full flex flex-col gap-4">
											<SypacText variant="overline-regular-large">
												<p className="text-sm text-gray-80 text-left">
													Uploaded by seller
												</p>
											</SypacText>

											{localFiles.map((file, index) => (
												<>
													<div
														key={file?.name || Math.random() + index}
														className="flex gap-[14px] border border-dashed border-gray-10 bg-alabaster rounded-10 p-[10px]"
													>
														{getFileType(file.fileUrl) === FileType.JPG ? (
															<div
																style={{
																	backgroundImage: `url(${file.fileUrl})`,
																}}
																className="relative w-[76px] h-[76px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
																onClick={() => openFile(file.fileUrl)}
																onMouseEnter={() =>
																	handleBoxToggle(file.fileUrl)
																}
																onMouseLeave={() => handleBoxToggle(undefined)}
																role="img"
															>
																{showBlur === file.fileUrl && (
																	<div className="bg-black-50 absolute rounded-[11px] backdrop-blur-sm h-full w-full flex justify-center items-center">
																		<SypacIcon
																			size="custom"
																			width="32px"
																			height="32px"
																			icon-name="Magnifer"
																			className="text-white"
																		/>
																	</div>
																)}
															</div>
														) : (
															<div className="w-[76px] h-[76px] flex justify-center items-center border border-solid border-gray-10 bg-white rounded-lg">
																<PdfIcon />
															</div>
														)}
														<div className="flex flex-col gap-3">
															<SypacText variant="body-regular-medium">
																<p className="text-base text-gray-80">
																	{file.name}
																</p>
															</SypacText>
															<div className="flex justify-start items-center">
																<SypacText variant="overline-regular-large">
																	<p className="text-[10px] text-gray-40">
																		{file.mimeType}{' '}
																	</p>
																</SypacText>
																<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
																<SypacText variant="overline-regular-large">
																	<p className="text-[10px] text-gray-40">
																		{file.size}{' '}
																	</p>
																</SypacText>
															</div>
															<div className="flex gap-3 justify-start items-center">
																<SypacText variant="overline-regular-large">
																	<p className="text-xs text-gray-40">
																		<T keyName="modalUploadInvoice.uploadedBy">
																			Uploaded by:
																		</T>
																	</p>
																</SypacText>
																<SypacText variant="overline-regular-large">
																	<p className="text-xs text-gray-80">
																		Dean Russel
																	</p>
																</SypacText>
																<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
																<SypacText variant="overline-regular-large">
																	<p className="text-xs text-gray-80">
																		{formatDate(
																			new Date()
																				.toUTCString()
																				.replace(
																					'GMT',
																					'GMT+0000 (Coordinated Universal Time)',
																				),
																		)}
																	</p>
																</SypacText>
															</div>
														</div>

														<div className="flex gap-3 ml-auto">
															<SypacButton variant="secondary" size="small">
																<button
																	type="button"
																	className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
																	//onClick={() => handleRemoveFile(index)}
																>
																	<EyeIcon />
																</button>
															</SypacButton>
															<SypacButton variant="secondary" size="small">
																<button
																	type="button"
																	className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
																	//onClick={() => handleRemoveFile(index)}
																>
																	<Trash />
																</button>
															</SypacButton>
															<SypacButton variant="secondary" size="small">
																<button
																	type="button"
																	className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
																	//onClick={() => downloadDocument(file.file.url)}
																>
																	<Download />
																</button>
															</SypacButton>
															<SypacButton variant="secondary" size="small">
																<button
																	type="button"
																	className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
																	//onClick={() => handleRemoveFile(index)}
																>
																	<Upload color="#454545" />
																</button>
															</SypacButton>
														</div>
													</div>
												</>
											))}
										</div>
										<div className="w-full border-0 border-t border-solid border-gray-10" />

										<div className="w-full flex flex-col gap-5">
											<SypacText variant="heading-5">
												<p className="text-sm text-gray-80">
													Invoice generated by SYPAC
												</p>
											</SypacText>

											<div className="flex gap-5">
												<div className="w-[58px] h-[58px] flex items-center justify-center rounded-10 bg-gray-10">
													<SypacText variant="heading-5">
														<p className="text-gray-90 mt-1">
															{invoice?.invoiceLines[0]?.assigneeUser.company
																?.name!.split(' ')
																.map((name: any) => name[0])
																.join('')
																.toUpperCase()
																.slice(0, 2)}
														</p>
													</SypacText>
												</div>

												<div className="flex flex-col gap-2.5">
													<SypacText variant="heading-5">
														<p className="text-gray-90">
															{
																invoice?.invoiceLines[0].assigneeUser.company
																	?.name
															}
														</p>
													</SypacText>
													<div className="flex items-center gap-[10px]">
														<Flag
															className="object-cover rounded z-10 border border-solid border-gray-10"
															code={company?.countryCode.toLocaleUpperCase()}
															width={22}
															height={16}
														/>

														<SypacText variant="body-normal-medium">
															<p className="text-sm text-gray-80 mt-[2px]">
																{company?.registryAddress}
															</p>
														</SypacText>
													</div>
												</div>
											</div>

											<div className="bg-alabaster rounded-10">
												<div className="flex flex-col justify-center dashed-box p-3 gap-5">
													<div>
														<SypacText variant="body-regular-small">
															<p className="text-gray-80">
																FAKTURA NR/Invoice no {invoice?.invoiceNr}
															</p>
														</SypacText>
													</div>

													<>
														<div>
															<InvoiceTable
																columns={COLUMNS}
																data={tableData}
															/>
														</div>
														<div className="text-end">
															<SypacText variant="body-regular-small">
																<p className="text-gray-80">
																	Amount to be paid:{' '}
																	<NumericFormat
																		type="text"
																		className="font-bold"
																		displayType="text"
																		thousandSeparator="."
																		decimalSeparator=","
																		value={parseFloat(
																			invoice?.balanceDue?.toFixed(2) || '0',
																		)}
																		suffix={
																			invoice?.countryCode === 'MD'
																				? ' MDL'
																				: ' PLN'
																		}
																	/>
																</p>
															</SypacText>
														</div>
													</>
												</div>
											</div>
										</div>

										<SypacText variant="overline-regular-large">
											<p className="text-gray-40 text-left">
												Please enter the payment date and paid amount to confirm
												it. This is necessary to ensure the security of your
												transaction and the proper processing of the payment.
											</p>
										</SypacText>

										<div className="grid grid-cols-2 gap-5 w-full">
											<SypacInput
												error={!!(formik.touched.date && formik.errors.date)}
											>
												<label className="text-primary-dark-gray text-xs leading-4 -tracking-[0.01em]">
													Enter date <span className="text-red">*</span>
												</label>
												<SypacDatepicker
													name="date"
													placeholder="Enter date"
													min="1950-01-01"
													max={dayjs().toString()}
													value={formik?.values.date ? formik?.values.date : ''}
													inputReadOnly={true}
													onCallback={({ detail }) => onCallback(detail)}
												/>
												{formik.touched.date && formik.errors.date ? (
													<span className="bottom-helper text-red text-sm">
														{formik.errors.date}
													</span>
												) : null}
											</SypacInput>
											<SypacInput error={!!errorAmount}>
												<label
													className={`${
														!!errorAmount
															? 'text-red'
															: 'text-primary-dark-gray'
													}  text-xs leading-4 -tracking-[0.01em]`}
												>
													Enter paid amount <span className="text-red">*</span>
												</label>
												<div
													className={`${
														!!errorAmount ? 'border-red' : 'border-gray-22'
													} h-[40px] flex border-solid border-[1px] rounded-md hover:border-gray-40/80`}
												>
													<input
														type="text"
														name="amount"
														className="block border-0 py-2.5 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
														placeholder="Enter amount"
														value={displayAmount}
														onChange={(event) =>
															setPrice(event.target.value.trim())
														}
													/>
													<div
														className={`${
															!!errorAmount
																? 'border-left-error'
																: 'border-left'
														} py-2.5 px-5 text-gray-22`}
													>
														<SypacText
															variant="overline-normal-large"
															className="flex"
														>
															<p className="text-gray-80 mt-1">
																{invoice?.countryCode === 'MD' ? 'MDL' : 'PLN'}
															</p>
														</SypacText>
													</div>
												</div>
												{errorAmount ? (
													<span className={`bottom-helper text-red text-sm`}>
														{formik.errors.amount}
													</span>
												) : null}
											</SypacInput>
										</div>
									</div>

									<div className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
										<SypacButton
											variant="secondary"
											size="small"
											//onClick={onClose}
											className="w-full"
										>
											<button
												type="reset"
												className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
											>
												<SypacText variant="body-regular-medium">
													<p>
														<T keyName="modalPendingInvoiceConfirmation.reject">
															Reject
														</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
										<SypacButton
											variant="secondary"
											size="small"
											className="w-full"
										>
											<button
												type="submit"
												className="w-full h-[44px] flex items-center justify-center border-0 bg-primary-violet rounded-lg hover:bg-primary-violet/80"
											>
												<SypacText variant="body-regular-medium">
													<p className="text-white">
														<T keyName="modalDeposit.confirm">Confirm</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			{openDocView ? (
				<DocumentsViewer
					url={openDocUrl!}
					isPdf={false}
					isOpen={openDocView}
					onClose={() => setOpenDocView(false)}
					canDownload={true}
				/>
			) : null}
		</>
	) : null;
};

export default ModalPendingInvoiceConfirmation;
