import React, { useState } from 'react';
import {
	SypacAvatar,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import Modal from 'react-modal';
import { T } from '@tolgee/react';
import { ModalDeleteMemberProps } from '../userSettings.interface';
import LoadingSpinner from '../../../assets/LoadingSpinner';
import NewTrash from '../../../assets/NewTrash';
import Flag from 'react-world-flags';
import { UsersServices } from '../../../services/users.services';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';

const ModalDeleteMember: React.FC<ModalDeleteMemberProps> = ({
	isOpen,
	onClose,
	selectedUser,
	currentUser,
	updateMyProfile,
}) => {
	const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

	const deleteCompanyUser = async (id: string) => {
		setLoadingDelete(true);
		try {
			await UsersServices.deleteCompanyUser(id);
			toastVariant('User deleted successfully.', false);
			onClose();
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		} finally {
			setLoadingDelete(false);
			updateMyProfile();
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="w-[516px] modal-inside outline-none box-border"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-col gap-[28px] px-3 pb-3">
					<span className="flex scale-[3.5] w-[18px] ml-[20px] my-[30px]">
						<NewTrash strokeWidth="0.5" />
					</span>
					<div className="flex flex-col gap-4">
						<SypacText variant="body-regular-medium">
							<p className="text-2xl text-gray-80">
								<T keyName="modalDeleteMember.areYouSure">
									Are you sure you want to delete this user?
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</p>
						</SypacText>
						<div className="flex gap-3 p-[10px] border border-dashed border-gray-10 rounded-10 bg-alabaster">
							<SypacAvatar
								initials={
									selectedUser?.firstName + ' ' + selectedUser?.lastName
								}
								size="sm"
								className="scale-[1.866] m-[14px]"
							>
								{/*{myProfile?.profile.photoUrl && (*/}
								{/*	<img*/}
								{/*		src={myProfile?.profile.photoUrl || ''}*/}
								{/*		alt={userName}*/}
								{/*		className="w-full h-full object-cover rounded-full"*/}
								{/*	/>*/}
								{/*)}*/}
							</SypacAvatar>

							<div className="flex flex-col justify-between">
								<div className="flex gap-5">
									<SypacText variant="body-regular-medium">
										<p className="text-xl text-gray-80">
											{selectedUser?.firstName + ' ' + selectedUser?.lastName}
										</p>
									</SypacText>

									<div
										className={`flex justify-center items-center px-[26.5px] rounded-full ${
											selectedUser?.id === currentUser.id
												? 'bg-primary-violet'
												: 'bg-texas-rose/30'
										}`}
									>
										{selectedUser?.id === currentUser.id ? (
											<SypacText variant="body-regular-medium">
												<p className="text-xs text-white">
													<T keyName="userSettings.profileOwner">
														Profile owner
													</T>
												</p>
											</SypacText>
										) : (
											<SypacText variant="body-regular-medium">
												<p className="text-xs text-gray-80">
													<T keyName="userSettings.moderator">Moderator</T>
												</p>
											</SypacText>
										)}
									</div>
								</div>

								<div className="flex items-center gap-3">
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-40">
											{selectedUser?.email}
										</p>
									</SypacText>
									<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />

									<div className="flex gap-4">
										<Flag
											className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
											code={selectedUser?.countryCode}
											width={22}
											height={16}
										/>
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-40">
												{selectedUser?.phoneNumber?.phoneNumber}
											</p>
										</SypacText>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex w-full py-2.5 xl-2xl:py-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl mt-auto">
					<SypacButton
						variant="secondary"
						size="small"
						className="w-full pl-2.5 xl-2xl:pl-3"
					>
						<button
							type="button"
							className="w-full py-2.5 rounded-lg"
							onClick={onClose}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalChangeRequest.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="primary"
						size="small"
						className="w-full pr-2.5 xl-2xl:pr-3"
					>
						<button
							type="button"
							className={`w-full py-2.75 rounded-lg ${
								loadingDelete ? 'bg-primary-violet/80 text-gray-500' : ''
							}`}
							onClick={() => deleteCompanyUser(selectedUser?.uid)}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									<T keyName="modalDeposit.confirm">Confirm</T>
									{loadingDelete && <LoadingSpinner />}
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ModalDeleteMember;
