import { IconInterface } from '../interfaces/icon.interface';

const Clock = (props: IconInterface) => {
	const { color = '#8B8B8B', width = 18, height = 18 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="9" cy="9" r="8" stroke={color} />
			<path
				d="M9 5.80078V9.00078L11 11.0008"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Clock;
