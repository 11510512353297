import { IconInterface } from '../interfaces/icon.interface';

const StatusDocument = (props: IconInterface) => {
	const { color = '#5682FA', width = 14, height = 16 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.5"
				d="M0 6.4C0 3.38301 0 1.87452 0.911223 0.937258C1.82245 0 3.28904 0 6.22222 0H7.77778C10.711 0 12.1776 0 13.0888 0.937258C14 1.87452 14 3.38301 14 6.4V9.6C14 12.617 14 14.1255 13.0888 15.0627C12.1776 16 10.711 16 7.77778 16H6.22222C3.28904 16 1.82245 16 0.911223 15.0627C0 14.1255 0 12.617 0 9.6V6.4Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.19922 8.00039C3.19922 7.66902 3.46785 7.40039 3.79922 7.40039H10.1992C10.5306 7.40039 10.7992 7.66902 10.7992 8.00039C10.7992 8.33176 10.5306 8.60039 10.1992 8.60039H3.79922C3.46785 8.60039 3.19922 8.33176 3.19922 8.00039Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.19922 4.79922C3.19922 4.46785 3.46785 4.19922 3.79922 4.19922H10.1992C10.5306 4.19922 10.7992 4.46785 10.7992 4.79922C10.7992 5.13059 10.5306 5.39922 10.1992 5.39922H3.79922C3.46785 5.39922 3.19922 5.13059 3.19922 4.79922Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.39844 11.1996C4.39844 10.8682 4.66707 10.5996 4.99844 10.5996H8.99844C9.32981 10.5996 9.59844 10.8682 9.59844 11.1996C9.59844 11.531 9.32981 11.7996 8.99844 11.7996H4.99844C4.66707 11.7996 4.39844 11.531 4.39844 11.1996Z"
				fill={color}
			/>
		</svg>
	);
};

export default StatusDocument;
