import React, { useContext, useMemo, useState } from 'react';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import FulfilmentSchedule from '../../AdditionalDetails/FulfilmentSchedule';
import PickUpDeliveryDetails from '../../AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import { AdminOrderProps } from '../../../adminOrder.interface';
import PaymentTerms from '../../AdditionalDetails/PaymentTerms';
import { PaymentNotesStatusesEnum } from '../../../../../../services/billing.service';
import { OrderDetailsContext } from '../../../../../../context/OrderDetailsContext/order-details.context';
import OrderDetailsInvoice from '../../../../../../components/OrderDetailsInvoice/OrderDetailsInvoice';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';

const Delivered: React.FC<AdminOrderProps> = ({ selectedOrder }) => {
	const { producerInvoice } = useContext(OrderDetailsContext);
	const { deliveryIncluded } = selectedOrder!;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const simpleOrder = useMemo(() => {
		return selectedOrder?.trucks?.length === 1;
	}, [selectedOrder?.trucks]);

	const currentStep = useMemo(() => {
		const baseStep =
			simpleOrder && deliveryIncluded
				? 12
				: simpleOrder && !deliveryIncluded
				? 6
				: 5;

		if (producerInvoice?.status === PaymentNotesStatusesEnum.PENDING_INVOICE) {
			return baseStep - 1;
		}

		if (producerInvoice?.status === PaymentNotesStatusesEnum.UPLOADED_INVOICE) {
			return baseStep - 1;
		}

		if (
			producerInvoice?.status === PaymentNotesStatusesEnum.INVOICE_CONFIRMED
		) {
			return baseStep - 1;
		}

		if (producerInvoice?.status === PaymentNotesStatusesEnum.PAID) {
			return baseStep;
		}

		return baseStep;
	}, [producerInvoice, simpleOrder, deliveryIncluded]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={currentStep}
					simpleOrder={simpleOrder}
					isFinalStep={
						producerInvoice?.status === PaymentNotesStatusesEnum.PAID
					}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			{[
				PaymentNotesStatusesEnum.UPLOADED_INVOICE,
				PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
			].includes(producerInvoice?.status as PaymentNotesStatusesEnum) && (
				<div className="px-10 pt-7 pb-3.5">
					<OrderDetailsInvoice
						currentStatus={producerInvoice?.status!}
						target={OrderTarget.admin}
					/>
				</div>
			)}

			<div
				className={`px-10 ${
					[
						PaymentNotesStatusesEnum.UPLOADED_INVOICE,
						PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
					].includes(producerInvoice?.status as PaymentNotesStatusesEnum)
						? 'py-3.5'
						: 'pt-7 pb-3.5'
				}`}
			>
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={simpleOrder}
					deliveryIncluded={deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PaymentTerms selectedOrder={selectedOrder} forSeller={true} />
			</div>

			{deliveryIncluded && (
				<div className="px-10 py-3.5">
					<PaymentTerms selectedOrder={selectedOrder} forSeller={false} />
				</div>
			)}

			<div className="px-10 py-3.5">
				{deliveryIncluded ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={simpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>

			<div className="px-10 py-3.5">
				{deliveryIncluded ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={simpleOrder}
					/>
				) : (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={
							simpleOrder && deliveryIncluded ? 12 : !deliveryIncluded ? 6 : 5
						}
						deliveryIncluded={deliveryIncluded!}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>

			<div className="px-10 pb-7 pt-3.5">
				{deliveryIncluded ? (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={
							simpleOrder && deliveryIncluded ? 12 : !deliveryIncluded ? 6 : 5
						}
						deliveryIncluded={deliveryIncluded!}
						simpleOrder={simpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>
		</>
	);
};

export default Delivered;
