import { IconInterface } from '../interfaces/icon.interface';

const NewTrash = (props: IconInterface) => {
	const {
		color = '#F44A77',
		width = 17,
		height = 18,
		hoverEffect = false,
		strokeWidth = '1',
	} = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={hoverEffect ? 'transition group-hover:stroke-red/70' : ''}
		>
			<path
				d="M16.1112 3.66602H1"
				stroke={color}
				strokeLinecap="round"
				strokeWidth={strokeWidth}
				className={hoverEffect ? 'transition group-hover:stroke-red/70' : ''}
			/>
			<path
				d="M14.6247 5.88867L14.2159 12.0212C14.0585 14.3811 13.9799 15.5611 13.211 16.2804C12.4421 16.9998 11.2595 16.9998 8.89435 16.9998H8.20692C5.84176 16.9998 4.65918 16.9998 3.89029 16.2804C3.12139 15.5611 3.04273 14.3811 2.8854 12.0212L2.47656 5.88867"
				stroke={color}
				strokeLinecap="round"
				strokeWidth={strokeWidth}
				className={hoverEffect ? 'transition group-hover:stroke-red/70' : ''}
			/>
			<path
				d="M3.66406 3.66667C3.71373 3.66667 3.73857 3.66667 3.76108 3.6661C4.49303 3.64755 5.13875 3.18214 5.38781 2.49362C5.39547 2.47244 5.40333 2.44888 5.41903 2.40176L5.50533 2.14286C5.579 1.92185 5.61584 1.81134 5.6647 1.71751C5.85963 1.34318 6.22028 1.08323 6.63705 1.01668C6.74151 1 6.85799 1 7.09096 1H10.0149C10.2479 1 10.3644 1 10.4689 1.01668C10.8856 1.08323 11.2463 1.34318 11.4412 1.71751C11.4901 1.81134 11.5269 1.92184 11.6006 2.14286L11.6869 2.40176C11.7026 2.44881 11.7104 2.47245 11.7181 2.49362C11.9672 3.18214 12.6129 3.64755 13.3448 3.6661C13.3673 3.66667 13.3922 3.66667 13.4418 3.66667"
				stroke={color}
				strokeWidth={strokeWidth}
				className={hoverEffect ? 'transition group-hover:stroke-red/70' : ''}
			/>
		</svg>
	);
};

export default NewTrash;
