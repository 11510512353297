import React, { DragEvent, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import { MAX_FILE_SIZE_MB, PREFERRED_PHONE_COUNTIES } from '../constants';
import { UpdateProfile, UsersServices } from '../../../services/users.services';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import { MutatingDots } from 'react-loader-spinner';
import { FileService } from '../../../services/file.service';
import { FileInterface } from '../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { SettingMyProfileProps } from '../userSettings.interface';
import LoadingSpinner from '../../../assets/LoadingSpinner';
import { PhotoFrame } from '../../../assets/PhotoFrame';

const SettingMyProfile: React.FC<SettingMyProfileProps> = ({
	profile,
	loadingProfile,
	updateMyProfile,
	user,
}) => {
	const { t } = useTranslate();
	const [file, setFile] = useState<FileInterface | undefined>(undefined);
	const [addSecondEmail] = useState<boolean>(false);
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const formRef = useRef<HTMLFormElement>(null);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstName: profile?.firstName,
			lastName: profile?.lastName,
			primaryEmail: profile?.email,
			secondaryEmail: profile?.secondaryEmail,
			phoneNumber: profile?.phoneNumber?.phoneNumber,
		},
		onSubmit: async (values) => {
			// setLoadingProfile(true);
			try {
				const body: UpdateProfile = {
					firstName: values.firstName,
					lastName: values.lastName,
					phoneNumber: {
						phoneNumber: values.phoneNumber,
						isViber: true,
						isTelegram: true,
						isWhatsapp: true,
					},
					photoUrl: file?.file?.url,
					email: values.primaryEmail,
					secondaryEmail: values.secondaryEmail,
				};

				await UsersServices.updateMyProfile(user?.company.industries[0]!, {
					...body,
				});
				toastVariant('Profile updated successfully', false);
			} catch (e) {
			} finally {
				// setLoadingProfile(false);
				setFormEdited(false);
				updateMyProfile();
			}
		},
		validationSchema: Yup.object({
			firstName: Yup.string().required('First name number is required'),
			lastName: Yup.string().required('Last name is required'),
			primaryEmail: Yup.string()
				.email('Email address must be an email')
				.required('Email is required'),
			phoneNumber: Yup.string().required('Phone number is required'),
		}),
		onReset: () => {
			setFormEdited(false);
		},
	});

	const getDataFiles = async (file: File) => {
		try {
			const { data } = await FileService.uploadFiles([file]);
			const newFile = data.items[0];
			const [name, type] = newFile.originalName?.split('.') || [];
			const mimeType =
				newFile.type?.split('/').pop()?.toUpperCase() ||
				type?.toUpperCase() ||
				'';
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;

			setFile({ name, mimeType, size, file: newFile });
			setFormEdited(true);
		} catch (e) {}
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			const droppedFile = e.dataTransfer.files[0];
			if (droppedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(droppedFile).then(() => {});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			const selectedFile = e.target.files[0];
			if (selectedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(selectedFile).then(() => {});
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik.handleChange(e);

		const fieldName = e.target.name;
		const initialValue =
			formik.initialValues[fieldName as keyof typeof formik.initialValues];
		const currentValue = e.target.value;

		if (initialValue !== currentValue) {
			setFormEdited(true);
		}
	};

	const changePhone = (key: string, value: string) => {
		formik?.setFieldValue(key, value);

		const initialPhoneNumber = formik.initialValues.phoneNumber;
		if (initialPhoneNumber !== value) {
			setFormEdited(true);
		}
	};

	const triggerSubmit = () => {
		formRef.current?.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true }),
		);
	};

	const triggerReset = () => {
		formRef.current?.reset();
		formik.resetForm();
	};

	return (
		<div className="flex flex-col">
			<div className="flex flex-col gap-[14px] mb-[24px]">
				<SypacText variant="heading-4">
					<p className="text-xl text-gray-80">
						<T keyName="userSettings.profile">Profile</T>
					</p>
				</SypacText>

				<SypacText variant="heading-4">
					<p className="text-base text-gray-40">
						<T keyName="userSettings.updateYourPhoto">Update your photo.</T>
					</p>
				</SypacText>
			</div>

			{loadingProfile ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1000]">
					<MutatingDots
						height="100"
						width="100"
						color="#7693F4"
						secondaryColor="#494C83"
						radius="12.5"
						ariaLabel="mutating-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			) : (
				<form
					ref={formRef}
					onSubmit={formik.handleSubmit}
					onReset={formik.handleReset}
					className="w-[532px] flex flex-col p-3 gap-6 border border-solid border-gray-10 rounded-10 bg-alabaster"
				>
					<div
						style={{
							backgroundImage: `url(${file?.file.url || profile?.photoUrl})`,
						}}
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
						className={`w-[120px] h-[120px] flex border border-solid border-gray-10 rounded-full cursor-pointer box-border transition hover:border-gray-50 ${
							file?.file.url || profile?.photoUrl
								? 'bg-no-repeat bg-cover bg-center'
								: 'bg-white'
						}`}
						role="img"
					>
						<input
							type="file"
							id="input-file-upload"
							className="hidden"
							multiple={true}
							accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
							onChange={handleChange}
						/>
						<label
							htmlFor="input-file-upload"
							className="w-full flex flex-col gap-[10px] justify-center items-center cursor-pointer"
						>
							{!profile?.photoUrl && !file?.file.url ? (
								<>
									<span className="flex scale-[1.2]">
										<PhotoFrame color="#454545" />
									</span>
									<SypacText variant="overline-normal-large">
										<p className="text-sm text-gray-80 text-center">
											<T keyName="settingMyProfile.uploadPhoto">Upload photo</T>
										</p>
									</SypacText>
								</>
							) : null}
						</label>
					</div>

					<div className="flex flex-col gap-3">
						<SypacInput
							error={!!(formik.touched.firstName && formik.errors.firstName)}
						>
							<SypacText className="mb-[10px]" variant="overline-normal-large">
								<p>
									<T keyName="userSettings.firstName">First Name</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="firstName"
								type="text"
								placeholder={t(
									'userSettings.enterFirstName',
									'Enter first name',
								)}
								value={formik?.values.firstName}
								onChange={handleInputChange}
							/>
							{formik.touched.firstName && formik.errors.firstName ? (
								<span className="bottom-helper">{formik.errors.firstName}</span>
							) : null}
						</SypacInput>
						<SypacInput
							error={!!(formik.touched.lastName && formik.errors.lastName)}
						>
							<SypacText className="mb-[10px]" variant="overline-normal-large">
								<p>
									<T keyName="userSettings.lastName">Last Name</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="lastName"
								type="text"
								placeholder={t('userSettings.enterLastName', 'Enter last name')}
								value={formik?.values.lastName}
								onChange={handleInputChange}
							/>
							{formik.touched.lastName && formik.errors.lastName ? (
								<span className="bottom-helper">{formik.errors.lastName}</span>
							) : null}
						</SypacInput>
						<SypacInput
							error={
								!!(formik.touched.primaryEmail && formik.errors.primaryEmail)
							}
							disabled={true}
						>
							<SypacText className="mb-[10px]" variant="overline-normal-large">
								<p>
									<T keyName="userSettings.emailAddress">Email address</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="primaryEmail"
								type="text"
								placeholder={t(
									'userSettings.enterEmailAddress',
									'Enter email address',
								)}
								value={formik?.values.primaryEmail}
								onChange={handleInputChange}
							/>
							{formik.touched.primaryEmail && formik.errors.primaryEmail ? (
								<span className="bottom-helper">
									{formik.errors.primaryEmail}
								</span>
							) : null}
						</SypacInput>
						<>
							{(!!profile?.secondaryEmail || addSecondEmail) && (
								<SypacInput
									error={
										!!(
											formik.touched.secondaryEmail &&
											formik.errors.secondaryEmail
										)
									}
								>
									<SypacText
										className="mb-[10px]"
										variant="overline-normal-large"
									>
										<p>
											<T keyName="userSettings.secondaryEmailAddress">
												Secondary email address
											</T>
										</p>
									</SypacText>
									<input
										className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
										name="secondaryEmail"
										type="text"
										placeholder={t(
											'userSettings.enterSecondaryEmailAddress',
											'Enter secondary email address',
										)}
										value={formik?.values.secondaryEmail}
										onChange={handleInputChange}
									/>
									{formik.touched.secondaryEmail &&
									formik.errors.secondaryEmail ? (
										<span className="bottom-helper">
											{formik.errors.secondaryEmail}
										</span>
									) : null}
								</SypacInput>
							)}
							{/* <SypacButton
									variant="secondary"
									size="small"
									className="self-start"
								>
									<button
										type="button"
										className="p-0 border-0 bg-transparent group"
										onClick={() =>
											!!profile?.secondaryEmail || !addSecondEmail
												? setAddSecondEmail(!addSecondEmail)
												: removeSecondEmail()
										}
									>
										<span className="flex scale-[0.8]">
											<EditPenIcon color="#5682FA" />
										</span>
										<SypacText
											variant="body-regular-medium"
											className="ml-1 mt-[2px]"
										>
											<p className="text-cornflower-blue transition group-hover:text-cornflower-blue/70">
												{!addSecondEmail ? (
													<T keyName="userSettings.addAnotherEmail">
														Add another email
													</T>
												) : (
													<T keyName="userSettings.removeAnotherEmail">
														Remove another email
													</T>
												)}
											</p>
										</SypacText>
									</button>
								</SypacButton> */}
						</>
						<SypacInput
							error={
								!!(formik.touched.phoneNumber && formik.errors.phoneNumber)
							}
						>
							<SypacText className="mb-[10px]" variant="overline-normal-large">
								<p>
									<T keyName="userSettings.phoneNumber">Phone Number</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<PhoneInput
								containerClass="border border-solid border-gray-20 rounded-lg hover:border-gray-30 focus-within:border-cornflower-blue focus-within:hover:border-cornflower-blue"
								placeholder={t(
									'userSettings.enterPhoneNumber',
									'Enter phone number',
								)}
								value={formik?.values.phoneNumber}
								onChange={(phone) => changePhone('phoneNumber', phone)}
								preferredCountries={PREFERRED_PHONE_COUNTIES}
								country="pl"
							/>
							{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
								<span className="bottom-helper">
									{formik.errors.phoneNumber}
								</span>
							) : null}
						</SypacInput>
					</div>
				</form>
			)}

			{formEdited ? (
				<div className="w-[1084px] absolute bottom-0 bg-white p-4 border-0 border-t border-solid border-t-gray-10 animate-slide-up box-border z-10">
					{/*<div className="h-2 absolute top-0 left-[50%] -translate-x-[50%] w-[98%] shadow-[0_-4px_18px_rgba(0,0,0,0.2)] -z-10" />*/}

					<div className="flex gap-4">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
								onClick={triggerReset}
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="modalConfirmationCode.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>

						<SypacButton variant="primary" size="small" className="w-full">
							<button
								type="button"
								disabled={formik.isSubmitting}
								className="w-full py-2.75 rounded-lg"
								onClick={triggerSubmit}
							>
								<SypacText
									variant="body-regular-medium"
									className="flex items-center gap-4"
								>
									<p className="text-white">
										<T keyName="modalManageProduct.save">Save</T>
									</p>
									{formik.isSubmitting ? <LoadingSpinner /> : null}
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default SettingMyProfile;
