import React, { DragEvent, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	SypacButton,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { ProductService } from '../../../../../../services/product.services';
import { useDetectClickOutside } from 'react-detect-click-outside';
import Flag from 'react-world-flags';
import { COUNTRIES } from '../../../../../../components/DropDownOption/countries.constant';
import { ChevronRightIcon } from '../../../../../../assets/ChevronRightIcon';
import { FileInterface } from '../../../../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { useGetCategories } from '../../../../../../hooks/use-get-categories';
import { FileService } from '../../../../../../services/file.service';
import { toastVariant } from '../../../../../../components/CompaniesTable/toastVariant/toastVariant';
import { ProductDetailsDatabaseProps } from '../../../productDatabase.interface';
import { PhotoFrame } from '../../../../../../assets/PhotoFrame';
import NewTrash from '../../../../../../assets/NewTrash';
import ModalDeletePhoto from './ModalDeletePhoto';
import EyeIcon from '../../../../../../assets/EyeIcon';
import ModalAddTranslates from '../../../../../../components/ModalAddTranslates/ModalAddTranslates';
import Emitter, { EventType } from '../../../../../../services/events';
import { MAX_FILE_SIZE_MB } from '../../../../../UserSettings/constants';

const Edit: React.FC<ProductDetailsDatabaseProps> = ({ onClose, product }) => {
	const { t } = useTranslate();
	const [groups] = useGetCategories({}, 'admin');
	const [activeGroup, setActiveGroup] = useState<string>(
		product?.category?.parent?.id!,
	);
	const [categories] = useGetCategories(
		{
			parentIds: [activeGroup!],
		},
		'admin',
	);
	const [file, setFile] = useState<FileInterface | undefined>({
		fileUrl: product?.photoUrl,
	});
	const [fileUpdated, setFileUpdated] = useState<boolean>(false);
	const [categoryDropdownOpen, setCategoryDropdownOpen] =
		useState<boolean>(false);
	const [groupDropdownOpen, setGroupDropdownOpen] = useState<boolean>(false);
	const [countryDropdownOpen, setCountryDropdownOpen] =
		useState<boolean>(false);
	const [charCount, setCharCount] = useState<number>(0);
	const [photoHover, setPhotoHover] = useState<boolean>(false);
	const [deletePhoto, setDeletePhoto] = useState<boolean>(false);
	const [addTranslate, setAddTranslate] = useState<boolean>(false);
	const [translateType, setTranslateType] = useState<string>('');
	const [formEdited, setFormEdited] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: {
			productCategory: product?.category?.id,
			productGroup: product?.category?.parent?.id,
			size: product?.size,
			type: product?.type,
			productName:
				product?.translations?.find((locale) => locale.language === 'en')
					?.name || product?.name,
			countryStore: product?.countryCode,
			description: product?.description,
			productNameRO: '',
			productNameRU: '',
			productNamePL: '',
			descriptionRO: '',
			descriptionRU: '',
			descriptionPL: '',
		},
		onSubmit: async (values) => {
			const translations = [
				{
					language: 'en',
					name: values.productName || '',
					description: values.description || '',
					// type: values.type || '',
				},
				{
					language: 'ro',
					name: values.productNameRO || '',
					description: values.descriptionRO || '',
					// type: values.typeRO || '',
				},
				{
					language: 'ru',
					name: values.productNameRU || '',
					description: values.descriptionRU || '',
					// type: values.typeRU || '',
				},
				{
					language: 'pl',
					name: values.productNamePL || '',
					description: values.descriptionPL || '',
					// type: values.typePL || '',
				},
			];

			const nonEmptyTranslations = translations.filter(
				(translation) => translation.name || translation.description,
			);

			try {
				const body = {
					name: values.productName!,
					categoryId: values.productCategory!,
					...(fileUpdated && { photoUrl: file?.file?.name }),
					countryCode: values.countryStore!,
					size: values.size!,
					type: values.type!,
					unit: 'ton',
					description: values.description,
					translations: nonEmptyTranslations,
				};

				await ProductService.updateProductDatabase(product?.id!, body);
				Emitter.emit(EventType.PRODUCT_DATABASE_REFRESH);
				onClose && onClose();
				setFormEdited(false);
			} catch (e) {}
		},
		validationSchema: Yup.object({
			productCategory: Yup.string().required(
				t(
					'modalAddProduct.productCategoryRequired',
					'Please select a product category',
				),
			),
			productGroup: Yup.string().required(
				t(
					'modalAddProduct.productGroupRequired',
					'Please select a product group',
				),
			),
			productName: Yup.string().required(
				t(
					'modalAddProduct.productNameRequired',
					'Please enter the product name',
				),
			),
			countryStore: Yup.string().required(
				t(
					'modalAddProduct.countryStoreRequired',
					'Please select the store country',
				),
			),
			size: Yup.string(),
			type: Yup.string(),
		}),
	});

	const getDataFiles = async (file: File) => {
		try {
			const { data } = await FileService.uploadFiles([file]);
			const newFile = data.items[0];
			const [name, type] = newFile.originalName?.split('.') || [];
			const mimeType =
				newFile.type?.split('/').pop()?.toUpperCase() ||
				type?.toUpperCase() ||
				'';
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;

			setFile({ name, mimeType, size, file: newFile });
			setFormEdited(true);
			setFileUpdated(true);
		} catch (e) {}
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			const droppedFile = e.dataTransfer.files[0];
			if (droppedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(droppedFile).then(() => {});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			const selectedFile = e.target.files[0];
			if (selectedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(selectedFile).then(() => {});
		}
	};

	const handleDeleteFile = () => {
		setFile(undefined);
		setDeletePhoto(false);
	};

	const handleCategoryOutsideClick = () => {
		if (categoryDropdownOpen && !formik.values.productCategory) {
			formik.setFieldTouched('productCategory', true);
			formik.validateField('productCategory');
		}
		setCategoryDropdownOpen(false);
	};

	const handleGroupOutsideClick = () => {
		if (groupDropdownOpen && !formik.values.productGroup) {
			formik.setFieldTouched('productGroup', true);
			formik.validateField('productGroup');
		}
		setGroupDropdownOpen(false);
	};

	const handleCountryStoreOutsideClick = () => {
		if (countryDropdownOpen && !formik.values.countryStore) {
			formik.setFieldTouched('countryStore', true);
			formik.validateField('countryStore');
		}
		setCountryDropdownOpen(false);
	};

	const handleFormFieldChange = (event: React.ChangeEvent<any>) => {
		formik.handleChange(event);
		setFormEdited(true);
	};

	const handleCountrySelection = (countryCode: string) => {
		formik.setFieldValue('countryStore', countryCode);
		setFormEdited(true);
		setCountryDropdownOpen(false);
	};

	const handleGroupSelection = (groupId: string) => {
		formik.setFieldValue('productGroup', groupId);
		setFormEdited(true);
		setActiveGroup(groupId);
		setGroupDropdownOpen(false);
	};

	const handleCategorySelection = (categoryId: string) => {
		formik.setFieldValue('productCategory', categoryId);
		setFormEdited(true);
		setCategoryDropdownOpen(false);
	};

	const handleTextareaChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		const text = event.target.value;
		formik.handleChange(event);
		setCharCount(text.length);
		setFormEdited(true);
	};

	const categoryRef = useDetectClickOutside({
		onTriggered: handleCategoryOutsideClick,
	});
	const groupRef = useDetectClickOutside({
		onTriggered: handleGroupOutsideClick,
	});
	const countryRef = useDetectClickOutside({
		onTriggered: handleCountryStoreOutsideClick,
	});

	const closeTransModal = () => {
		setAddTranslate(false);
		setTranslateType('');
	};

	const handleModalSave = (translations: any) => {
		const { key, translations: langTranslations } = translations;

		if (key === 'name') {
			const translationMap: { [key: string]: string } = langTranslations.reduce(
				(acc: any, { language, name }: { language: string; name: string }) => {
					acc[language] = name;
					return acc;
				},
				{},
			);
			formik.setFieldValue('productName', translationMap.en || '');
			formik.setFieldValue('productNameRO', translationMap.ro || '');
			formik.setFieldValue('productNameRU', translationMap.ru || '');
			formik.setFieldValue('productNamePL', translationMap.pl || '');
		} else if (key === 'type') {
			const translationMap: { [key: string]: string } = langTranslations.reduce(
				(acc: any, { language, type }: { language: string; type: string }) => {
					acc[language] = type;
					return acc;
				},
				{},
			);
			formik.setFieldValue('type', translationMap.en || '');
			formik.setFieldValue('typeRO', translationMap.ro || '');
			formik.setFieldValue('typeRU', translationMap.ru || '');
			formik.setFieldValue('typePL', translationMap.pl || '');
		} else if (key === 'description') {
			const translationMap: { [key: string]: string } = langTranslations.reduce(
				(
					acc: any,
					{ language, description }: { language: string; description: string },
				) => {
					acc[language] = description;
					return acc;
				},
				{},
			);
			formik.setFieldValue('description', translationMap.en || '');
			formik.setFieldValue('descriptionRO', translationMap.ro || '');
			formik.setFieldValue('descriptionRU', translationMap.ru || '');
			formik.setFieldValue('descriptionPL', translationMap.pl || '');
		}
		setFormEdited(true);
		closeTransModal();
	};

	return (
		<>
			<form
				onSubmit={formik.handleSubmit}
				className={`${formEdited ? 'mb-[100px]' : ''}`}
			>
				<div className="flex border-0 border-b border-t border-solid border-gray-10">
					<div className="w-full flex items-center justify-between px-10 py-[15px]">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								<T keyName="productDetails.productInformation">
									Product information
								</T>
							</p>
						</SypacText>
					</div>
				</div>

				<div className="flex border-0 border-b border-solid border-gray-10">
					<div className="flex flex-col gap-5 px-10 py-5">
						<SypacText variant="overline-normal-large">
							<p>
								<T keyName="modalAddProduct.productPhoto">Product photo</T>
							</p>
						</SypacText>

						<div className="flex gap-5">
							{file ? (
								<div
									style={{
										backgroundImage: `url(${file.fileUrl || file.file.url})`,
									}}
									className="relative w-[120px] h-[120px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
									onClick={() => setDeletePhoto(true)}
									onMouseEnter={() => setPhotoHover(true)}
									onMouseLeave={() => setPhotoHover(false)}
									role="img"
								>
									{photoHover ? (
										<div className="absolute top-[8px] right-[8px] w-[24px] h-[24px] flex justify-center items-center bg-red rounded-md z-10">
											<NewTrash color="#FFFFFF" />
										</div>
									) : null}
									{photoHover ? (
										<div className="absolute w-[119px] h-[119px] flex bg-transparent/20 z-0 rounded-10 box-border" />
									) : null}
								</div>
							) : null}

							<div
								onDragEnter={handleDrag}
								onDragLeave={handleDrag}
								onDragOver={handleDrag}
								onDrop={handleDrop}
								className="flex gap-[10px] w-[120px] h-[120px] border border-solid border-gray-10 bg-alabaster p-0 rounded-10 transition hover:border-gray-40 box-border"
							>
								<input
									type="file"
									id="input-file-upload"
									className="hidden"
									multiple={true}
									accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
									onChange={handleChange}
								/>
								<label
									htmlFor="input-file-upload"
									className="w-full h-full flex flex-col gap-[10px] items-center justify-center cursor-pointer"
								>
									<span className="flex scale-[1.1]">
										<PhotoFrame color="#454545" />
									</span>
									<SypacText variant="overline-normal-large">
										<p className="text-sm text-gray-80">
											<T keyName="edit.editPhoto">Edit photo</T>
										</p>
									</SypacText>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-5 mt-6 px-10">
					<div className="flex flex-col gap-1 w-full">
						<SypacText variant="body-regular-medium">
							<p className="text-xs text-primary-dark-gray">
								<T keyName="modalAddProduct.countryStore">Country store</T>
								<span className="text-red">*</span>
							</p>
						</SypacText>

						<div ref={countryRef} className="relative">
							<div
								className={`h-[44px] flex justify-between items-center box-border border border-solid bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
									countryDropdownOpen
										? 'border-cornflower-blue'
										: formik.touched.countryStore && formik.errors.countryStore
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30'
								}`}
								onClick={() => setCountryDropdownOpen(!countryDropdownOpen)}
							>
								<SypacText variant="body-normal-small">
									<p
										className={`text-base ${
											formik.values.countryStore
												? 'text-gray-80'
												: 'text-gray-22'
										}`}
									>
										{formik.values.countryStore ? (
											<div className="flex gap-3 items-center">
												<Flag
													className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
													code={
														COUNTRIES.find(
															(country) =>
																country['alpha-3'] ===
																formik.values.countryStore,
														)?.value
													}
													width={24}
													height={16}
												/>
												<SypacText
													variant="body-regular-medium"
													className="mt-1"
												>
													<p className="text-gray-80 max-w-[148px] truncate">
														{
															COUNTRIES.find(
																(country) =>
																	country['alpha-3'] ===
																	formik.values.countryStore,
															)?.label
														}
													</p>
												</SypacText>
											</div>
										) : (
											<T keyName="modalAddProduct.selectCountryStore">
												Select store country
											</T>
										)}
									</p>
								</SypacText>

								<span
									className={`flex my-auto transition-all mr-[2px] ${
										countryDropdownOpen ? 'transform rotate-90' : ''
									}`}
								>
									<ChevronRightIcon />
								</span>
							</div>

							{countryDropdownOpen && (
								<div className="absolute top-[48px] w-full h-fit flex flex-col p-1 border border-solid border-gray-10 rounded-lg shadow-dropdown bg-white z-30 box-border">
									{COUNTRIES.filter(
										(country) =>
											country.value === 'PL' || country.value === 'MD',
									).map((country) => (
										<div
											key={country.value}
											className={`no-underline flex items-center gap-3 border-none p-3 rounded-md cursor-pointer hover:bg-gray-10/90 ${
												country['alpha-3'] === formik.values.countryStore
													? 'bg-gray-10-opacity-50'
													: 'bg-white'
											}`}
											onClick={() => {
												handleCountrySelection(country['alpha-3']);
											}}
										>
											<Flag
												className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
												code={country.value}
												width={24}
												height={16}
											/>
											<SypacText
												variant="body-regular-medium"
												className="mr-auto"
											>
												<p className="text-gray-80 max-w-[148px] truncate">
													{country.label}
												</p>
											</SypacText>
										</div>
									))}
								</div>
							)}

							{formik.touched.countryStore && formik.errors.countryStore ? (
								<span className="input-error">
									{formik.errors.countryStore.toString()}
								</span>
							) : null}
						</div>
					</div>
					<div className="flex flex-col gap-1 w-full">
						<SypacText variant="body-regular-medium">
							<p className="text-xs text-primary-dark-gray">
								<T keyName="modalAddProduct.productGroup">Product group</T>
								<span className="text-red">*</span>
							</p>
						</SypacText>

						<div ref={groupRef} className="relative">
							<div
								className={`h-[44px] flex justify-between items-center box-border border border-solid bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
									groupDropdownOpen
										? 'border-cornflower-blue'
										: formik.touched.productGroup && formik.errors.productGroup
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30'
								}`}
								onClick={() => setGroupDropdownOpen(!groupDropdownOpen)}
							>
								<SypacText variant="body-normal-small">
									<p
										className={`text-base ${
											formik.values.productGroup
												? 'text-gray-80'
												: 'text-gray-22'
										}`}
									>
										{formik.values.productGroup ? (
											groups?.items.find(
												(group) => group.id === formik.values.productGroup,
											)?.name
										) : (
											<T keyName="modalAddProduct.selectProductGroup">
												Select product group
											</T>
										)}
									</p>
								</SypacText>

								<span
									className={`flex my-auto transition-all mr-[2px] ${
										groupDropdownOpen ? 'transform rotate-90' : ''
									}`}
								>
									<ChevronRightIcon />
								</span>
							</div>

							{groupDropdownOpen && (
								<div className="absolute top-[48px] w-full h-fit max-h-[238px] flex flex-col p-1 border border-solid border-gray-10 rounded-lg shadow-dropdown bg-white z-30 box-border overflow-x-hidden overflow-y-auto">
									{groups?.items.map((group) => (
										<div
											key={group.id}
											className={`no-underline flex items-center gap-3 border-none p-3 rounded-md cursor-pointer hover:bg-gray-10/90 ${
												group.id === formik.values.productGroup
													? 'bg-gray-10-opacity-50'
													: 'bg-white'
											}`}
											onClick={() => {
												handleGroupSelection(group.id);
											}}
										>
											<div
												style={{
													backgroundImage: `url(${group.imageUrl})`,
												}}
												className="w-[52px] h-[52px] bg-no-repeat bg-cover bg-center rounded-10"
												role="img"
											/>

											<SypacText
												variant="body-regular-medium"
												className="mr-auto"
											>
												<p className="text-gray-80">{group.name}</p>
											</SypacText>
										</div>
									))}
								</div>
							)}

							{formik.touched.productGroup && formik.errors.productGroup ? (
								<span className="input-error">
									{formik.errors.productGroup.toString()}
								</span>
							) : null}
						</div>
					</div>
					<div className="flex flex-col gap-1 w-full">
						<SypacText variant="body-regular-medium">
							<p className="text-xs text-primary-dark-gray">
								<T keyName="modalAddProduct.productCategory">
									Product category
								</T>
								<span className="text-red">*</span>
							</p>
						</SypacText>

						<div ref={categoryRef} className="relative">
							<div
								className={`h-[44px] flex justify-between items-center box-border border border-solid bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
									categoryDropdownOpen
										? 'border-cornflower-blue'
										: formik.touched.productCategory &&
										  formik.errors.productCategory
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30'
								}`}
								onClick={() => setCategoryDropdownOpen(!categoryDropdownOpen)}
							>
								<SypacText variant="body-normal-small">
									<p
										className={`text-base ${
											formik.values.productCategory
												? 'text-gray-80'
												: 'text-gray-22'
										}`}
									>
										{formik.values.productCategory ? (
											categories?.items.find(
												(category) =>
													category.id === formik.values.productCategory,
											)?.name
										) : (
											<T keyName="modalAddProduct.selectProductCategory">
												Select product category
											</T>
										)}
									</p>
								</SypacText>

								<span
									className={`flex my-auto transition-all mr-[2px] ${
										categoryDropdownOpen ? 'transform rotate-90' : ''
									}`}
								>
									<ChevronRightIcon />
								</span>
							</div>

							{categoryDropdownOpen && (
								<div className="absolute top-[48px] w-full h-fit max-h-[214px] flex flex-col border border-solid border-gray-10 rounded-lg shadow-dropdown bg-white z-30 box-border overflow-hidden">
									<div className="w-full flex flex-col py-1 pl-1 pr-3 overflow-y-scroll">
										{categories ? (
											categories.items.map((category) => (
												<div
													key={category.id}
													className={`no-underline flex items-center gap-3 border-none p-3 rounded-md cursor-pointer hover:bg-gray-10/90 -mr-[7px] ${
														category.id === formik.values.productCategory
															? 'bg-gray-10-opacity-50'
															: 'bg-white'
													}`}
													onClick={() => {
														handleCategorySelection(category.id);
													}}
												>
													<div
														style={{
															backgroundImage: `url(${category.imageUrl})`,
														}}
														className="w-[52px] h-[52px] bg-no-repeat bg-cover bg-center rounded-10"
														role="img"
													/>

													<SypacText
														variant="body-regular-medium"
														className="mr-auto"
													>
														<p className="text-gray-80">{category.name}</p>
													</SypacText>
												</div>
											))
										) : (
											<div className="h-[40px] flex items-center justify-center">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">
														Please select a group first.
													</p>
												</SypacText>
											</div>
										)}
									</div>
								</div>
							)}

							{formik.touched.productCategory &&
							formik.errors.productCategory ? (
								<span className="input-error">
									{formik.errors.productCategory.toString()}
								</span>
							) : null}
						</div>
					</div>
					<div className="flex gap-4">
						<SypacInput error={!!(formik.touched.type && formik.errors.type)}>
							<SypacText className="mb-1" variant="overline-normal-large">
								<p>
									<T keyName="modalAddProduct.productType">Product type</T>
								</p>
							</SypacText>
							<input
								className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
									formik.touched.type && formik.errors.type
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
								}`}
								name="type"
								type="text"
								placeholder={t(
									'modalAddProduct.enterProductType',
									'Enter type',
								)}
								value={formik?.values.type}
								onChange={handleFormFieldChange}
							/>
							{formik?.touched.type && formik?.errors.type ? (
								<span className="input-error">
									{formik.errors.type.toString()}
								</span>
							) : null}
						</SypacInput>
						<SypacInput error={!!(formik.touched.size && formik.errors.size)}>
							<SypacText className="mb-1" variant="overline-normal-large">
								<p>
									<T keyName="modalAddProduct.format">Format</T>
								</p>
							</SypacText>
							<input
								className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
									formik.touched.size && formik.errors.size
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
								}`}
								name="size"
								type="text"
								placeholder={t(
									'modalAddProduct.enterProductFormat',
									'Enter format',
								)}
								value={formik?.values.size}
								onChange={handleFormFieldChange}
							/>
							{formik?.touched.size && formik?.errors.size ? (
								<span className="input-error">
									{formik.errors.size.toString()}
								</span>
							) : null}
						</SypacInput>
					</div>
					<div className="flex flex-col gap-3 col-span-2">
						<SypacInput
							className="w-full"
							error={
								!!(formik.touched.productName && formik.errors.productName)
							}
						>
							<SypacText className="mb-1" variant="overline-normal-large">
								<p>
									<T keyName="modalAddProduct.productName">Product name</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
									formik.touched.productName && formik.errors.productName
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
								}`}
								name="productName"
								type="text"
								placeholder={t(
									'modalAddProduct.enterProductName',
									'Enter product name',
								)}
								value={formik?.values.productName}
								onChange={handleFormFieldChange}
							/>
							{formik?.touched.productName && formik?.errors.productName ? (
								<span className="input-error">
									{formik.errors.productName.toString()}
								</span>
							) : null}
						</SypacInput>

						<SypacButton
							className="self-start"
							variant="secondary"
							size="small"
						>
							<button
								type="button"
								className="h-[28px] p-0 gap-3 border-0 group"
								onClick={() => {
									setTranslateType('name');
									setAddTranslate(true);
								}}
							>
								<span className="flex scale-[1.3]">
									<EyeIcon hoverEffect={true} />
								</span>
								<SypacText variant="body-regular-medium">
									<p className="text-primary-violet transition group-hover:text-primary-violet/70 mt-1">
										<T keyName="productDetails.showTranslates">
											Show translates
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>

					{/* <div className="flex flex-col gap-1 w-full">
					<SypacText variant="overline-regular-large" className="mb-1">
						<p className="text-gray-80">
							<T keyName="productEdit.productType">Product type</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>
					<SypacDropdown
						name="sourceProductId"
						placeholder={t(
							'productEdit.selectProductType',
							'Select Product Type',
						)}
						callback={(e) => {
							formik.setFieldValue('sourceProductId', e?.detail?.value);
						}}
						ref={dropDownRef}
						onClick={removeClass}
					>
						{products?.items?.map((product) => {
							const formikValue =
								(typeof formik.values.sourceProductId === 'string'
									? parseInt(formik.values.sourceProductId)
									: formik.values.sourceProductId) || undefined;

							const label = `${product.name || ''}${
								product.type ? `, ${product.type}` : ''
							}${product.size ? `: ${product.size}` : ''}`;
							return (
								// @ts-ignore
								<sypac-dropdown-option
									key={product.id}
									value={product.id}
									label={label}
									selected={product.id === formikValue}
								/>
							);
						})}
					</SypacDropdown>
					{formik.touched.sourceProductId && formik.errors.sourceProductId ? (
						<span className="input-error">
							<T keyName="productEdit.errorProductType">
								{formik.errors.sourceProductId}
							</T>
						</span>
					) : null}
				</div> */}
				</div>

				<div className="flex flex-col gap-5 mt-5 px-10">
					<SypacInput>
						<SypacText className="mb-1" variant="overline-normal-large">
							<p>
								<T keyName="modalAddProduct.productDescription">
									Product description
								</T>
							</p>
						</SypacText>
						<div className="w-full flex flex-col gap-[10px]">
							<textarea
								name="description"
								className="w-full box-border h-[183px] font-sans py-3.5 pl-3 border border-solid border-gray-20 hover:border-gray-40/75 focus:border-[#5682fa] rounded-xl placeholder:text-gray-40 placeholder:text-base resize-none outline-none"
								rows={7}
								maxLength={1500}
								placeholder={t(
									'modalAddProduct.enterProductDescription',
									'Enter product description',
								)}
								onChange={handleTextareaChange}
								onBlur={formik.handleBlur}
								value={formik.values.description}
							/>
							<div className="flex items-center justify-between">
								<SypacButton
									className="self-start"
									variant="secondary"
									size="small"
								>
									<button
										type="button"
										className="h-[28px] p-0 gap-3 border-0 group"
										onClick={() => {
											setTranslateType('description');
											setAddTranslate(true);
										}}
									>
										<span className="flex scale-[1.3]">
											<EyeIcon hoverEffect={true} />
										</span>
										<SypacText variant="body-regular-medium">
											<p className="text-primary-violet transition group-hover:text-primary-violet/70 mt-1">
												<T keyName="productDetails.showTranslates">
													Show translates
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<div className="w-fit h-fit flex bg-gray-10 text-xs text-gray-40 rounded-2xl px-[5px] py-[1px]">{`${charCount} / 1500`}</div>
							</div>
						</div>
					</SypacInput>
				</div>

				{formEdited ? (
					<div className="w-[714px] fixed bottom-0 px-10 py-5 bg-white border-0 border-t border-solid border-t-gray-10 animate-slide-up box-border">
						<div className="flex gap-3">
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									className="w-full border-red text-red px-5 py-2 rounded-lg"
									onClick={onClose}
								>
									<SypacIcon
										iconName="Close Circle"
										size="custom"
										width="24px"
										height="24px"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="productDetails.cancel">Cancel</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									type="submit"
									className="w-full border-mountain-meadow text-mountain-meadow px-5 py-2 rounded-lg"
								>
									<SypacIcon
										iconName="Check Circle"
										size="custom"
										width="24px"
										height="24px"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="productDetails.save">Save</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>
				) : null}
			</form>
			<ModalDeletePhoto
				isOpen={deletePhoto}
				onClose={() => setDeletePhoto(false)}
				handleDelete={handleDeleteFile}
				url={product.photoUrl!}
			/>
			<ModalAddTranslates
				isOpen={addTranslate}
				onClose={closeTransModal}
				onSave={handleModalSave}
				type={translateType}
				currentTranslate="Product"
				currentValues={{
					name: formik.values.productName!,
					type: formik.values.type!,
					description: formik.values.description!,
				}}
				translations={product?.translations?.map(({ language, name }) => ({
					language,
					value: name,
				}))}
			/>
		</>
	);
};

export default Edit;
