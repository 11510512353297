import React, { useEffect, useState } from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import {
	SypacAvatar,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import { InvoiceLineTableInterface } from './interfaces/InvoiceLineTable.interface';
import {
	InvoiceInterface,
	InvoiceLineInterface,
} from '../PaymentsTable/interfaces/Payment.interface';
import { TableStatusEnum } from '../TableStatus/TableStatus.enum';
import dayjs from 'dayjs';
import TableStatus from '../TableStatus/TableStatus';
import { NumericFormat } from 'react-number-format';
import { T, useTranslate } from '@tolgee/react';
import NoResults from './components/NoResults';
import { formatDate } from '../../utils/time.util';
import HashBox from '../../assets/HashBox';
import Tons from '../../assets/Tons';
import { PaymentTypeAction } from '../PaymentsTable/interfaces/PaymentStatus.interface';
import Download from '../../assets/Download';
import EyeIcon from '../../assets/EyeIcon';
import { Tooltip } from 'react-tooltip';
import EditPenIcon from '../../assets/EditPenIcon';
import Upload from '../../assets/Upload';
import { PaymentNotesStatusesEnum } from '../../services/billing.service';

export const InvoiceLinesTable = ({
	rows,
	rowClick,
	search,
	target,
	currentTab,
	additionalClick,
}: InvoiceLineTableInterface) => {
	const { t } = useTranslate();
	const [keyRows, setRows] = useState<InvoiceLineInterface[]>([]);

	useEffect(() => {
		const newRows = rows
			? rows?.map((row: InvoiceLineInterface) => ({
					...row,
					key: row?.id,
			  }))
			: [];
		setRows(newRows);
	}, [rows]);

	const actionClick = (type: string, payment: InvoiceInterface | number) => {
		switch (type) {
			case PaymentTypeAction.view_order:
				return (
					additionalClick &&
					additionalClick(payment, PaymentTypeAction.view_order)
				);
			case PaymentTypeAction.download:
				return (
					additionalClick &&
					additionalClick(payment, PaymentTypeAction.download)
				);
		}
	};

	const getColumns = () => {
		switch (target) {
			case 'producer':
				return columnsProducer(rowClick, actionClick, search);
			default:
				return columnsTransporter(rowClick, search);
		}
	};

	const columnsTransporter = (
		rowClick: (payment: InvoiceLineInterface) => void,
		search?: string,
	) => [
		{
			title: t('invoiceLinesTableTransporter.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceLineInterface) {
				const isOverdue =
					value?.invoice?.status === TableStatusEnum.PENDING &&
					dayjs(value?.invoice?.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value?.invoice?.dueDate, 'day')
					: dayjs(value?.invoice?.dueDate).diff(dayjs(), 'day');
				const status = isOverdue
					? TableStatusEnum.OVERDUE
					: value?.invoice?.status;
				let isPartial =
					value?.invoice?.status === TableStatusEnum.PENDING &&
					!!value?.invoice?.payments?.length;
				isPartial =
					value?.invoice?.status === TableStatusEnum.PAID
						? value?.invoice?.payments?.length > 0
						: isPartial;

				return (
					<div
						className="flex items-center justify-start h-full w-fit py-4 px-3"
						onClick={() => rowClick(value)}
					>
						<TableStatus
							days={days}
							state={status ? status : ''}
							isPartial={isPartial}
							insideOut={true}
						/>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableTransporter.createdDate', 'Created'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceLineInterface) {
				const date = formatDate(value.createdAt);
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.amountOpen', 'Amount open'),
			dataIndex: '',
			className: 'text-left',
			key: 'totalAmount',
			render(value: InvoiceLineInterface) {
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value?.lineTotal?.toFixed(2) || '0')}
									suffix={
										value?.invoice?.countryCode === 'MD' ? ' MDL' : ' PLN'
									}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableTransporter.productName', 'Product name'),
			dataIndex: '',
			className: 'text-left',
			key: 'description',
			render(value: InvoiceLineInterface) {
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								<T keyName="invoiceLinesTable.transportService">
									Transport service
								</T>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableTransporter.dispatcher', 'Dispatcher'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceLineInterface) {
				const user = value?.order?.assigneeUser;

				return user && user.name ? (
					<div
						className="flex gap-4 items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacAvatar size="sm" initials={user.email || user?.name}>
							{user?.avatarUrl ? (
								<img src={user?.avatarUrl} alt={user.email || user?.name} />
							) : null}
						</SypacAvatar>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{user.name}</p>
						</SypacText>
					</div>
				) : null;
			},
		},
	];

	const columnsProducer = (
		rowClick: (payment: InvoiceLineInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface | number) => void,
		search?: string,
	) => [
		{
			title: t('invoiceLinesTableProducer.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceLineInterface) {
				const { invoice } = value || {};
				const today = dayjs();
				const isOverdue =
					invoice?.status === TableStatusEnum.PENDING &&
					dayjs(invoice?.dueDate).isBefore(today);
				const days =
					dayjs(invoice?.dueDate).diff(today, 'day') * (isOverdue ? -1 : 1);
				const status = isOverdue ? TableStatusEnum.OVERDUE : invoice?.status;
				const isPartial =
					invoice?.payments?.length! > 0 &&
					(invoice?.status === TableStatusEnum.PENDING ||
						invoice?.status === TableStatusEnum.PAID);
				const date = formatDate(invoice?.createdAt);

				return (
					<div
						className="w-fit h-full flex flex-col gap-[10px] justify-start py-[8.5px] px-5"
						onClick={() => rowClick(value)}
					>
						<TableStatus
							days={days}
							state={status!}
							isPartial={isPartial}
							insideOut={true}
						/>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: InvoiceLineInterface) {
				return (
					<div
						className="h-full flex items-center gap-3 px-3"
						onClick={() => rowClick(value)}
					>
						<span className="scale-[1.2]">
							<HashBox />
						</span>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.id}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableProducer.productName', 'Product name'),
			dataIndex: '',
			className: 'text-left',
			key: 'description',
			render(value: InvoiceLineInterface) {
				const { order } = value || {};
				const product = order?.product;
				const htmlName = search
					? product?.name
							?.toString()
							.replace(
								new RegExp(search, 'gi'),
								(match) => `<span class="text-texas-rose">${match}</span>`,
							)
					: product?.name?.toString();
				const productSize = [
					product?.type,
					product?.size && `(${product.size})`,
				]
					.filter(Boolean)
					.join(' ');
				const htmlSize = search
					? productSize?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productSize;

				return (
					<div
						className="h-full flex gap-[10px] px-4"
						onClick={() => rowClick(value)}
					>
						<div
							style={{
								backgroundImage: `url(${product?.photoUrl})`,
							}}
							className="flex flex-none w-[54px] h-[54px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10"
						/>
						<div className="flex flex-col gap-2 justify-center w-full">
							<section className="flex items-center gap-4">
								<SypacText variant="body-regular-medium">
									<p dangerouslySetInnerHTML={{ __html: htmlName! }} />
								</SypacText>
								{productSize.trim() ? (
									<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
								) : null}
								<SypacText variant="body-regular-medium">
									<p dangerouslySetInnerHTML={{ __html: htmlSize }} />
								</SypacText>
							</section>

							<div className="flex gap-[10px]">
								<Tons />
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										{product?.quantity} <T keyName="ordersTable.tons">tons</T>
									</p>
								</SypacText>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.receivable', 'Receivable'),
			dataIndex: '',
			className: 'text-left',
			key: 'totalAmount',
			render(value: InvoiceLineInterface) {
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value?.lineTotal?.toFixed(2) || '0')}
									suffix={
										value?.invoice?.countryCode === 'MD' ? ' MDL' : ' PLN'
									}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableProducer.user', 'User'),
			dataIndex: '',
			className: 'text-center',
			key: 'createdBy',
			render(value: InvoiceLineInterface) {
				const user = value?.order?.assigneeUser;

				return user && user.name ? (
					<div
						className="flex gap-4 justify-center items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacAvatar size="sm" initials={user.email || user?.name}>
							{user?.avatarUrl ? (
								<img src={user?.avatarUrl} alt={user.email || user?.name} />
							) : null}
						</SypacAvatar>
					</div>
				) : null;
			},
		},
		{
			title: ' ',
			dataIndex: '',
			className: 'text-left',
			key: 'action',
			render(value: InvoiceInterface) {
				const orderId = value.order?.id;

				return (
					<div className="h-full flex justify-center items-center px-4">
						{value.invoice?.status ===
						PaymentNotesStatusesEnum.PENDING_INVOICE ? (
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									type="button"
									className="w-full h-[32px] flex gap-3 justify-center items-center px-3 bg-primary-violet border-0 rounded-lg p-0 cursor-pointer transition hover:bg-primary-violet/80"
									onClick={() =>
										actionClick(PaymentTypeAction.view_order, orderId!)
									}
								>
									<Upload />
									<SypacText variant="body-regular-small">
										<p className="text-base text-white mt-1">
											<T keyName="invoiceLinesTable.uploadInvoice">
												Upload invoice
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						) : value.invoice?.status ===
						  PaymentNotesStatusesEnum.UPLOADED_INVOICE ? (
							<div className="flex gap-[46.5px]">
								<>
									<SypacButton variant="secondary" size="small">
										<button
											type="button"
											data-tooltip-id="invoice-lines-table"
											data-tooltip-content={t(
												'paymentsWithdrawHistory.viewInvoice',
												'View Invoice',
											)}
											className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
											onClick={() =>
												actionClick(PaymentTypeAction.view_order, orderId!)
											}
										>
											<EyeIcon width={18} height={16} />
										</button>
									</SypacButton>
									<SypacButton variant="secondary" size="small">
										<button
											type="button"
											data-tooltip-id="invoice-lines-table"
											data-tooltip-content={t(
												'paymentsWithdrawHistory.editInvoice',
												'Edit Invoice',
											)}
											className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
											// onClick={() =>
											// 	actionClick(PaymentTypeAction.download, value)
											// }
										>
											<EditPenIcon color="#454545" width={16} height={16} />
										</button>
									</SypacButton>
									<SypacButton variant="secondary" size="small">
										<button
											type="button"
											data-tooltip-id="invoice-lines-table"
											data-tooltip-content={t(
												'paymentsWithdrawHistory.downloadInvoice',
												'Download Invoice',
											)}
											className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
											onClick={() =>
												actionClick(PaymentTypeAction.download, value)
											}
										>
											<Download />
										</button>
									</SypacButton>
								</>
							</div>
						) : (
							<div className="flex gap-[46.5px]">
								<>
									<SypacButton variant="secondary" size="small">
										<button
											type="button"
											data-tooltip-id="invoice-lines-table"
											data-tooltip-content={t(
												'paymentsWithdrawHistory.viewInvoice',
												'View Invoice',
											)}
											className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
											onClick={() =>
												actionClick(PaymentTypeAction.view_order, orderId!)
											}
										>
											<EyeIcon width={18} height={16} />
										</button>
									</SypacButton>
									<SypacButton variant="secondary" size="small">
										<button
											type="button"
											data-tooltip-id="invoice-lines-table"
											data-tooltip-content={t(
												'paymentsWithdrawHistory.editInvoice',
												'Edit Invoice',
											)}
											className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
											onClick={() =>
												actionClick(PaymentTypeAction.download, value)
											}
										>
											<EditPenIcon color="#454545" width={16} height={16} />
										</button>
									</SypacButton>
									<SypacButton variant="secondary" size="small">
										<button
											type="button"
											data-tooltip-id="invoice-lines-table"
											data-tooltip-content={t(
												'paymentsWithdrawHistory.downloadInvoice',
												'Download Invoice',
											)}
											className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
											onClick={() =>
												actionClick(PaymentTypeAction.download, value)
											}
										>
											<Download />
										</button>
									</SypacButton>
								</>
							</div>
						)}
					</div>
				);
			},
		},
	];

	return (
		<>
			<Table
				className={`w-full payment-table ${rows.length === 0 ? 'h-full' : ''} ${
					target === 'producer'
						? 'payment-table-producer'
						: 'payment-table-transporter'
				}`}
				columns={getColumns()}
				data={keyRows}
				emptyText={<NoResults currentTab={currentTab!} />}
			/>
			<Tooltip
				place="top"
				id="invoice-lines-table"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
				}}
			/>
		</>
	);
};
