import React, { useState } from 'react';
import Flag from 'react-world-flags';
import {
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { T, useTranslate } from '@tolgee/react';
import { SearchLocationProps } from '../clientSelectLocation.interface';
import { Geo } from '../../../../interfaces/geo.interface';

const SearchLocation: React.FC<SearchLocationProps> = ({
	onChange,
	locations,
	selectLocation,
	inputName,
}) => {
	const { t } = useTranslate();
	const [expandResults, setExpandResults] = useState<boolean>(false);

	const inputRef = useDetectClickOutside({
		onTriggered: () => setExpandResults(false),
	});

	const handleLocationSelect = (location: Geo) => {
		selectLocation(location);
		setExpandResults(false);
	};

	const LocationItem = ({
		location,
		onSelect,
	}: {
		location: Geo;
		onSelect: (location: Geo) => void;
	}) => (
		<div
			className="flex items-center mr-2 rounded-[5px] cursor-pointer hover:bg-gray-10-opacity-50"
			onClick={() => onSelect(location)}
		>
			<span className="mx-5">
				<Flag
					className="rounded-[3px]"
					code={location.countryCode.toLocaleUpperCase()}
					width={22}
					height={16}
				/>
			</span>
			<SypacText variant="body-normal-medium" className="my-[15px]">
				<span>
					{location.address?.city}, {location.address?.street} str.
					{location.address?.houseNumber &&
						` ${location.address.houseNumber},`}{' '}
					Zip {location.address?.postalCode}
				</span>
			</SypacText>
		</div>
	);

	const NoResults = () => (
		<div className="flex items-center rounded-lg cursor-pointer px-5 border border-solid border-gray-22 shadow-drop-shadow">
			<SypacText variant="body-normal-medium" className="my-[15px]">
				<p>
					<T keyName="searchLocationInput.noResults">No results found</T>
				</p>
			</SypacText>
		</div>
	);

	return (
		<div className="relative w-full group" ref={inputRef}>
			<SypacInput>
				<input
					name={inputName}
					autoComplete="off"
					type="text"
					className="pl-[62px] leading-[22px] w-full outline-none border border-gray-10 
            transition hover:border-gray-hover-1 focus:border-cornflower-blue py-4.5 
            rounded-10 text-gray-hover-2 placeholder:text-gray-hover-1"
					placeholder={t(
						'searchLocation.enterThePlaceWhere',
						'Enter the place where you would like to receive your products',
					)}
					onFocus={() => setExpandResults(true)}
					onChange={(e) => onChange(e.target.value)}
				/>
			</SypacInput>

			<span
				className={`absolute top-3 left-5 ${
					expandResults ? 'stroke-cornflower-blue' : 'stroke-gray-80'
				}`}
			>
				<SypacIcon
					icon-name="Map Point"
					className="text-gray-40"
					size="custom"
					width="30px"
					height="30px"
				/>
			</span>

			{expandResults && (
				<div
					className="flex-col absolute top-[70px] w-full shadow-drop-shadow-expanded 
          border border-solid border-gray-10 rounded-lg max-h-72 overflow-hidden bg-white z-50"
				>
					{locations?.length ? (
						<nav className="flex flex-col w-full p-3 shadow-drop-shadow-expanded overflow-y-scroll">
							{locations.map((location) => (
								<LocationItem
									key={location.locationId}
									location={location}
									onSelect={handleLocationSelect}
								/>
							))}
						</nav>
					) : (
						<NoResults />
					)}
				</div>
			)}
		</div>
	);
};

export default SearchLocation;
