export interface PhoneDto {
	phoneNumber: string;
	isViber: boolean;
	isTelegram: boolean;
	isWhatsapp: boolean;
}

export interface ProfileInterface {
	id: string;
	uid: string;
	firstName: string;
	lastName: string;
	name?: string;
	email: string;
	phoneNumber?: PhoneDto;
	secondaryPhoneNumber?: PhoneDto;
	countryCode: string;
	secondaryEmail?: string;
	photoUrl?: string;
}
export interface UsersInterface {
	status: UserStatus | string;
	uid: string;
	country?: string;
	registered?: string;
	name: string;
	seen?: string;
	assigned?: string;
	avatarUrl?: string;
	email: string;
	company?: Company;
	role?: string;
	countryCode?: string;
	firstName?: string;
	id?: string;
	lastName?: string;
	phoneNumber?: PhoneDto;
	secondaryPhoneNumber?: PhoneDto;
	secondaryEmail?: string;
	profile?: ProfileInterface;
}

export interface UsersList {
	items: UsersInterface[];
	count: number;
}

export interface Company {
	id?: string;
	name?: string;
	industry?: string;
}

export enum UserStatus {
	total = 'total',
	AWAIT_EMAIL_CONFIRMATION = 'awaitEmailConfirmation',
	APPROVED = 'approved',
	DISABLED = 'disabled',
	ASSIGNED = 'assigned',
	UNASSIGNED = 'unassigned',
}
