import { IconInterface } from '../interfaces/icon.interface';

const Document = (props: IconInterface) => {
	const { color = '#5682FA', width = 23, height = 26 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 23 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 10.6C1 6.07452 1 3.81178 2.36684 2.40589C3.73367 1 5.93356 1 10.3333 1H12.6667C17.0664 1 19.2663 1 20.6332 2.40589C22 3.81178 22 6.07452 22 10.6V15.4C22 19.9255 22 22.1882 20.6332 23.5941C19.2663 25 17.0664 25 12.6667 25H10.3333C5.93356 25 3.73367 25 2.36684 23.5941C1 22.1882 1 19.9255 1 15.4V10.6Z"
				stroke={color}
			/>
			<path d="M7 13H16.6" stroke={color} strokeLinecap="round" />
			<path d="M7 8.19922H16.6" stroke={color} strokeLinecap="round" />
			<path d="M9 17.8008H15" stroke={color} strokeLinecap="round" />
		</svg>
	);
};

export default Document;
