import React from 'react';
import { useTable } from 'react-table';

import './table.css';

export interface InvoiceTableProps {
	columns: any;
	data: any;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({ columns, data }) => {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data });

	return (
		<table className="result-table" {...getTableProps()}>
			<thead>
				{headerGroups.map((headerGroup: any, index: number) => (
					<tr {...headerGroup.getHeaderGroupProps()} key={index}>
						{headerGroup.headers.map((column: any) => {
							return (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							);
						})}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row: any, index: number) => {
					prepareRow(row);
					return (
						<tr {...row.getRowProps()} key={index}>
							{row.cells.map((cell: any, i: number) => {
								return (
									<td {...cell.getCellProps()} key={i}>
										{cell.render('Cell')}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};
export default InvoiceTable;
