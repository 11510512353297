import { IconInterface } from '../interfaces/icon.interface';

const Upload = (props: IconInterface) => {
	const { color = '#FFFFFF', width = 15, height = 16 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 15 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.5 10.334C0.5 12.5339 0.5 13.6338 1.18342 14.3172C1.86684 15.0007 2.96678 15.0007 5.16667 15.0007H9.83333C12.0332 15.0007 13.1332 15.0007 13.8166 14.3172C14.5 13.6338 14.5 12.5339 14.5 10.334"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.49978 11.1111V1M7.49978 1L10.6109 4.40278M7.49978 1L4.38867 4.40278"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Upload;
