import React, { useContext, useEffect, useRef } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { ModalSuccessPaymentProps } from './ModalSuccessPayment.interface';
import PaymentSuccess from '../../assets/PaymentSuccess';
import { T } from '@tolgee/react';
import Close from '../../assets/Close';
import ArrowToRight from '../../assets/ArrowToRight';
import ClockSmall from '../../assets/ClockSmall';
import { OrderContext } from '../../context/OrderContext/order.context';
import StatusPendingPayment from '../../assets/StatusPendingPayment';

const ModalSuccessPayment: React.FC<ModalSuccessPaymentProps> = ({
	isOpen,
	onClose,
}) => {
	const { setAdditional } = useContext(OrderContext);
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<div className="modal-overlay" onClick={onClose}>
			<div
				className="w-[471px] bg-white rounded-xl"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<div className="flex flex-col">
					<div className="flex items-center justify-between p-3 border-0 border-b border-solid border-gray-10">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalSuccessPayment.paymentProcessed">
									Payment processed
								</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<div className="px-3 py-10">
						<PaymentSuccess />
					</div>

					<div className="flex flex-col items-start justify-center px-3 pb-3 gap-6">
						<section className="flex flex-col gap-3">
							<SypacText variant="heading-5">
								<p className="text-gray-80">
									<T keyName="modalSuccessPayment.invoiceConfirmed">
										Invoice Confirmed
									</T>
								</p>
							</SypacText>
							{/* <SypacText variant="body-regular-small">
							<p className="text-gray-40 text-left">
								<T keyName="modalSuccessPayment.successfullyConfirmedInvoice">
									You have successfully confirmed the uploaded invoice.
								</T>
							</p>
						</SypacText> */}
						</section>

						<div className="w-full flex flex-col gap-[10px] p-[10px] border border-dashed border-gray-10 rounded-10 bg-alabaster box-border whitespace-nowrap">
							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-40">
									<T keyName="modalSuccessPayment.theTransactionStatus">
										The transaction status will now change from:
									</T>
								</p>
							</SypacText>

							<div className="flex gap-2 items-center">
								<ClockSmall color="#FFB352" secondColor="#454545" />
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-80 mt-[2px]">
										Pending Invoice Confirmation
									</p>
								</SypacText>
								<ArrowToRight color="#A2A2A2" width={39} />
								<StatusPendingPayment width={20} height={18} />
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-80 mt-[2px]">
										Pending Payment
									</p>
								</SypacText>
							</div>
						</div>
					</div>

					<div className="w-full flex justify-center p-3 border-0 border-t border-solid border-gray-10 box-border">
						<SypacButton
							variant="secondary"
							size="small"
							className="w-full"
							onClick={onClose}
						>
							<button
								type="reset"
								className="w-full h-[42px] flex items-center justify-center border-0 bg-primary-violet rounded-lg transition hover:bg-primary-violet/80"
							>
								<SypacText variant="body-regular-medium">
									<p className="text-white">
										<T keyName="modalSuccessPayment.gotIt">Got it</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default ModalSuccessPayment;
