import { IconInterface } from '../interfaces/icon.interface';

const InvoicingApprove = (props: IconInterface) => {
	const { color = '#20AC93' } = props;

	return (
		<svg
			width="14"
			height="16"
			viewBox="0 0 14 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.53231 15.2836L7.875 15.6477L7.53231 15.2836C7.22582 15.5721 6.77418 15.5721 6.46769 15.2836L6.125 15.6477L6.46769 15.2836C5.77615 14.6328 4.72385 14.6328 4.03231 15.2836L4.375 15.6477L4.03231 15.2836C3.72583 15.5721 3.27417 15.5721 2.96769 15.2836L2.625 15.6477L2.96769 15.2836L2.59195 14.93C2.11469 14.4808 1.38531 14.4808 0.908052 14.93C0.826704 15.0066 0.73832 15.0117 0.658347 14.9737C0.575053 14.9341 0.5 14.8437 0.5 14.6994V3.49605C0.5 2.50315 0.505984 2.12356 0.599175 1.83162C0.787341 1.24214 1.21986 0.792252 1.75467 0.600844C2.01591 0.507349 2.35786 0.5 3.30168 0.5H10.6983C11.6421 0.5 11.9841 0.507349 12.2453 0.600844C12.7801 0.792253 13.2127 1.24214 13.4008 1.83161C13.494 2.12356 13.5 2.50315 13.5 3.49605V14.6994C13.5 14.8437 13.4249 14.9341 13.3417 14.9737C13.2617 15.0117 13.1733 15.0066 13.0919 14.93C12.6147 14.4808 11.8853 14.4808 11.4081 14.93L11.0323 15.2836C10.7258 15.5721 10.2742 15.5721 9.96769 15.2836C9.27615 14.6328 8.22385 14.6328 7.53231 15.2836Z"
				stroke={color}
			/>
			<path
				d="M5.05859 6.72078L6.1697 8.00078L8.94748 4.80078"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M3.5 10.8008H10.5" stroke={color} strokeLinecap="round" />
		</svg>
	);
};

export default InvoicingApprove;
