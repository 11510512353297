import React from 'react';
import { SypacBox, SypacText } from '@sypac/component-library-react';
import { GroupCardProps } from './GroupCard.interface';

const GroupCard: React.FC<GroupCardProps> = ({
	imageUrl,
	title,
	description,
	onClick,
}) => {
	return (
		<SypacBox
			hover={true}
			className="box-border w-[268px] h-[260px] flex justify-between p-2.5 rounded-xl transition bg-white"
			onClick={() => {
				onClick && onClick();
			}}
		>
			<div
				style={{ backgroundImage: `url(${imageUrl})` }}
				className="w-full h-[174px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10"
				role="img"
				aria-label={title}
			/>

			<header className="flex flex-col gap-2">
				<SypacText variant="body-regular-medium">
					<p className="text-base text-gray-80">{title}</p>
				</SypacText>
				<SypacText variant="body-regular-medium">
					<p className="text-sm text-gray-40">{description}</p>
				</SypacText>
			</header>
		</SypacBox>
	);
};

export default GroupCard;
