import React from 'react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import Modal from 'react-modal';
import { T, useTranslate } from '@tolgee/react';
import Close from '../../../assets/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import { PREFERRED_PHONE_COUNTIES, settingsCountrySelect } from '../constants';
import Select from 'react-select';
import { AddUser, UsersServices } from '../../../services/users.services';
import { ModalManageMemberProps } from '../userSettings.interface';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import LoadingSpinner from '../../../assets/LoadingSpinner';

const ModalManageMember: React.FC<ModalManageMemberProps> = ({
	isOpen,
	onClose,
	modalAction,
	company,
	updateMyProfile,
	selectedUser,
}) => {
	const { t } = useTranslate();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstName: selectedUser?.firstName || '',
			lastName: selectedUser?.lastName || '',
			email: selectedUser?.email || '',
			phoneNumber: selectedUser?.phoneNumber?.phoneNumber || '',
		},
		onSubmit: async (values) => {
			try {
				const body: AddUser = {
					firstName: values.firstName,
					lastName: values.lastName,
					phoneNumber: values.phoneNumber!,
					email: values.email,
					lang: localStorage.getItem('lang') || 'pl',
				};

				await UsersServices.addNewUser(company?.companyTypes[0], {
					...body,
				});

				toastVariant('New user added successfully', false);
				onClose();
				formik.resetForm();
			} catch (error) {
				return toastVariant(
					`'Failed to add user. Please try again. ${error?.toString()!}`,
					true,
				);
			} finally {
				updateMyProfile();
			}
		},
		validationSchema: Yup.object({
			firstName: Yup.string().required('First name is required'),
			lastName: Yup.string().required('Last name is required'),
			email: Yup.string()
				.email('Email address must be an email')
				.required('Email is required'),
			phoneNumber: Yup.string().required('Phone number is required'),
		}),
	});

	const changePhone = (key: string, value: string) => {
		formik?.setFieldValue(key, value);
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="w-[660px] modal-inside outline-none box-border"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<form onSubmit={formik.handleSubmit} className="flex flex-col">
				<div className="flex items-center justify-between p-3">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							{modalAction.type === 'add' ? (
								<T keyName="modalManageMember.newCompanyUser">
									New company user
								</T>
							) : (
								<T keyName="modalManageMember.editMember">Edit member</T>
							)}
						</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>
				<div className="border-0 border-solid border-t-[1px] border-gray-10" />

				<div className="flex flex-col gap-3 p-3">
					<div className="flex gap-3">
						<SypacInput
							className="w-full"
							error={!!(formik.touched.firstName && formik.errors.firstName)}
						>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									<T keyName="modalManageMember.firstName">First name</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="firstName"
								type="text"
								placeholder={t(
									'modalManageMember.enterUserFirstName',
									'Enter user first name',
								)}
								value={formik?.values.firstName}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>

						<SypacInput
							className="w-full"
							error={!!(formik.touched.lastName && formik.errors.lastName)}
						>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									<T keyName="modalManageMember.lastName">Last name</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="lastName"
								type="text"
								placeholder={t(
									'modalManageMember.enterUserLastName',
									'Enter user last name',
								)}
								value={formik?.values.lastName}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>
					</div>
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
					<div className="flex gap-3">
						<SypacInput
							className="w-full"
							error={!!(formik.touched.email && formik.errors.email)}
						>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									<T keyName="modalManageMember.emailAddress">E-mail address</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="email"
								type="text"
								placeholder={t(
									'modalManageMember.enterEmailAddress',
									'Enter e-mail address',
								)}
								value={formik?.values.email}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>

						<SypacInput
							className="w-full"
							error={
								!!(formik.touched.phoneNumber && formik.errors.phoneNumber)
							}
						>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									<T keyName="userSettings.phoneNumber">Phone number</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<PhoneInput
								containerClass="border border-solid border-gray-20 rounded-lg hover:border-gray-30 focus-within:border-cornflower-blue focus-within:hover:border-cornflower-blue"
								placeholder={t(
									'userSettings.enterPhoneNumber',
									'Enter Phone number',
								)}
								value={formik?.values.phoneNumber}
								onChange={(phone) => changePhone('phoneNumber', phone)}
								preferredCountries={PREFERRED_PHONE_COUNTIES}
								country="pl"
							/>
						</SypacInput>
					</div>

					<SypacInput>
						<SypacText className="mb-[4px]" variant="overline-normal-large">
							<p>
								<T keyName="modalManageMember.userRole">User role</T>{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>
						<Select
							isDisabled={true}
							placeholder="Admin"
							name="userRole"
							onBlur={formik.handleBlur}
							menuPlacement={'auto'}
							classNamePrefix="dropdown"
							styles={settingsCountrySelect}
						/>
					</SypacInput>
				</div>

				<div className="flex w-full py-2.5 xl-2xl:py-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl mt-auto">
					<SypacButton
						variant="secondary"
						size="small"
						className="w-full pl-2.5 xl-2xl:pl-3"
					>
						<button
							type="reset"
							className="w-full py-2.5 rounded-lg"
							onClick={onClose}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalChangeRequest.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="primary"
						size="small"
						className="w-full pr-2.5 xl-2xl:pr-3"
					>
						<button
							type="submit"
							className={`w-full py-2.75 rounded-lg ${
								formik.isSubmitting ? 'bg-primary-violet/80 text-gray-500' : ''
							}`}
							disabled={formik.isSubmitting}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									{modalAction.type === 'add' ? (
										<T keyName="modalChangeRequest.sendRequest">
											Create new user
										</T>
									) : (
										<T keyName="companyInformationEditable.save">Save</T>
									)}
									{formik.isSubmitting && <LoadingSpinner />}
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</form>
		</Modal>
	);
};

export default ModalManageMember;
