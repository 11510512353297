import React, { useState } from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacTabButton,
	SypacTabs,
	SypacText,
} from '@sypac/component-library-react';
import dayjs from 'dayjs';
import { MutatingDots } from 'react-loader-spinner';
import { useGetCompanyById } from '../../../hooks/use-get-company-by-id';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import {
	CompanyResponse,
	CompanyService,
} from '../../../services/company.services';
import CompanyAssignees from '../../../components/CompanyAssignees/CompanyAssignees';
import { useGetCompanyUsers } from '../../../hooks/use-get-company-users';
import { listDifference } from '../../../utils/list-diff.util';
import ShortCompanyBox from './components/ShortCompanyBox';
import { CompanyData, CompanyDetailsProps } from './comanyDetails.interface';
import CompanyInformation from '../../../components/CompanyInformation/CompanyInformation';
import { CompanyInfoData } from '../../../components/CompanyInformation/CompanyInformation.interface';
import CompanyInformationEditable from '../../../components/CompanyInformationEditable/CompanyInformationEditable';
import { CompanyEditableData } from '../../../components/CompanyInformationEditable/CompanyInformationEditable.interface';
import { CompanyUsers } from './components/Tabs/CompanyUsers';
import { CompanyFiles } from './components/Tabs/CompanyFiles';
import { StatsTAB } from './components/Tabs/StatsTab';
import { WalletTAB } from './components/Tabs/WalletTab';
import { useGetInvoiceStatsByCompnayId } from '../../../hooks/use-get-stats';
import { T, useTranslate } from '@tolgee/react';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { FleetTab } from './components/Tabs/FleetTab';
import { FleetTruckIcon } from '../../../assets/FleetTruckIcon';
import { formatDate } from '../../../utils/time.util';

const CompanyDetails: React.FC<CompanyDetailsProps> = ({
	onClose,
	companyId,
	onVerification,
}) => {
	const { t } = useTranslate();
	const [currentTab, setCurrentTab] = useState<string>('overview');
	const [forceRefresh, setForceRefresh] = useState<number>(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [company, _, isLoading] = useGetCompanyById({
		id: companyId,
		refresh: forceRefresh,
	});
	const [stats] = useGetInvoiceStatsByCompnayId(companyId!, {});

	const changeAssignees = (newAssignees: string[] = []) => {
		let [toRemove, toAdd]: [string[], string[]] = [[], []];
		if (newAssignees.length) {
			[toRemove, toAdd] = listDifference(
				company?.assignees?.map((u) => u.assigneeUid) || [],
				newAssignees,
			);
		} else {
			toRemove = company?.assignees?.map((u) => u.assigneeUid) || [];
		}
		if (!toAdd.length && !toRemove.length) {
			return;
		}
		const promises: Promise<void>[] = [];
		toRemove.forEach((id) => {
			promises.push(CompanyService.unassignManagerToCompany(companyId, id));
		});
		toAdd.forEach((id) => {
			promises.push(CompanyService.assignManagerToCompany(companyId, id));
		});

		Promise.all(promises).finally(() => {
			toastVariant(t(`companyDetails.added`, 'Added'));
			setForceRefresh(Math.random());
		});
	};
	const [resp] = useGetCompanyUsers({
		companyId: 'self',
	});

	const shortCompanySection = (company: CompanyResponse) => {
		const shortCompanyData: CompanyData = {
			name: company?.name || '-',
			address: company?.registryAddress || '-',
			country: company?.countryCode || '-',
			targets: company?.companyTypes || [],
			creditLimit: parseFloat(stats?.creditLimit || '0'),
			terms: company?.paymentTerm,
			withdrawInterval: company?.paymentTerm?.toString() || '0',
			state: company?.status!,
			registrationDate: formatDate(company?.createdAt) || 'Unknown',
			companyId: company?.id!,
			lastOnline: 'Unknown',
			allowExcludedTransporter: company?.alowExcludedTransporter,
			allowUploadInvoice: company?.alowUploadInvoice,
			userOwner: company?.userOwner!,
			termsAccepted: company?.termsAccepted,
			updatedAt: formatDate(company?.updatedAt)!,
		};
		return <ShortCompanyBox data={shortCompanyData} />;
	};

	const companyInfo = (company: CompanyResponse) => {
		const infoCompanyData: CompanyInfoData = {
			companyName: company?.name || '-',
			registryNumber: company?.registrationNumber || '0',
			taxNumber: company?.taxNumber || '0',
			email: company?.contact?.email || '-',
			phoneNumber: company?.contact?.phone?.phoneNumber || '-',
			website: 'Unknown',
			address: company?.registryAddress || '-',
			country: company?.countryCode || '-',
			targets: company?.companyTypes,
			status: company?.status!,
			established: company?.establishedDate
				? formatDate(dayjs(company?.establishedDate).toISOString())
				: '-',
		};
		return <CompanyInformation data={infoCompanyData} />;
	};

	const companyEditable = (company: CompanyResponse) => {
		const infoCompanyData: CompanyEditableData = {
			companyName: company?.name || '-',
			vatNumber: company?.vatId || '-',
			registryNumber: company?.registrationNumber || '0',
			taxNumber: company?.taxNumber || '0',
			registryAddress: company?.registryAddress?.replace('-', ''),
			billingAddress: company?.billingAddress?.replace('-', ''),
			country: company?.countryCode || '-',
			companyTypes: company?.companyTypes || [],
			established: company?.establishedDate
				? dayjs(company?.establishedDate).toISOString()
				: dayjs(company?.createdAt).toISOString(),
			emailPrimary: company.contact?.email || '-',
			phoneNumberPrimary: company.contact?.phone?.phoneNumber || '-',
			primaryIsWhatsUp: company.contact?.phone?.isWhatsapp,
			primaryIsTelegram: company.contact?.phone?.isTelegram,
			primaryIsViber: company.contact?.phone?.isViber,
			emailSecondary: company.contact?.secondaryEmail || '-',
			phoneNumberSecondary: company.contact?.secondaryPhone?.phoneNumber || '-',
			secondaryIsWhatsUp: company.contact?.secondaryPhone?.isWhatsapp,
			secondaryIsTelegram: company.contact?.secondaryPhone?.isTelegram,
			secondaryIsViber: company.contact?.secondaryPhone?.isViber,
			allowExcludedTransporter: company?.alowExcludedTransporter,
			allowUploadInvoice: company?.alowUploadInvoice,
		};

		const handleOnSave = async (data: CompanyEditableData) => {
			await CompanyService.updateCompany(company.id!, {
				name: data.companyName,
				vatId: data.vatNumber,
				registrationNumber: data?.registryNumber?.toString(),
				taxNumber: data?.taxNumber?.toString(),
				registryAddress: data.registryAddress,
				billingAddress: data.billingAddress,
				companyTypes: data.companyTypes,
				countryCode: data.country,
				establishedDate: dayjs(data.established).toISOString(),
				email: data.emailPrimary,
				phone: {
					phoneNumber: data.phoneNumberPrimary,
					isViber: data.primaryIsViber,
					isTelegram: data.primaryIsTelegram,
					isWhatsapp: data.primaryIsWhatsUp,
				},
				secondaryEmail: data?.emailSecondary?.length
					? data.emailSecondary
					: undefined,
				secondaryPhone: {
					phoneNumber: data.phoneNumberSecondary,
					isViber: data.secondaryIsViber,
					isTelegram: data.secondaryIsTelegram,
					isWhatsapp: data.secondaryIsWhatsUp,
				},
				alowExcludedTransporter: data.allowExcludedTransporter,
				alowUploadInvoice: data.allowUploadInvoice,
			});
			setForceRefresh(Math.random());
		};

		return (
			<CompanyInformationEditable
				data={infoCompanyData}
				onSave={handleOnSave}
			/>
		);
	};

	const renderTabs = (company: CompanyResponse, currentTab: string) => {
		return (
			<>
				{currentTab === 'overview' && companyInfo(company)}
				{currentTab === 'company' && companyEditable(company)}
				{currentTab === 'users' && <CompanyUsers companyId={company.id!} />}
				{currentTab === 'documents' && (
					<CompanyFiles
						company={company}
						onFilesChange={() => {
							if (currentTab === 'documents') {
								setForceRefresh(Math.random());
							}
						}}
					/>
				)}
				{currentTab === 'stats' && <StatsTAB company={company} />}
				{currentTab === 'wallet' && (
					<WalletTAB
						company={company}
						updateCompany={() => setForceRefresh(forceRefresh + 1)}
					/>
				)}
				{currentTab === 'fleet' && <FleetTab companyId={company.id} />}
			</>
		);
	};

	const renderCompany = (company: CompanyResponse) => {
		return (
			<>
				{shortCompanySection(company)}
				<div className="px-10 py-5">
					<SypacTabs variant="gray">
						<SypacTabButton
							variant="violet"
							size="medium"
							label={t('companyDetails.overview', 'Overview')}
							button-id="overview"
							onClick={() => setCurrentTab('overview')}
							isActive={currentTab === 'overview'}
						>
							<SypacIcon icon-name="Document" size="md" />
						</SypacTabButton>
						<SypacTabButton
							variant="violet"
							size="medium"
							label={t('companyDetails.company', 'Company')}
							button-id="company"
							onClick={() => setCurrentTab('company')}
							isActive={currentTab === 'company'}
						>
							<SypacIcon icon-name="Buildings 2" size="md" />
						</SypacTabButton>
						{company?.companyTypes.includes(`${OrderTarget.transporter}`) && (
							<SypacTabButton
								variant="violet"
								size="medium"
								label={t('companyDetails.fleet', 'Fleet')}
								button-id="fleet"
								onClick={() => setCurrentTab('fleet')}
								isActive={currentTab === 'fleet'}
							>
								<FleetTruckIcon isActive={currentTab === 'fleet'} />
							</SypacTabButton>
						)}
						<SypacTabButton
							variant="violet"
							size="medium"
							label={t('companyDetails.users', 'Users')}
							button-id="users"
							onClick={() => setCurrentTab('users')}
							isActive={currentTab === 'users'}
						>
							<SypacIcon icon-name="User Id" size="md" />
						</SypacTabButton>
						<SypacTabButton
							variant="violet"
							size="medium"
							label={t('companyDetails.documents', 'Documents')}
							button-id="documents"
							onClick={() => setCurrentTab('documents')}
							isActive={currentTab === 'documents'}
						>
							<SypacIcon icon-name="Paperclip" size="md" />
						</SypacTabButton>
						<SypacTabButton
							variant="violet"
							size="medium"
							label={t('companyDetails.wallet', 'Wallet')}
							button-id="wallet"
							onClick={() => setCurrentTab('wallet')}
							isActive={currentTab === 'wallet'}
						>
							<SypacIcon icon-name="Wallet" size="md" />
						</SypacTabButton>
						<SypacTabButton
							variant="violet"
							size="medium"
							label={t('companyDetails.stats', 'Stats')}
							button-id="stats"
							onClick={() => setCurrentTab('stats')}
							isActive={currentTab === 'stats'}
						>
							<SypacIcon icon-name="Chart 2" size="md" />
						</SypacTabButton>
					</SypacTabs>
				</div>

				<div className="border-0 border-solid border-t-[1px] border-gray-10" />

				{company.status === 'verification_pending' && (
					<div className="bg-seashell-peach px-10 py-2 flex flex-row justify-between items-center">
						<SypacText variant="body-normal-large">
							<span>
								<T keyName="companyDetails.thisCompanyIsPendingVerification">
									This company is pending verification
								</T>
							</span>
						</SypacText>
						<SypacButton variant="label" size="large">
							<button
								className="text-white bg-mountain-meadow"
								onClick={onVerification}
							>
								<T keyName="companyDetails.startCompanyVerification">
									Start company verification
								</T>
							</button>
						</SypacButton>
					</div>
				)}

				{renderTabs(company, currentTab)}
			</>
		);
	};

	return (
		<div className="fixed min-h-full top-0 right-0 w-[716px] bg-white shadow-order-details z-[100] h-screen overflow-y-auto scroll-smooth">
			<div className="py-10 overflow-x-hidden overflow-y-scroll">
				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					<>
						<div className="px-10 pb-10 flex">
							<div className="flex-1">
								{company ? (
									<CompanyAssignees
										assignUser={changeAssignees}
										users={resp?.items || []}
										assignees={company?.assignees || []}
									/>
								) : null}
							</div>
							<SypacIcon
								iconName="Close Square"
								className="text-gray-10 ml-6 cursor-pointer"
								size="custom"
								width="32px"
								height="32px"
								onClick={onClose}
							/>
						</div>
						{company && renderCompany(company)}
					</>
				)}
			</div>
		</div>
	);
};

export default CompanyDetails;
