import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SypacTabs, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { TabsButton } from '../../components/TabsButton/TabsButton';
import SettingMyProfile from './components/SettingMyProfile';
import SettingCompanyProfile from './components/SettingCompanyProfile';
import {
	CompanyResponse,
	CompanyService,
} from '../../services/company.services';
import { toastVariant } from '../../components/CompaniesTable/toastVariant/toastVariant';
import SettingUsers from './components/SettingUsers';
import { MyProfile, UsersServices } from '../../services/users.services';
import { AuthContext } from '../../context/context';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import { UsersInterface } from '../../interfaces/Users.interface';

const UserSettings: React.FC = () => {
	const { user } = useContext(AuthContext);
	const [currentTab, setCurrentTab] = useState<string>('my-profile');
	const [myProfile, setMyProfile] = useState<MyProfile>();
	const [myCompanyUsers, setMyCompanyUsers] = useState<UsersInterface[]>([]);
	const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
	const [myCompany, setMyCompany] = useState<CompanyResponse>();
	const tabs = [
		{ label: 'My profile', color: 'white', count: '0', value: 'my-profile' },
		{
			label: 'Company profile',
			color: 'white',
			count: '0',
			value: 'company-profile',
		},
		{ label: 'Company users', color: 'white', count: '0', value: 'users' },
		// { label: 'My fleet', color: 'white', count: '0', value: 'my-fleet' },
		// { label: 'Billings', color: 'white', count: '0', value: 'billings' },
		// {
		// 	label: 'Notifications',
		// 	color: 'white',
		// 	count: '0',
		// 	value: 'notifications',
		// },
	];

	const getMyProfileCallback = useCallback(async () => {
		setLoadingProfile(true);
		try {
			const { data } = await UsersServices.getMyProfile(
				user?.company.industries[0]!,
			);
			const { data: usersData } = await UsersServices.getCompanyUsers(
				user?.company.industries[0]!,
			);
			setMyCompanyUsers(usersData);
			setMyProfile(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		} finally {
			setLoadingProfile(false);
		}
	}, [user]);

	useEffect(() => {
		getMyProfileCallback().then(() => {});
	}, [getMyProfileCallback]);

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setMyCompany(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const updateMyProfile = async () => {
		try {
			await getMyProfileCallback();
		} catch (e) {}
	};

	const updateMyCompany = async () => {
		try {
			await getMyCompanyCallback();
			setCurrentTab('company-profile');
		} catch (e) {}
	};

	return (
		<div className="relative flex justify-center w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-94px)] h-full">
			<div
				className={`w-[1084px] flex flex-col gap-6 ${
					currentTab === 'company-profile'
						? 'overflow-x-hidden overflow-y-scroll pr-2'
						: ''
				} ${currentTab === 'users' ? 'gap-0' : ''}`}
			>
				<section className="flex flex-col gap-[14px] mt-[27px]">
					<SypacText variant="heading-4">
						<p className="text-[32px] text-gray-90">
							<T keyName="userSettings.settings">Settings</T>
						</p>
					</SypacText>
					<SypacText variant="heading-4">
						<p className="text-2xl leading-[28px] text-gray-40">
							<T keyName="userSettings.manageYourAccountSettings">
								Manage your account settings and preferences.
							</T>
						</p>
					</SypacText>
				</section>
				<div
					className={`border-0 border-solid border-t-[1px] border-gray-10 ${
						currentTab === 'users' ? 'my-6' : ''
					}`}
				/>

				<SypacTabs
					className={`flex w-full p-[3px] gap-[4px] box-border ${
						currentTab === 'users' ? 'mb-6' : ''
					}`}
				>
					{tabs.map(({ label, value, count }: any) => {
						return (
							<TabsButton
								key={value}
								label={label}
								buttonId={value!}
								onClick={() =>
									setCurrentTab(value ? value : label.toLowerCase())
								}
								isSelected={
									currentTab === value || currentTab === label.toLowerCase()
								}
								notification={+count}
								color="#E8E8E8"
								classNames="w-full"
							/>
						);
					})}
				</SypacTabs>

				{currentTab === 'my-profile' ? (
					<SettingMyProfile
						profile={myProfile?.profile!}
						loadingProfile={loadingProfile}
						updateMyProfile={updateMyProfile}
						user={user!}
					/>
				) : null}
				{currentTab === 'company-profile' ? (
					<SettingCompanyProfile
						company={myCompany!}
						updateMyCompany={updateMyCompany}
					/>
				) : null}
				{currentTab === 'users' ? (
					<SettingUsers
						currentUser={myProfile?.profile!}
						users={myCompanyUsers}
						company={myCompany!}
						updateMyProfile={updateMyProfile}
					/>
				) : null}

				<div className="flex justify-between mt-auto pt-4 border-0 border-solid border-t-[1px] border-gray-10">
					<LanguagePicker />
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">{new Date().getFullYear()} sypac.app</p>
					</SypacText>
				</div>
			</div>
		</div>
	);
};

export default UserSettings;
