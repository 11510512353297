import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	SypacBreadcrumbs,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import Flag from 'react-world-flags';

import ListMenu from '../../../components/ListMenu/ListMenu';
import { useGetCategories } from '../../../hooks/use-get-categories';
import Close from '../../../assets/Close';
import GroupCard from '../../../components/GroupCard/GroupCard';

import '../select-product.scss';
import { OrderContext } from '../../../context/OrderContext/order.context';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { GeoService } from '../../../services/geo.services';
import { T, useTranslate } from '@tolgee/react';

// TODO: decorate category with products count count on get
const ClientSelectCategory: React.FC = () => {
	const { t } = useTranslate();
	const { groupId } = useParams();
	const [coordinates, setCoordinates] = useState<number[]>([]);
	const [groups] = useGetCategories({});
	const [activeGroup, setActiveGroup] = useState<string>(groupId!);
	const navigate = useNavigate();
	const { order } = useContext(OrderContext);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [categories, _, isLoading] = useGetCategories({
		parentIds: [activeGroup!],
		coordinates,
	});

	const address = useMemo(
		() =>
			order?.location
				? [
						[
							order?.location?.address?.street,
							order?.location?.address?.houseNumber,
						].join(' ') || '',
						order?.location.address?.postalCode || '',
						order?.location.address?.city || '',
						order?.location.address?.country || '',
				  ]
						.filter((r) => r)
						.join(', ')
				: '',
		[order?.location],
	);
	const listItems =
		groups?.items?.map((item) => {
			return {
				id: item.id,
				name: item.name,
				onClick: () => {
					setActiveGroup(item.id);
				},
			};
		}) || [];

	const currentGroup = useMemo(() => {
		return groups?.items?.find((item) => item.id === activeGroup);
	}, [groups, activeGroup]);

	const getLocation = useCallback(async (locationId: string) => {
		if (locationId) {
			const resp = await GeoService.getLocationDetails({
				locationid: locationId,
			});
			setCoordinates([resp.data.Longitude, resp.data.Latitude]);
		}
	}, []);

	useEffect(() => {
		if (order?.location?.locationId) {
			getLocation(order?.location?.locationId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.location?.locationId]);

	return (
		<section className="client-select-product">
			<SearchBar
				placeholder={t(
					'clientSelectCategory.searchProducts',
					'Search Products',
				)}
			/>
			<div className="mt-6">
				<SypacText variant="body-regular-medium">
					<p className="align-middle text-gray-80">
						<T keyName="clientSelectCategory.deliveryAddress">
							Delivery address:
						</T>
						<span className="flag">
							<Flag
								code={order?.location?.countryCode.toLocaleUpperCase()}
								width={24}
								height={16}
							/>
						</span>
						{address}
						<SypacButton
							variant="secondary"
							size="medium"
							className="inline-block mx-6"
						>
							<a href="/store" className="close">
								<Close width="8" height="8" />
							</a>
						</SypacButton>
					</p>
				</SypacText>
			</div>
			<div className="mt-4">
				<SypacBreadcrumbs>
					<div
						className="cursor-pointer text-cornflower-blue"
						onClick={() => navigate('/store/groups')}
					>
						<T keyName="clientSelectCategory.store">Store</T>
					</div>
					<div className="text-cornflower-blue">{currentGroup?.name}</div>
				</SypacBreadcrumbs>
			</div>
			<div className="flex mt-6 sm:flex-col xl:flex-row">
				<div className="w-[210px]">
					<div>
						<ListMenu items={listItems} selected={groupId} />
					</div>
				</div>
				<div className="ml-6 flex-1 sm:ml-0 xl:ml-6 sm:mt-4 xl:mt-0">
					<SypacText variant="heading-5">
						<p>{currentGroup?.name}</p>
					</SypacText>
					<div className="mt-7 grid grid-cols-3 gap-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
						{!isLoading &&
							categories?.items?.map((item) => {
								return (
									<GroupCard
										key={item.id}
										imageUrl={item.imageUrl}
										title={item.name}
										description={`${item.countPrododucts} ${t(
											'clientSelectCategory.productsInThisCategory',
											'products in this category',
										)}`}
										onClick={() => {
											navigate(`${item.id}/products`);
										}}
									/>
								);
							})}
					</div>
				</div>
			</div>
		</section>
	);
};

export default ClientSelectCategory;
